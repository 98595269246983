import React, { Component } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	NavLink,
	useHistory
  } from 'react-router-dom';
import logo from '../images/logo.png';


class Navbar extends Component {

  	state = {};

	onToggleOpen() {
		document.getElementsByClassName('menu-to-right')[0].classList.add("show");
	}

	onToggleClose() {
		document.getElementsByClassName('menu-to-right')[0].classList.remove("show");
	}

	render() {
    	return (
			<React.Fragment>
				<link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossOrigin="anonymous"/>
     			<nav className="navbar navbar-expand-lg navbar-light" id="navbar">
  					<div className="container-fluid">
    					<a className="navbar-brand" href="/"><img id="logo" src={logo} alt=""/></a>
						<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>
    					<div className="collapse navbar-collapse" id="navbarSupportedContent">
							<ul className="navbar-nav me-auto mb-2 mb-lg-0 ml-auto">
								<li className="nav-item mr-3">
									<Link className="nav-item nav-link " to="/">
										Home
									</Link>
								</li>
								<li className="nav-item dropdown mr-3">
									<a className="nav-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
										Solutions
									</a>
									<ul className="dropdown-menu " aria-labelledby="navbarDropdown">
										<li className="li-dropdown">
											<div className="dropright">
												<a className="btn btn-light dropdown-toggle dropdown-item" id="open-to-right" data-toggle="dropdown" onMouseOver={this.onToggleOpen}>Products</a>
												<div className="dropdown-menu menu-to-right">
													<Link className="nav-item nav-link right" to="/serenus-ai-for-payers-employers">
														<button className="dropdown-item btn-dropdown" >
															Serenus.AI for Payers/Employers
														</button>
													</Link>
													<Link className="nav-item nav-link right" to="/serenus-ai-for-providers-and-physicians">
														<button className="dropdown-item btn-dropdown">
															Serenus.AI for Providers and Physicians
														</button>
													</Link>
													<Link className="nav-item nav-link right" to="/serenus-ai-for-patient">
														<button className="dropdown-item btn-dropdown" >
															Serenus.AI for Patients
														</button>
													</Link>
													<Link className="nav-item nav-link right" to="/emr-api">
														<button className="dropdown-item btn-dropdown">
															Serenus EMR API
														</button>
													</Link>
												</div>
											</div>
										</li>
										<li className="li-dropdown" onMouseOver={this.onToggleClose}>
											<Link className="dropdown-item btn-light" to="/benefits">
												Benefits
											</Link>
										</li>
										<li className="li-dropdown" onMouseOver={this.onToggleClose}>
											<Link className="dropdown-item btn-light" to="/specialties">
												Specialties
											</Link>
										</li>
									</ul>
								</li>
								<li className="nav-item dropdown mr-3">
									<a className="nav-link" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
										Company
									</a>
									<ul className="dropdown-menu " aria-labelledby="navbarDropdown">
										<li className="li-dropdown" onMouseOver={this.onToggleClose}>
											<Link className="dropdown-item btn-light" to="/mission">
												Mission
											</Link>
										</li>
										<li className="li-dropdown" onMouseOver={this.onToggleClose}>
											<Link className="dropdown-item btn-light" to="/team">
												Team
											</Link>
										</li>
										<li className="li-dropdown" onMouseOver={this.onToggleClose}>
											<Link className="dropdown-item btn-light" to="/investors">
												Investors
											</Link>
										</li>
										<li className="li-dropdown" onMouseOver={this.onToggleClose}>
											<Link className="dropdown-item btn-light" to="/releases">
												Press Releases
											</Link>
										</li>
									</ul>
								</li>

								<li className="nav-item mr-3">
									<Link className="nav-item nav-link" to="/partners">
										Partners
									</Link>
								</li>
								<li className="nav-item mr-3">
									<Link className="nav-item nav-link" to="/media">
										Media
									</Link>
								</li>
								<li className="nav-item mr-3">
									<Link className="nav-item nav-link" to="/careers">
										Careers
									</Link>
								</li>
								<li className="nav-item mr-3">
									<Link className="nav-item nav-link" to="/contact-us">
										Contact Us
									</Link>
								</li>
								<li className="nav-item">
									<Link className="nav-item nav-link" to="/info">
										Login
									</Link>
								</li>
							</ul>
    					</div>
  					</div>
				</nav>
			</React.Fragment>
    	);
  	}
}

export default Navbar;
