import header from '../images/work.jpg'
import React, { Component } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	NavLink
  } from 'react-router-dom';
import { Helmet } from 'react-helmet'


const VP = () => {

	return ( 
		<React.Fragment>
			<Helmet>	
				<title>VP AI/ML R&D | Serenus.AI&reg;</title>
			</Helmet>
			<div className="col col-12" id="image-picker">
				<img src={header} alt="" width="100%" height="400px" />
				<div style={{position:"absolute", width:"100%", height:"100%", background:"#000000", opacity:"0.4", top:0}}></div>
				<div className="centered main-title" style={{textAlign:"center", width:"100%"}}>
					<h1 class="header-for-pages">VP AI/ML R&D	</h1>
					<div  className="mr-3"><span className="mr-3"><i className="fas fa-map-marker-alt mr-1"></i> Tel Aviv, Israel</span> <span><i className="fas fa-business-time mr-1"></i>Full-Time</span></div>
				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-md-8">
						<p>We are an exciting lifesaving start-up developing an innovative AI-based system for the medical field. You will join a high-profile team to work on groundbreaking problems in health outcomes. The predictive analytics team works hand-in-hand with top medical professionals, leveraging a vast variety of anonymous patient-level information and top medical professionals’ labels.   </p>
						<br/>
						<p>We're looking for a talented and experienced AI/ML executive to join our team; A proactive, self-motivated, hands-on person to spearhead the development process for our various products.</p>
	
						<h5 style={{fontWeight:"bold"}}>As the VP AI/ML R&D, you will:</h5>
						<ul className="ul-big">
							<li>Be part of the company’s executive team and help shape its strategy.</li>
							<li>Build a product that includes cutting-edge technologies that combine Web and Machine Learning.</li>
							<li>Lead a team of data scientists, web developers, and project managers.</li>
							<li>Define technical requirements and system architecture. </li>
							<li>Lead and motivate subordinates to accomplish the goals and objectives of the R&D process. </li>
						</ul>
						<br/>
						<h5 style={{fontWeight:"bold"}}>Requirements::</h5>
						<ul className="ul-big">
							<li>Proven experience in building top quality data-driven solutions.  </li>
							<li>Experience in the implementation of machine learning models and algorithms.</li>
							<li>Experience in hands-on projects. </li>
						</ul>
						<br/>

						<p style={{fontWeight:"bold"}}>The position will be located within the central area of Israel.</p>
						<p style={{fontWeight:"bold"}}>Position Capacity: Full-Time</p>
						<p>**We are committed to creating a diverse environment and proud to be an equal opportunity employer.</p>
					</div>
					<div className="col-md-4">
						<div className="row">
							<Link className="btn btn-position" to="vp/apply" style={{width:"100%"}}>
								<i class="fas fa-pencil-alt mr-2"></i>
								Apply To Position
							</Link>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
 
export default VP;