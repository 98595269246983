import Background from '../images/background4.jpg'
import React, { Component } from "react";
import Light from "../images/light.png";
import { Helmet } from 'react-helmet'


const Emr = () => {

	return ( 
		<React.Fragment>
			<Helmet>	
				<title>Serenus.AI EMR API | Serenus.AI&reg;</title>
			</Helmet>
			<div className="col col-12" id="image-picker">
				<img src={Background} alt="" height="500px" width="100%" />
				<div style={{position:"absolute", width:"100%", height:"100%", background:"#000000", opacity:"0.4", top:0}}></div>
				<div className="centered main-title" style={{textAlign:"center", width:"100%"}}>
					<h1 class="header-for-pages">Serenus.AI EMR API</h1>
				</div>
			</div>
			<div className="wrapper">
				<div className="container">
					<br/>
					<div className="row">
						<div className="col-md-6 col-xs-12">
							<p>
								Serenus.AI EMR API is a comprehensive integration service that enhances the quality of information available in medical records with critical medical decisions reached using state-of-the-art deep learning and AI algorithms.
								<br/> <br/>
								Serenus.AI EMR API offers new higher standards of safety for healthcare systems by providing access to leading edge solutions while maintaining advanced information privacy and security measures.
							</p>
						</div>
						<div className="col-6" style={{textAlign:"center"}}>
							<img style={{height:"200px"}} src={Light} alt=""/>
						</div>
					</div>
					<br/><br/>
				</div>
			</div>
		</React.Fragment>
	);
}
 
export default Emr;