import React, { Component } from "react";
import header from '../images/investors.png';
import { Helmet } from 'react-helmet'
import covve from '../images/investors/covve.png'
import firstTime from '../images/investors/firstTime.png'
import lastTime from '../images/investors/theTime.png'
import shibolet from '../images/investors/shibolet.png'
import madens from '../images/investors/madens.png'
import bird from '../images/investors/bird.jpg'
import insur from '../images/investors/insurtech.jpg'
import border from '../images/investors/border.png'


const Investors = () => {

	return ( 
		<React.Fragment>
			<Helmet>	
				<title>Investors | Serenus.AI&reg;</title>
			</Helmet>
			<div className="col col-12" id="image-picker">
				<img src={header} alt="" width="100%" height="400px" />
				<div style={{position:"absolute", width:"100%", height:"100%", background:"#000000", opacity:"0.4", top:0}}></div>
				<div className="centered main-title" style={{textAlign:"center", width:"100%"}}>
					<h1 className="header-for-pages">Investors</h1>
				</div>
			</div>
			<div className="container">
				<p style={{textAlign:"center"}} className="margin-botton-50">
					Serenus.AI is the first AI-based solution that empowers medical decisions at critical crossroads, saving lives and valuable resources. <br/>
					Our fast growth is a result of its unique products, developed by Serenus.AI's R&D laboratories, to deliver leading innovations and state-of-the art technologies. <br/>
					We’re proud to be supported by an incredible team of investors. <br/> Together, we have a mission to improve healthcare delivery. <br/>
					Serenus.AI is a great opportunity for investment, we will be glad to be in touch with investors in the following email : <a href="mailto:investors@serenusai.com">investors@serenusai.com</a>.
				</p>
				<br/><br/>
				<h2 className="margin-bottom-20">Our Investors</h2>
				<br/>
				<div className="row" style={{justifyContent:"center"}}>

					<div className="col-md-2 col-sm-5 col-xs-12" style={{ marginRight:"20px", textAlign:"center"}}>
						<img src={madens} alt="" className="img-height-70 logos"/> <br/>
						<img src={border} className="investors-border" width="90%" alt=""/>
					</div>
					<div className="col-md-2 col-sm-5 col-xs-12" style={{marginLeft:"20px", marginRight:"20px",textAlign:"center"}}>
						<img src={firstTime} className="img-height-70 logos" style={{zIndex:"1"}} alt=""/> <br/>
						<img src={border} className="investors-border" width="90%" alt=""/>
					</div>
					<div className="col-md-2 col-sm-5 col-xs-12" style={{marginLeft:"20px", marginRight:"20px",textAlign:"center"}}>
						<img src={lastTime} className="img-height-70 logos" alt=""/><br/>
						<img src={border} className="investors-border" width="90%" alt=""/>
					</div>
					<div className="col-md-2 col-sm-5 col-xs-12" style={{marginLeft:"20px", marginRight:"20px",textAlign:"center"}}>
						<img src={shibolet} className="img-height-70 logos shibolet" width="100%" alt=""/> <br/>
						<img src={border} className="investors-border" width="90%" alt=""/>
					</div>
					<div className="col-md-2 col-sm-5 col-xs-12" style={{marginLeft:"20px", marginRight:"20px",textAlign:"center"}}>
						<img src={insur} className="img-height-70 logos shibolet" width="90%" alt=""/> <br/>
						<img src={border} className="investors-border" width="90%" alt=""/>
					</div>

				</div>
			</div>
		</React.Fragment>
	);
}
 
export default Investors;