import Background from '../images/background4.jpg';
import Specialties from "../components/specialties";
import React, { Component } from "react";
import { Helmet } from 'react-helmet'


const SpecialtiesPage = () => {

	return (
		<React.Fragment>
			<Helmet>	
				<title>Specialties | Serenus.AI&reg;</title>
			</Helmet>
			<div className="col col-12" id="image-picker">
				<img src={Background} alt="" height="500px" width="100%" />
				<div style={{position:"absolute", width:"100%", height:"100%", background:"#000000", opacity:"0.4", top:0}}></div>
				<div className="centered main-title" style={{textAlign:"center", width:"100%"}}>
					<h1 class="header-for-pages">Specialties</h1>
				</div>
			</div>

			<div className="col col-12 margin-top-40" style={{display: "inline-block", marginBottom:"80px"}}>
				<br/>
				<Specialties></Specialties>
				<br/>
			</div>
		</React.Fragment> 
	);
}
 
export default SpecialtiesPage;