import Background from '../images/background4.jpg';
import think from '../images/think.png';
import process from '../images/process.png';
import React, { Component } from "react";
import { Helmet } from 'react-helmet'


const Benefits = () => {
	
	return ( 
		<React.Fragment>
			<Helmet>	
				<title>Benefits | Serenus.AI&reg;</title>
			</Helmet>
			<link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.10.0/css/all.css" integrity="sha384-AYmEC3Yw5cVb3ZcuHtOA93w35dYTsvhLPVnYs9eStHfGJvOvKxVfELGroGkvsg+p" crossorigin="anonymous"/>
			<div className="col col-12" id="image-picker">
				<img src={Background} alt="" height="500px" width="100%" />
				<div style={{position:"absolute", width:"100%", height:"100%", background:"#000000", opacity:"0.4", top:0}}></div>
				<div className="centered main-title" style={{textAlign:"center", width:"100%"}}>
					<h1 class="header-for-pages">Benefits</h1>
				</div>
			</div>

			<div className="" style={{padding:"0px", marginBottom:"30px"}}>
				<div className="col col-12">
					<div className="row items-center">
						<div className=" col-md-12 col-xs-12">
							<div className="">
								<br/>
								<h4 className="margin-top-10 ml-3">Payers/Employers</h4>
								<ul className="ul-no-points">
									<div className="row" style={{marginBottom:"5px"}}>
										<div className="col-md-3 col-sm-6 col-xs-12">
											<div className="benefit">
												<div className=""><img src="https://img.icons8.com/dotty/80/000000/decision-making.png" style={{height:"25px"}}/></div>
												<div className=""> <li> Objective medical decision making</li> </div>
											</div>	
										</div>	

										<div className="col-md-3 col-sm-6 col-xs-12">
											<div className="benefit">
												<div className=""><img src="https://img.icons8.com/carbon-copy/100/000000/service.png" style={{height:"25px"}}/></div>
												<div className=""> <li> Automates and scales objective clinical processes</li> </div>
											</div>
										</div>

										<div className="col-md-3 col-sm-6 col-xs-12">
											<div className="benefit">
												<div className=""><img src="https://img.icons8.com/carbon-copy/100/000000/speed.png" style={{height:"25px"}}/></div>
												<div className=""> <li>Speedy approval for necessary procedures</li> </div>
											</div>
										</div>

										<div className="col-md-3 col-sm-6 col-xs-12">
											<div className="benefit">
												<div className=""><img src="https://img.icons8.com/dotty/80/000000/unicast.png" style={{height:"25px"}}/></div>
												<div className=""> <li>Improves efficiency and saves valuable resources</li> </div>
											</div>
										</div>
									</div>


									<div className="row">
										<div className="col-md-3 col-sm-6 col-xs-12">
											<div className="benefit">
												<div className=""><img src="https://img.icons8.com/wired/64/000000/add-list.png" style={{height:"20px"}}/></div>
												<div className=""> <li>Comprehensive review of all relevant factors</li> </div>
											</div>
										</div>

										<div className="col-md-3 col-sm-6 col-xs-12">
											<div className="benefit">
												<div className=""><img src="https://img.icons8.com/wired/64/000000/artificial-intelligence.png" style={{height:"20px"}}/></div>
												<div className=""> <li>Powerful AI-driven analytics</li> </div>
											</div>
										</div>

										<div className="col-md-3 col-sm-6 col-xs-12">
											<div className="benefit">
												<div className=""><img src="https://img.icons8.com/dotty/80/000000/data-backup.png" style={{height:"20px"}}/></div>
												<div className=""> <li>Improves patient history and data flow</li> </div>
											</div>
										</div>

										<div className="col-md-3 col-sm-6 col-xs-12">
											<div className="benefit">
												<div className=""><img src="https://img.icons8.com/wired/64/000000/improvement.png" style={{height:"20px"}}/></div>
												<div className=""> <li>Improves patient outcomes</li> </div>
											</div>
										</div>
									</div>

								</ul>
							</div>
						</div>


						<div className="col-md-12 col-xs-12">
							<div className=""> <br/>
								<h4 className="margin-top-10  ml-3">Providers </h4>
								<ul className="ul-no-points">
									<div className="row">
										<div className="col-md-3 col-sm-6 col-xs-12">
											<div className="benefit">
												<div className=""><img src="https://img.icons8.com/wired/64/000000/online-support--v2.png" style={{height:"20px"}}/></div>
												<div className=""> <li> Decision support system for professionals</li> </div>
											</div>
										</div>

										<div className="col-md-3 col-sm-6 col-xs-12">
											<div className="benefit">
												<div className=""><img src="https://img.icons8.com/pastel-glyph/64/000000/health-report.png" style={{height:"20px"}}/></div>
												<div className=""> <li>Implements the best medical practice</li> </div>
											</div>
										</div>

										<div className="col-md-3 col-sm-6 col-xs-12">
											<div className="benefit">
												<div className=""><img src="https://img.icons8.com/wired/64/000000/database-restore.png" style={{height:"20px"}}/></div>
												<div className=""> <li>Improves patient history and data flow</li> </div>
											</div>
										</div>

										<div className="col-md-3 col-sm-6 col-xs-12">
											<div className="benefit">
												<div className=""><img src="https://img.icons8.com/dotty/80/000000/safe-in.png" style={{height:"20px"}}/></div>
												<div className=""> <li> Prioritizes patients' waiting lists </li> </div>
											</div>
										</div>
									</div>


									<div className="row">
										<div className="col-md-3 col-sm-6 col-xs-12">
											<div className="benefit">
												<div className=""><img src="https://img.icons8.com/wired/64/000000/improvement.png" style={{height:"20px"}}/></div>
												<div className=""> <li> Improves outcomes</li> </div>
											</div>
										</div>

										<div className="col-md-3 col-sm-6 col-xs-12">
											<div className="benefit">
												<div className=""><img src="https://img.icons8.com/ios/50/000000/negative-dynamic.png" style={{height:"20px"}}/></div>
												<div className=""> <li>Reduces unplanned re-admissions rates</li> </div>
											</div>
										</div>

										<div className="col-md-3 col-sm-6 col-xs-12">
											<div className="benefit">
												<div className=""><img src="https://img.icons8.com/wired/64/000000/artificial-intelligence.png" style={{height:"20px"}}/></div>
												<div className=""> <li>Powerful AI-driven analytics</li> </div>
											</div>
										</div>

										<div className="col-md-3 col-sm-6 col-xs-12">
											<div className="benefit">
												<div className=""><img src="https://img.icons8.com/wired/64/000000/add-list.png" style={{height:"20px"}}/></div>
												<div className=""> <li>Comprehensive review of all relevant factors</li> </div>
											</div>
										</div>
									</div>

								</ul>
							</div>
						</div>
						<div className=" col-md-12 col-xs-12">
							<div className=""> <br/>
								<h4 className="margin-top-10  ml-3">Patients – MySerenus&#8482;</h4>
								<ul className="ul-no-points">
									<div className="row">
										<div className="col-md-3 col-sm-6 col-xs-12">
											<div className="benefit">
												<div className=""><img src="https://img.icons8.com/ios/50/000000/health-checkup.png" style={{height:"20px"}}/></div>
												<div className=""> <li>Empowers patients</li> </div>
											</div>
										</div>

										<div className="col-md-3 col-sm-6 col-xs-12">
											<div className="benefit">
												<div className=""><img src="https://img.icons8.com/ios/50/000000/negative-dynamic.png" style={{height:"20px"}}/></div>
												<div className=""> <li>Reduces unnecessary risks and costs</li> </div>
											</div>
										</div>

										<div className="col-md-3 col-sm-6 col-xs-12">
											<div className="benefit">
												<div className=""><img src="https://img.icons8.com/carbon-copy/100/000000/neutral-decision.png" style={{height:"30px"}}/></div>
												<div className=""> <li>Enhances transparency and mutual decision-making</li> </div>
											</div>
										</div>

										<div className="col-md-3 col-sm-6 col-xs-12">
											<div className="benefit">
												<div className=""><img src="https://img.icons8.com/dotty/80/000000/health-book.png" style={{height:"30px"}}/></div>
												<div className=""> <li>Improves the level of care</li> </div>
											</div>
										</div>
									</div>

								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<br/><br/>
		</React.Fragment>
	);
}
 
export default Benefits;
