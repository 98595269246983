import Career from '../images/career.jpg';
import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from 'react-helmet'


const Careers = () => {

	const history = useHistory();

	const routeChange = (event) => { 
		const id = event.target.id;
		let path;
		if (id == 'cso') {
			path = '/jobs/serenus-ai/cso'; 
		} else if (id == 'vp') {
			path = '/jobs/serenus-ai/vp'; 
		} else if (id == 'evp-us') {
			path = '/jobs/serenus-ai/evp-us'; 
		} else if (id == 'full-stack') {
			path = '/jobs/serenus-ai/full-stack'; 
		} else {
			path = '/jobs/serenus-ai/digital'; 
		}
	  	history.push(path);
	}

	
	return ( 
		<React.Fragment>
			<Helmet>
				<title>Careers | Serenus.AI&reg;</title>
			</Helmet>
			<div className="col col-12" id="image-picker">
				<img src={Career} alt="" height="400px" width="100%" />
				<div style={{position:"absolute", width:"100%", height:"100%", background:"#000000", opacity:"0.4", top:0}}></div>
				<div className="centered main-title" style={{textAlign:"center", width:"100%"}}>
					<h1 class="header-for-pages">Careers</h1>
				</div>
			</div>
			<div className="wrapper">
				<div className="container">
					<div className="col-12">
						<h2 style={{textAlign:"center", fontWeight:"bold"}}>More than just a work</h2>
						<p style={{textAlign:"center"}}>
							<br/>
							Serenus.AI is an exciting lifesaving start-up developing an innovative AI-based system for the medical field. <br/>
							We’re building a diverse team of people who love a challenge and are unafraid to bring their whole self to work. <br/>
							We are looking for ambitious, reliable and experienced candidates that will help us grow into the company they never want to leave.
						</p>
						<br/>
					</div>

					<h2 style={{textAlign:"center", fontWeight:"bold"}}>Career opportunities</h2>
					<br/>
					<div className="col col-12">
						<div className="row">
							<div className="col-md-6 col-xs-12" style={{marginLeft:"0px"}}>
								<div className="career" style={{textAlign:"center"}}>
									<h5 className="margin-top-10"  style={{color:"black"}}> Full-Stack Developer  </h5>
									<p className="margin-top-20" style={{color:"rgb(153, 151, 151)"}}><i class="fas fa-map-marker-alt"></i> Tel Aviv, Israel</p>
									<div style={{width: "100%", textAlign:"center"}}>
										<button className="learn-more col-md-6 col-xs-12" id="full-stack"  onClick={routeChange}>See details</button>
									</div>
								</div>
							</div>

							<div className="col-md-6 col-xs-12" style={{marginLeft:"0px"}}>
								<div className="career" style={{textAlign:"center"}}>
									<h5 className="margin-top-10"  style={{color:"black"}}> Online Digital Marketing Specialist  </h5>
									<p className="margin-top-20" style={{color:"rgb(153, 151, 151)"}}><i class="fas fa-map-marker-alt"></i> Tel Aviv, Israel</p>
									<div style={{width: "100%", textAlign:"center"}}>
										<button className="learn-more col-md-6 col-xs-12" id="digital"  onClick={routeChange}>See details</button>
									</div>
								</div>
							</div>
						</div>


						<div className="row">
							<div className="col-md-6 col-xs-12" style={{marginLeft:"0px"}}>
								<div className="career" style={{textAlign:"center"}}>
									<h5 className="margin-top-10"  style={{color:"black"}}> VP AI/ML R&D  </h5>
									<p className="margin-top-20" style={{color:"rgb(153, 151, 151)"}}><i class="fas fa-map-marker-alt"></i> Tel Aviv, Israel</p>
									<div style={{width: "100%", textAlign:"center"}}>
										<button className="learn-more col-md-6 col-xs-12" id="vp"  onClick={routeChange}>See details</button>
									</div>
								</div>
							</div>

							<div className="col-md-6 col-xs-12" style={{marginLeft:"0px"}}>
								<div className="career" style={{textAlign:"center"}}>
									<h5 className="margin-top-10"  style={{color:"black"}}> CSO  </h5>
									<p className="margin-top-20" style={{color:"rgb(153, 151, 151)"}}><i class="fas fa-map-marker-alt"></i> Tel Aviv, Israel</p>
									<div style={{width: "100%", textAlign:"center"}}>
										<button className="learn-more col-md-6 col-xs-12" id="cso"  onClick={routeChange}>See details</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br/>
			</div>
		</React.Fragment>
	);
}
 
export default Careers;
