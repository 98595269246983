import React, { Component } from "react";
import Video from "../components/video"
import Logos from "../components/logos"
import Map from "../components/map"
import Demo from "./demo"
import Specialties from "../components/specialties"
import head from "../images/head.png"
import placeholder from "../images/placeholder.png"
import brain from "../images/brain.png"
import cell from "../images/cell.png"
import video from "../images/serenus-video.mp4"
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { Helmet } from 'react-helmet';
import Slider from "react-slick";
import yaffe from "../images/hospitals/hillel_yaffe.jpeg";
import nhs from "../images/nsh.png";
import leeds from "../images/education/leeds.png";


class Home extends Component {
	
	state = {  }

	constructor(props) {
		super(props);
		// Define settings as a property of the class
		this.settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 3000
		};
	}

	render() { 
		return ( 
			<React.Fragment>
				<Helmet>	
					<title>Home | Serenus.AI&reg;	</title>
				</Helmet>
				<Video></Video>
				<div className="wrapper col col-12" style={{marginBottom:"0px !important", paddingBottom:"10px"}}>
					<br/> 

					{/* <div className="margin-top-40 margin-bottom-40 margin-top-40 wrapper">
						<h2 className="margin-bottom-20">Improving Patients' Outcomes and Efficiencies</h2>
						<h3 className="mb-0" style={{color: "#212529"}}><i>The Right Treatment, to the Right Patient, at the Right Time</i></h3>

						<div className="margin-bottom-40" style={{}}>
						
							<div className="col col-12">
								<div className="row items-center">
									<div className="col-md-4 col-sm-4 col-xs-10 mb-4" >
										<div className="card p-0 h-100 border-light hover-shadow" style={{transform: 'skew(-10deg)', overflow: 'hidden'}}>
											<div className="card-body d-flex flex-column justify-content-center align-items-center p-0" style={{transform: 'skew(10deg)'}}>
												<img className="img-height-70 margin-top-5" src="https://img.icons8.com/dotty/80/000000/financial-analytics.png"/>
												<p className="card-text">
													The First to Efficiently and Clinically Prioritize Patients’ Waiting Lists
													<br/>
													~-12%-40%
												</p>
											</div>
										</div>
									</div>
									<div className="col-md-4 col-sm-4 col-xs-10 mb-4">
										<div className="card p-0 h-100 border-light hover-shadow" style={{transform: 'skew(-10deg)', overflow: 'hidden'}}>
											<div className="card-body d-flex flex-column justify-content-center align-items-center p-0" style={{transform: 'skew(10deg)'}}>
												<img className="img-height-70 margin-top-5" src="https://img.icons8.com/dotty/80/000000/graph.png"/>
												<p className="card-text">
													Reduces Unnecessary Referrals
													<br/>
													~-12%-40%
												</p>
											</div>
										</div>
									</div>
									<div className="col-md-4 col-sm-4 col-xs-10 mb-4" >
										<div className="card p-0 h-100 border-light hover-shadow" style={{transform: 'skew(-10deg)', overflow: 'hidden'}}>
											<div className="card-body d-flex flex-column justify-content-center align-items-center p-0" style={{transform: 'skew(10deg)'}}>
												<img className="img-height-70 margin-top-5" src="https://img.icons8.com/dotty/80/000000/report-file.png"/>
												<p className="card-text">
													Creates New Data for Unprecedent Population Management
												</p>
											</div>
										</div>
									</div>
									<div className="col-md-4 col-sm-4 col-xs-10 mb-4" >
										<div className="card p-0 h-100 border-light hover-shadow">
											<div className="card-body d-flex flex-column justify-content-center align-items-center p-0">
												<img className="img-height-70 margin-top-5" src="https://img.icons8.com/carbon-copy/100/000000/brain.png"/>
												<p className="card-text">
													Reduces Supervision Resources
													<br/>
													~-60%-88%
												</p>
											</div>
										</div>
									</div>
									<div className="col-md-4 col-sm-4 col-xs-10 mb-4" >
										<div className="card p-0 h-100 border-light hover-shadow">
											<div className="card-body d-flex flex-column justify-content-center align-items-center p-0">
												<img className="img-height-70 margin-top-5" src="https://img.icons8.com/carbon-copy/100/000000/flow-chart.png"/>
												<p className="card-text">
													Better Data Flow and Documentation
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>


						<div className="" style={{padding:"0px"}}>
							<div className="col col-12">
								<div className="row items-center">
									<div className="col-md-4 col-sm-4 col-xs-10" >
										<div className="what-serenus" style={{alignContent: 'center'}}>
											<img className="img-height-70 margin-top-5" src="https://img.icons8.com/dotty/80/000000/financial-analytics.png"/>
											<h3 className="margin-top-20 h3-special">
												The First to Efficiently and Clinically Prioritize Patients’ Waiting Lists
												<br/>
												~-12%-40%
											</h3>
										</div>
									</div>
									<div className="col-md-4 col-sm-4 col-xs-10" >
										<div className="what-serenus" style={{alignContent: 'center'}}>
											<img className="margin-top-5" style={{height:"67px"}} src="https://img.icons8.com/dotty/80/000000/graph.png"/>
											<h3 className="margin-top-20 h3-special">
												Reduces Unnecessary Referrals
												<br/>
												~-12%-40%
											</h3>
										</div>
									</div>
									<div className="col-md-4 col-sm-4 col-xs-10">
										<div className="what-serenus" style={{alignContent: 'center'}}>
											<img  className="img-height-60 margin-top-10" src="https://img.icons8.com/dotty/80/000000/report-file.png"/>
											<h3 className="margin-top-20 h3-special">
												Creates New Data for Unprecedent Population Management
											</h3>
										</div>
									</div>
								</div>

								<div className="row items-center">
									<div className="col-md-4 col-sm-4 offset-md-2 col-xs-10" style={{marginLeft:"0px"}}>
										<div className="what-serenus" style={{alignContent: 'center'}}>
											<img className="img-height-70 margin-top-5" src="https://img.icons8.com/carbon-copy/100/000000/brain.png"/>
											<h3 className="margin-top-20 h3-special">
												Reduces Supervision Resources
												<br/>
												~-60%-88%
											</h3>
										</div>
									</div>

									<div className="col-md-4 col-sm-4 col-xs-10">
										<div className="what-serenus" style={{alignContent: 'center'}}>
											<img className="img-height-70 margin-top-5" src="https://img.icons8.com/carbon-copy/100/000000/flow-chart.png"/>
											<h3 className="margin-top-20 h3-special">
												Better Data Flow and Documentation
											</h3>
										</div>
									</div>
								</div>
							</div>
						</div>
						<br/>
					</div> */}


					{/* <div className="margin-left-right" style={{justifyContent:"center"}}>
						<div className="col-12">
							<div className="row adv-container" style={{justifyContent:"center"}}>
								<div className="col-md-2 col-sm-6 col-xs-12 margin-right-35">
									<div className="icon-adv">
										<div style={{background:"#668a5b",textAlign:"center"}}>
											<img src="https://img.icons8.com/dotty/80/000000/financial-analytics.png"/>
										</div>
									</div>
									<div className="circle" style={{background:"rgba(105,208,95,0.3)"}}>
										<div style={{position:"relative"}}>
											<p>
												The First to Efficiently and Clinically Prioritize Patients’ Waiting Lists while Saving Valuable Resources
												<br/>
												<small>~-12%-40%</small>
											</p>
										</div>
									</div>
								</div>
								<div className="col-md-2 col-sm-6 col-xs-12 margin-right-35">
									<div className="icon-adv">
										<div style={{background:"#4c96c1", textAlign:"center"}}>
											<img src="https://img.icons8.com/dotty/80/000000/graph.png"/>
										</div>
									</div>
									<div className="circle" style={{background:"rgba(76,150,193,0.3)"}}>
										<div style={{position:"relative"}}>
											<p>
												Reduces Unnecessary Referrals
												<br/>
												<small>~-12%-40%</small>
											</p> 
										</div>
									</div>
								</div>
								<div className="col-md-2 col-sm-6 col-xs-12 margin-right-35">
									<div className="icon-adv">
										<div style={{background:"#8a5b85", textAlign:"center"}}>
											<img  src="https://img.icons8.com/dotty/80/000000/report-file.png"/>
										</div>
									</div>
									<div className="circle" style={{background:"rgba(144,101,138,0.3)"}}>
										<div style={{position:"relative"}}>
											<p>Creates New Data for Unprecedent Population Management</p> 
										</div>
									</div>
								</div>
								<div className="col-md-2 col-sm-6 col-xs-12 margin-right-35">
									<div className="icon-adv">
										<div style={{background:"#37abac",textAlign:"center"}}>
											<img src="https://img.icons8.com/carbon-copy/100/000000/brain.png"/>
										</div>
									</div>
									<div className="circle" style={{background:"rgba(78,178,179,0.3)"}}>
										<div style={{position:"relative"}}>
											<p>
												Reduces Supervision Resources
												<br/>
												<small>~-60%-88%</small>
											</p>
										</div>
									</div>
								</div>
								<div className="col-md-2 col-sm-6 col-xs-12 margin-right-35" >
									<div className="icon-adv">
										<div style={{background:"rgb(238, 181, 124)",textAlign:"center"}}>
											<img src="https://img.icons8.com/carbon-copy/100/000000/flow-chart.png"/>
										</div>
									</div>
									<div className="circle" style={{background:"rgba(238, 181, 124,0.3)"}}>
										<div style={{position:"relative"}}>
											<p>Better Data Flow and Documentation</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div> */}

					{/* <div className="col col-md-6 col-xs-12 my-5 center">
						<a href="/#demo"><button className="contact-us-vision" style={{width:"100%"}}>REQUEST A DEMO</button></a>
					</div> */}

					<br/>
					
					<h2 className="margin-bottom-40">What is Serenus.AI ?</h2>
					<div className="col col-12">
						<div className="row" style={{textAlign:"center"}}>
							<div className="col-md-12" style={{textAlign:"center", margin:"0 auto"}}>
								<p>Serenus.AI specializes in the development of advanced Generative AI technologies that empower medical decisions at critical medical crossroads on patients' pathways, saving lives and valuable resources. Our flagship product is the first (registered patent and 4 patents pending) clinically objective, Generative AI, automated case-by-case system to improve referrals before medical procedures and treatments, while prioritizing patients' waiting lists. Serenus.AI advances the quality and equality of healthcare delivery, saves valuable resources, and boosts patients', physicians', and payers' satisfaction across the board.</p>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6 col-xs-12 center" >
								<video data-object-fit="cover" src={video} poster={placeholder} type="video/mp4" className="margin-top-40" id="serenus-video" style={{width:"100%"}} controls/>
							</div>
						</div>
					</div>
				</div>

				<div className="col col-12 margin-top-30 ">
					<h2 className="margin-bottom-40">Why Serenus.AI ?</h2>
					<div className="col col-12">
						<div className="row items-center">
							<div className="col-md-4 col-sm-4 col-xs-10 center why-serenus" style={{marginRight:"0px"}}>
								<img className="img-height-100" src={head} alt=""/>
								<h5>Expertise</h5>
								<p>Serenus.AI is listed as one of the top AI start-up companies in the Healthcare Innovation Landscape by  Data Roots Labs report; Its team includes top physicians and machine learning experts.</p>
							</div>

							<div className="col-md-4 col-sm-4 col-xs-10 center why-serenus">
								<img className="img-height-100" src={cell} alt=""/>
								<h5>Cutting-Edge</h5>
								<p>Serenus.AI has the experience, knowledge and resources to rapidly and effectively meet the diverse needs of its customers, implementing cutting-edge science and technology.</p>
							</div>

							<div className="col-md-4 col-sm-4 col-xs-10 center why-serenus" style={{marginLeft:"0px"}}>
								<img className="img-height-100" src={brain} alt=""/>
								<h5>Efficiency</h5>
								<p>At Serenus.AI, we are fully committed to providing the most effective solutions that improve clinical decision-making and outcomes for healthcare professionals and their patients.</p>
							</div>
						</div>
					</div>
				</div>

				<div className="col col-12 margin-top-30">
					<h2 className="margin-bottom-20">What Our Partners Say</h2>
					<Slider {...this.settings} style={{textAlign:"center"}}>
						<div className="col-md-8">
							<div className="say" style={{border:"1px solid rgb(217, 217, 217)", padding:"30px", textAlign:"left"}}>
								<i class="fas fa-2x fa-quote-left" style={{color:"rgb(217, 217, 217)"}}></i> <br/>
								<p>Serenus.AI technology has the ability to improve patient outcomes, reduce variation in clinical practice and reduce the waiting times. It will also save the NHS
								significant money by improving efficiency and reducing the number of unindicated unnecessary treatments</p>
								<span style={{fontSize:"12px", fontWeight:"bold"}}>Prof. Hemant Pandit FRCS (Orth) DPhil (Oxon) <br/>
								NIHR Senior Investigator <br/>
								Professor of Orthopedics and Honorary Consultant <br/>
								Director for Research an Innovation, LIRMM
								</span> <br/><br/>
								<div className="row">
									<img src={nhs} alt=""  height="50" width="100" style={{marginRight:"30px"}} />
									<img src={leeds} alt=""  height="50" width="100" />
								</div>
							</div>
						</div>

						<div className="col-md-8">
							<div className="say" style={{border:"1px solid rgb(217, 217, 217)", padding:"30px", textAlign:"left"}}>
								<i class="fas fa-2x fa-quote-left" style={{color:"rgb(217, 217, 217)"}}></i> <br/>
								<p>I strongly recommend implementing the Serenus.AI system in medical institutions in order to improve the professional decision-making process prior to preforming any medical procedure</p>
								<span style={{fontSize:"12px", fontWeight:"bold"}}>Prof. Itzhak Braverman <br/>
								Head of ENT department <br/>
								Hill-Yaffe Hospital
								</span>
								<br/> <br/> 
								<img src={yaffe} height="50" width="50" alt=""/>
							</div>
						</div>
					</Slider>
				</div>

				<div className="col col-12 margin-top-40  margin-top-40 wrapper" >
					<br/>
					<h2  className="margin-bottom-40">Medical Specialties :</h2>
					<br/>
					<Specialties></Specialties>
					<br/><br/><br/>
				</div>

				<div className="col col-12 margin-top-40 margin-bottom-40 margin-top-40">
					<h2  className="margin-bottom-40">Serenus.AI By The Numbers :</h2>
					<br/>
					<div className="row">
						<div className="col-md-3 col-sm-5 col-xs-12 center numbers counter" style={{marginTop:"15px",marginBottom:"15px"}}>
							<h3 className="num"> <CountUp end={1650324} separator=",">
							{({ countUpRef, start }) => (
								<VisibilitySensor onChange={start} delayedCall>
									<span ref={countUpRef} />
								</VisibilitySensor>
							)}
							</CountUp> <br/> Analysed Cases </h3>
						</div>
						<div className="col-md-3 col-sm-5 col-xs-12 center numbers counter" style={{marginTop:"15px",marginBottom:"15px"}}>
							<h3 className="num"> <CountUp end={107000}  separator=","> 
								{({ countUpRef, start }) => (
									<VisibilitySensor onChange={start} delayedCall>
										<span ref={countUpRef} />
									</VisibilitySensor>
								)}
							</CountUp>  <br/> Worked Hours</h3>
						</div>
						<div className="col-md-3 col-sm-6 col-xs-12 center numbers counter" style={{marginTop:"15px",marginBottom:"15px"}}>
							<h3 className="num"><CountUp end={100}  separator=",">       
							{({ countUpRef, start }) => (
								<VisibilitySensor onChange={start} delayedCall>
									<span ref={countUpRef} />
								</VisibilitySensor>
							)}</CountUp> <br/> Procedures</h3>
						</div>
					</div>
				</div>

				<div className="margin-top-40 margin-bottom-40 margin-top-40 wrapper">
					<br/>
					<h2  className="margin-bottom-40">Our Competitive Edge :</h2>
					<div className="" style={{padding:"0px"}}>
						<div className="col col-12">
							<div className="row items-center">
								<div className="col-md-4 col-sm-4 col-xs-10" >
									<div className="what-serenus">
										<img className="img-height-70 margin-top-5" src="https://img.icons8.com/carbon-copy/100/000000/saving-book.png"/>
										<h4 className="margin-top-10">Knowledge</h4>
										<p className="margin-top-20">Combining high-level knowledge of top physicians, continuously simulating real-world situations.</p>
									</div>
								</div>
								<div className="col-md-4 col-sm-4 col-xs-10" >
									<div className="what-serenus">
										<img className="margin-top-5" style={{height:"67px"}} src="https://img.icons8.com/carbon-copy/100/000000/goal.png"/>
										<h4 className="margin-top-10">No Direct Competitor </h4>
										<p className="margin-top-20">Serenus.AI is a unique solution, providing machine learning-based medical decision-making system at the post-diagnostic stage.</p>
									</div>
								</div>
								<div className="col-md-4 col-sm-4 col-xs-10">
									<div className="what-serenus">
										<img  className="img-height-60 margin-top-10" src="https://img.icons8.com/carbon-copy/100/000000/learning.png"/>
										<h4 className="margin-top-10">Keeps Learning</h4>
										<p className="margin-top-20">Ongoing learning mechanism through real-time cases, experts' inputs and industry protocols make the system continuously smarter.</p>
									</div>
								</div>
							</div>

							<div className="row items-center">
								<div className="col-md-4 col-sm-4 col-xs-10" style={{marginLeft:"0px"}}>
									<div className="what-serenus">
										<img className="img-height-70 margin-top-5" src="https://img.icons8.com/dotty/80/000000/machine-learning.png"/>
										<h4 className="margin-top-10">Machine Learning</h4>
										<p className="margin-top-20">Unmatched technology in deep learning and algorithms providing advanced and objective decision-making.</p>
									</div>
								</div>

								<div className="col-md-4 col-sm-4 col-xs-10">
									<div className="what-serenus">
										<img className="img-height-70 margin-top-5" src="https://img.icons8.com/carbon-copy/100/000000/download-from-cloud.png"/>
										<h4 className="margin-top-10">Cloud Based</h4>
										<p className="margin-top-20">Accessible Any Time, Anywhere, without the need for EMR integration. Advanced privacy and security measures are in place (HIPPA etc.)</p>
									</div>
								</div>

								<div className="col-md-4 col-sm-4 col-xs-10">
									<div className="what-serenus">
										<img className="margin-top-10" style={{height:"63px"}} src="https://img.icons8.com/dotty/80/000000/business-group.png"/>	
										<h4 className="margin-top-10">Advisory Board</h4>
										<p className="margin-top-20">Comprised of today’s leading and best-known physicians, providing insights and credibility.</p>
										<br/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<br/>
				</div>
		
				<div className="col col-12 margin-top-40 margin-bottom-40 margin-top-40 ">
					<br/>
					<h2  className="margin-bottom-20">Awarded and Validated :</h2>
					<br/>
					<Logos></Logos>
				</div>
	
				<div className="col col-12 margin-top-40  margin-top-40 " style={{paddingLeft:"0", paddingRight:"0"}}>
					<div className="wrapper">
						<br/> 
						<h2  className="margin-bottom-40" id="demo">Request a Demo :</h2>
						<Demo></Demo>
					</div>
				</div>
				<Map></Map>
			</React.Fragment>
		);
	}
}
 
export default Home;
