import React, { Component } from "react";
import { DefaultEditor } from "react-simple-wysiwyg";
import { baseApiUrl } from "./constants";
import challenge from '../images/background4.jpg';
import market from '../images/background2.jpg';
import solution1 from '../images/light.png';
import solution2 from '../images/background3.jpg';
import solution3 from '../images/ai.jpg';
import solution4 from '../images/mind.png';
import solution5 from '../images/career.jpg';

const axios = require('axios');


class ExecutiveSummaryEditor extends Component {
    
    constructor(props) {
        super(props);
    }

    state = {
        isLoading: true,
        html: []
    };

    async componentDidMount() {
        const url = baseApiUrl() + 'serenusai/get-executive-summray';
        try {
            let res = await axios(url);
            if (res.status == 200) {
                this.setState({ html: res.data, isLoading: false });
            } else if (res.data.status == "error") {
                this.props.toast('Error retrieving data', 'error');
            }      
        } catch (error) {
            console.log(error);
            this.props.toast('Error retrieving data', 'error');
        }
    }
    
    onChange = (i) => (e) => {
        let html = [...this.state.html];
        html[i] = e.target.value;
        this.setState({html});
    }

    handleSaveText = async () => {
        // const url = `http://localhost/medecide-api/public/serenusai/save-executive-summary?token=${this.props.user.token}`;
        // const url = `https://core-dev.serenusai.com/public/serenusai/save-executive-summary?token=${this.props.user.token}`;
        const url = `${baseApiUrl()}serenusai/save-executive-summary?token=${this.props.user.token}`;
        try {
            let res = await axios({
				method: 'post',
				url: url,
				data: {
                    user: this.props.user.name,
					html: this.state.html
				}
			});
            if (res.status == 200 && res.data.status == 'ok') {
                if (res.data.counter > 0)
                    this.props.toast('New changes saved', 'success');
                else if (res.data.counter == 0)
                    this.props.toast('There are no new changes');
            } else {
                this.props.toast('Error. Data was not saved', 'error');
            }      
        } catch (error) {
            console.log(error);
            this.props.toast('Error. Data was not saved', 'error');
        }
    }

    render() {
        
        return (
            <React.Fragment>
                {!this.state.isLoading && (
                    <div className="main-page-ES">
                        <div className="row">
                            <button className="btn btn-block btn-lg btn-danger" onClick={this.handleSaveText}>Save Changes</button>
                        </div>

                        {/* THE CHALLENGE */}
                        <div className="row">
                            <div className="col-lg-6 col-md-7 left-text">
                                <div className="text">
                                    <DefaultEditor value={this.state.html[0]} onChange={this.onChange(0)} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-5 right-image">
                                <img src={challenge} alt="" />
                            </div>     
                        </div>
                        
                        {/* THE SOLUTION */}
                        <div className="row">
                            <div className="col-md-4 left-image d-none d-md-block">
                                <img src={solution1} alt="" />
                            </div>
                            <div className="col-md-8 right-text">
                                <div className="text">
                                    <DefaultEditor value={this.state.html[1]} onChange={this.onChange(1)} />
                                </div>
                            </div>
                            <div className="col-md-4 left-image d-md-none">
                                <img src={solution1} alt="" />
                            </div>
                        </div>

                        {/* THE SOLUTION - CURRENT STATUS */}
                        <div className="row">
                            <div className="col-lg-6 col-md-7 left-text">
                                <div className="text">
                                    <DefaultEditor value={this.state.html[2]} onChange={this.onChange(2)} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-5 right-image">
                                <img src={solution4} alt="" />
                            </div>
                        </div>

                        {/* THE SOLUTION2 */}
                        <div className="row">
                            <div className="col-lg-7 col-md-6 left-image d-none d-md-block">
                                <img src={solution2} alt="" />
                            </div>
                            <div className="col-lg-5 col-md-6 right-text">
                                <div className="text">
                                    <DefaultEditor value={this.state.html[3]} onChange={this.onChange(3)} />
                                </div>
                            </div>
                            <div className="col-mdlg-7 col-md-6 left-image d-md-none">
                                <img src={solution2} alt="" />
                            </div>
                        </div>

                        {/* THE SOLUTION3 */}
                        <div className="row">
                            <div className="col-lg-5 col-md-6 left-text">
                                <div className="text">
                                    <DefaultEditor value={this.state.html[4]} onChange={this.onChange(4)} />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-6 right-image">
                                <img src={solution3} alt="" />
                            </div>
                        </div>

                        {/* THE MARKET */}
                        <div className="row">
                            <div className="col-lg-6 col-md-5 left-image d-none d-md-block">
                                <img src={market} alt="" />
                            </div>
                            <div className="col-lg-6 col-md-7 right-text">
                                <div className="text">
                                    <DefaultEditor value={this.state.html[5]} onChange={this.onChange(5)} />
                                </div>
                            </div>
                            <div className="left-image d-md-none">
                                <img src={market} alt="" />
                            </div>
                        </div>

                        {/* THE ASK */}
                        <div className="row">
                            <div className="col-lg-5 col-md-6 left-text">
                                <div className="text">
                                    <DefaultEditor value={this.state.html[6]} onChange={this.onChange(6)} />
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-6 right-image">
                                <img src={solution5} alt="" />
                            </div>
                        </div>
                    </div>
                )}

            </React.Fragment>
        );
    }
}

export default ExecutiveSummaryEditor;
