import React, { Component, useState } from "react";
import header from '../images/work.jpg';
import { useForm } from 'react-hook-form/dist/index.ie11'; 
import { ToastContainer, toast } from 'react-toastify';
import { b2cUrl } from "./constants";

const axios = require('axios');


const Apply = (props) => {

	const [isSubmit, setIsSubmit] = useState(false);
	const { register, handleSubmit, errors } = useForm();

	let title;
	function jobTitle() {
		const job = props.match.params.title;
		if (job == 'vp') {
			return title = 'VP AI/ML R&D';
		} else if (job == 'cso') {
			return title = 'Chief Strategy Officer';
		} else if (job == 'evp-us') {
			return title = 'EVP US Market';
		} else if (job == 'full-stack') {
			return title = 'Full Stack Developer';
		} else {
			return title = 'Online Digital Marketing Specialist';
		} 	
	}

	async function onSubmit(data) {
		setIsSubmit(true);
		try {

			let nameErr='';
			let emailErr='';	
			let phoneErr='';
			let linkenidErr='';
			let websiteErr='';
			let noteErr='';
			let cvErr='';
			let letterErr='';
			let cvSizeErr='';
			let letterSizeErr='';

			let rawData = {
  				name: data.name,
				email: data.email,
				phone: data.phone,
				url: data.url,
				note: data.note,
				website: data.website,
				position: title
            }
			rawData = JSON.stringify(rawData);
			let formData = new FormData();
			formData.append("cv", window.resume);
			if (window.letter)
				formData.append("letter", window.letter);
			formData.append('data', rawData);

			// const url = 'http://localhost/serenus-ai/b2c/serenus-apply';
			const url = b2cUrl() + 'serenus-apply';

			let res = await axios({
				method: 'post',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
        			// 'Accept': 'application/json',
					// "Access-Control-Allow-Origin": "*"
				}
			});

			if (res.status == 200) {
				if (Array.isArray(res.data)) {
					for (let i = 0; i < res.data.length; i++) {
						if (res.data[i]) {
							if (i == 0) {
								nameErr = res.data[i];
							} else if (i == 1) {
								emailErr = res.data[i];
							} else if (i == 2) {
								phoneErr = res.data[i];
							} else if (i == 3) {
								cvErr = res.data[i];
							} else if (i == 4) {
								letterErr = res.data[i];
							} else if (i == 5) {
								cvSizeErr = res.data[i];
							} else if (i == 7) {
								letterSizeErr = res.data[i];
							}
						}
					}
			  
			  		if (nameErr || emailErr || phoneErr || cvErr || letterErr || cvSizeErr || letterSizeErr) {
						if (nameErr) {
							document.getElementById("err-name").innerHTML = nameErr;
						}
						if (emailErr) {
							document.getElementById("err-email").innerHTML = emailErr;
						}
						if (phoneErr) {
							document.getElementById("err-phone").innerHTML = phoneErr;
						}
						if (cvErr) {
							document.getElementById("err-cv").innerHTML = cvErr;
						}
						if (letterErr) {
							document.getElementById("err-letter").innerHTML = letterErr;
						}
						if (cvSizeErr) {
							document.getElementById("err-size-cv").innerHTML = cvSizeErr;
						}
						if (letterSizeErr) {
							document.getElementById("err-size-letter").innerHTML = letterSizeErr;
						}
			  		}
		  		} else {
					if (res.data.includes("Message has been sent")) {
						toast.success("Your message sent successfully!");
						let frm = document.getElementById('apply-form');
						document.getElementById("letter").value = '';
						document.getElementById("letter-name").innerHTML = '';
						document.getElementById("resume").value = '';
						document.getElementById("file-name").innerHTML = '';

						frm.reset();
						setIsSubmit(false);
						return false;
					} else {
						toast.error("The site is experiencing technical difficulties, please try again later");
					}
				}
		  	}
			setIsSubmit(false);
		} catch {
			toast.error("The site is experiencing technical difficulties, please try again later");
			setIsSubmit(false);
		}
	}


	function updateResume(e) {
		window.resume = e.target.files[0];
		let type = window.resume.type;
		let allowedTypes = ["image/jpeg","image/jpg", "application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
		let allowedSize = 2097152;
		if (window.resume.size > allowedSize) {
			document.getElementById('err-size-cv').innerHTML = "Exceeded size limit";
		} else {
			document.getElementById('err-size-cv').innerHTML = "";
		}
		if (allowedTypes.includes(type)) {
			document.getElementById('err-cv').innerHTML = '';
		} else{
			document.getElementById('err-cv').innerHTML = ' Only jpeg,jpg, pdf, and docx files are allowed.';
		}
		document.getElementById('file-name').innerHTML = window.resume.name;
	}

	function updateCover(e) {
		window.letter = e.target.files[0];
		document.getElementById('letter-name').innerHTML = window.letter.name;
		let type = window.letter.type;

		let allowedTypes = ["image/jpeg","image/jpg", "application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
		let allowedSize = 2097152;

		if (window.letter.size > allowedSize) {
			document.getElementById('err-size-letter').innerHTML = "Exceeded size limit";
		} else {
			document.getElementById('err-size-letter').innerHTML = "";
		}
		if (allowedTypes.includes(type)) {
			document.getElementById('err-letter').innerHTML = '';
		} else {
			document.getElementById('err-letter').innerHTML = ' Only jpeg,jpg, pdf, and docx files are allowed.';
		}
	}

	return ( 

		<React.Fragment>
			<ToastContainer />
			<div className="col col-12" id="image-picker">
				<img src={header} alt="" width="100%" height="400px" />
				<div style={{position:"absolute", width:"100%", height:"100%", background:"#000000", opacity:"0.4", top:0}}></div>
				<div className="centered main-title" style={{textAlign:"center", width:"100%"}}>
					<h1 className="header-for-pages">{jobTitle()}</h1>
					<div  className="mr-3"><span className="mr-3"><i className="fas fa-map-marker-alt mr-1"></i> Tel Aviv, Israel</span> <span><i className="fas fa-business-time mr-1"></i>Full-Time</span></div>
				</div>
			</div>
			<div className="container">
				<div className="col col-12">
					<br/>
					<div className="col-md-12 col-xs-12">
						<form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" id="apply-form">
							<div className="col-12">
								<div className="row">
									<div className="col-md-6 col-xs-12 " style={{justifyContent:"right"}}>
										<label htmlFor="name">Full Name <span style={{color:"red"}}>*</span> </label>
										<input type="text" id="name" name="name" ref={register({ required: true, maxLength: 100 })} placeholder="Full Name"/>
										<span className="err" id="err-name">
											{errors.name && errors.name.type === "required" && <span>Name is required</span>}
											{errors.name && errors.name.type === "maxLength" && <span>Max length exceeded</span> }
										</span>
									</div>

									<div className="col-md-6 col-xs-12 " style={{justifyContent:"right"}}>
										<label htmlFor="email">Email<span style={{color:"red"}}>*</span> </label>
										<input type="email" id="email" name="email" ref={register({ required: true ,maxLength: 100 })} placeholder="Email"/>
										<span className="err" id="err-email">
											{errors.email && errors.email.type === "required" && <span>Email is required</span>}
											{errors.email && errors.email.type === "maxLength" && <span>Max length exceeded</span> }
										</span>
									</div>
								</div>

								<div className="row">
									<div className="col-md-6 col-xs-12 " style={{justifyContent:"right"}}>
										<label htmlFor="phone">Phone<span style={{color:"red"}}>*</span> </label>
										<input type="phone" id="phone" name="phone" ref={register({ required: true,minLength: 9,maxLength: 13})} placeholder="Phone"/>
										<span className="err" id="err-phone">
											{errors.phone && errors.phone.type === "required" && <span>Phone is required</span>}
											{errors.phone && errors.phone.type === "maxLength" && <span>Max length exceeded</span> }
											{errors.phone && errors.phone.type === "minLength" && <span>Phone number needs to be at least 9 digits</span> }
										</span>
									</div>

									<div className="col-md-6 col-xs-12 " style={{justifyContent:"right"}}>
										<label htmlFor="url" >LinkedIn Profile URL</label>
										<input type="url" id="url" name="url" ref={register({ maxLength: 500})}  placeholder="LinkedIn Profile URL"/>
										<span className="err" id="err-linkedin">{errors.url && errors.url.type === "maxLength" && <span>Max length exceeded</span>}</span>
									</div>
								</div>

								<div className="row">
									<div className="col-md-12" style={{justifyContent:"right"}}>
										<label htmlFor="website">Personal Website</label>
										<input type="url" id="website" name="website" ref={register({ maxLength: 200})} placeholder="Personal Website"/>
										<span className="err" id="err-website">{errors.website && errors.website.type === "maxLength" && <span>Max length exceeded</span>}</span>
									</div>

									<div className="col-md-12" style={{justifyContent:"right"}}>
										<label htmlFor="note">Personal note</label>
										<textarea id="note" name="note" cols="10" rows="5" placeholder="Personal note" ref={register({ maxLength: 500})} ></textarea>
										<span className="err" id="err-note">{errors.note && errors.note.type === "maxLength" && <span>Max length exceeded</span>} </span>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										<label htmlFor="resume" id="label-file" >Attach Resume<span style={{color:"red"}}>*</span></label>
										<input type="file" ref={register({ required: true })} id="resume" name="resume" onChange={updateResume}  /> <span id="file-name"></span>
										<span className="err" id="err-cv">
											{errors.resume && errors.resume.type === "required" && <span>CV is required</span>}
										</span> 
										<span className="err" id="err-size-cv"> </span>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										<label htmlFor="letter" id="label-file">Attach Cover Letter</label>
										<input type="file" ref={register} id="letter" name="letter" onChange={updateCover} /> <span id="letter-name"></span>
										<span className="err" id="err-letter"> </span> 
										<span className="err" id="err-size-letter"> </span>
									</div>
								</div>
							</div>
							<div className="col-12" style={{textAlign: "center"}}>
								<button className="submit" type="submit" value="Submit" disabled={isSubmit}>
									{isSubmit
										? (<React.Fragment><span className="spinner-border spinner-border-sm"></span> Sending...</React.Fragment>)
										: 'Submit'
									}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</React.Fragment>

	);
}
 
export default Apply;
