import React, { Component } from "react";
import video from "../images/header.mp4";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import { baseApiUrl } from "./constants";
import challenge from '../images/background4.jpg';
import market from '../images/background2.jpg';
import solution1 from '../images/light.png';
import solution2 from '../images/background3.jpg';
import solution3 from '../images/ai.jpg';
import solution4 from '../images/mind.png';
import solution5 from '../images/career.jpg';
import solution6 from '../images/vision1.jpg';
import team from '../images/team.jpg';
import team2 from '../images/company.jpg';
import team3 from '../images/background1.jpg';
import team4 from '../images/description.jpg';
import team5 from '../images/info2.jpg';
import team6 from '../images/background5.jpg';
import team7 from '../images/privacy.jpg';
import team8 from '../images/work.jpg';
import cell from '../images/cell.png';
import '../css/executiveSummary.css';

const axios = require('axios');


class CTEAS extends Component {

    state = { 
        isLoading: true,
        isLoggedIn: localStorage.getItem('user_id') ? true : false,
        password: localStorage.getItem('PAPassword') || '',
        data: []
    };

    // componentDidMount = async () => {
    //     if (this.state.isLoggedIn) {
    //         await this.getData();
    //         this.setState({ isLoading: false });
    //     } else if (!this.state.isLoggedIn && localStorage.getItem('PAPassword')) {
    //         await this.handleLogin();
    //     } else {
    //         this.setState({ isLoading: false });
    //     }

    //     const listener = event => {
    //         if (event.code === "Enter" || event.code === "NumpadEnter") {
    //           event.preventDefault();
    //           this.handleLogin();
    //         }
    //       };
    //       document.addEventListener("keydown", listener);
    //       return () => {
    //         document.removeEventListener("keydown", listener);
    //     };
    // }

    // handleChange = (event) => {
    //     this.setState({password: event.target.value});
    // }

    // handleLogin = async (event) => {
    //     const isCorrectPassword = await this.checkPassword();
    //     if (isCorrectPassword) {
    //         const isData = await this.getData();
    //         if (isData) {
    //             localStorage.setItem('PAPassword', this.state.password);
    //             this.setState({ isLoading: false, isLoggedIn: true });
    //         }
    //         else
    //             toast.error('Error retrieving data', {
    //                 position: "bottom-right"
    //             });
    //     }
    // }

    // checkPassword = async () => {
    //     const url = baseApiUrl() + 'serenusai/password-check';
    //     try {
    //         let res = await axios({
	// 			method: 'post',
	// 			url: url,
	// 			data: {
	// 				page: 'prior-auth',
	// 				password: this.state.password
	// 			}
	// 		});
    //         if (res.data.status == "ok") {
    //             return true;
    //         } else if (res.data.status == "error") {
    //             toast.error('Wrong password', {
    //                 position: "bottom-right"
    //             });
    //             return false;
    //         }      
    //     } catch (error) {
    //         toast.error('Error', {
    //             position: "bottom-right"
    //         });
    //         return false;
    //     }
    // }

    // getData = async () => {
    //     const url = baseApiUrl() + 'serenusai/get-payers-brochure';
    //     try {
    //         let res = await axios(url);
    //         if (res.status == 200) {
    //             this.setState({ data: res.data });
    //             return true;
    //         } else if (res.data.status == "error") {
    //             return false;
    //         }      
    //     } catch (error) {
    //         console.log(error);
    //         return false;
    //     }
    // }

    putText = (i) => {
        return {__html: this.state.data[i]};
    }

    brochure = async (event) => {
        window.open('/prior-auth360.pdf', '_blank');
    }
    
    render() {
        const isLoading = false; //this.state.isLoading;
        const isLoggedIn = true; //this.state.isLoggedIn;
        return (
            <React.Fragment>
                <ToastContainer />
                <Helmet>	
                    <title>Serenus.AI for Payers/Employers | Serenus.AI&reg;</title>
                </Helmet>
                <div className="row">
                    <div className="col col-12 video-ES" id="video-picker" style={{overflow: 'hidden'}}>
                        <video playsInline autoPlay muted loop src={video} type="video/mp4" id="ESvideo"/>
                        <div className="centered main-title main-title-ES">
                            <h1 className="header-for-pages header-for-pages-ES">Serenus.AI CTEAS™</h1>
                            <h1 className="sub-title">The first Generative AI Clinical Trial Automatic Eligibility Assessment System</h1>
                            {/* <button className="demo-vision" onClick={this.brochure}>Download Brochure</button> */}
                        </div>
                    </div>
                </div>	

                {!isLoading && (
                    !isLoggedIn
                        ? (
                            <div className="col-md-4 offset-md-4 col-sm-6 offset-sm-3 col-12 text-center mt-5">
                                <input type="text" name="password" value={this.state.password} onChange={this.handleChange} placeholder="Please enter the password" />
                                <br />
                                <button className="read-more-press" onClick={this.handleLogin}>Log In</button>
                            </div>
                        )
                        : (
                            <div className="row main-page-ES">
                                <div className="col col-12">

                                    {/* 1 */}
                                    <div className="row">
                                        <div className="col-lg-6 col-md-7 left-text">
                                            <div className="text" dangerouslySetInnerHTMLs={this.putText(0)}>
                                                <h2>Serenus.AI CTEAS™ sets a new standard for clinical trial screening</h2>
                                                <br />
                                                <div>
                                                    Serenus.AI CTEAS™ is introducing the world&#39;s first automated and personalized patients&#39; eligibility evaluation platform that significantly streamlines and personalizes the screening process, optimizing study patient recruitment.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-5 right-image">
                                            <img src={challenge} alt="" />
                                        </div>     
                                    </div>
                                
                                    {/* 2 */}
                                    <div className="row">
                                        <div className="col-md-5 left-image d-none d-md-block">
                                            <img src={cell} alt="" />
                                        </div>
                                        <div className="col-md-7 right-text">
                                            <div className="text" dangerouslySetInnerHTMsL={this.putText(1)}>
                                                <h2>Intelligent, Automated and Personalized Eligibility Assessment</h2>
                                                <br/>
                                                <div>Serenus.AI CTEAS™ uses innovative Generative AI and machine learning techniques to analyze and interpret eligibility criteria from clinical trial protocols while suggesting the appropriateness of each patient taking into consideration the entire clinical history of the patient.</div>
                                            </div>
                                        </div>
                                        <div className="col-md-5 left-image d-md-none">
                                            <img src={cell} alt="" />
                                        </div>
                                    </div>

                                    {/* 3 */}
                                    <div className="row">
                                        <div className="col-lg-6 col-md-7 left-text">
                                            <div className="text" dangerouslySetInnerHTMLs={this.putText(2)}>
                                                <h2>Improved Efficiency</h2>
                                                <br/>
                                                <div>Serenus.AI CTEAS™ automates and personalizes the eligibility criteria assessment process utilizing advanced patented Generative AI technology, reducing the time and effort required for manual screening.</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-5 right-image">
                                            <img src={solution5} alt="" />
                                        </div>
                                    </div>

                                    {/* 4 */}
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 left-image d-none d-md-block">
                                            <img src={solution4} alt="" />
                                        </div>
                                        <div className="col-lg-6 col-md-6 right-text">
                                            <div className="text" dangerouslySetInnerHTMLs={this.putText(3)}>
                                                <h2>Enhanced Accuracy</h2>
                                                <br/>
                                                <div>
                                                    Our advanced Generative AI system ensures accurate identification of eligible patients based on complex criteria, reducing errors, and minimizing unqualified candidates.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-mdlg-6 col-md-6 left-image d-md-none">
                                            <img src={solution4} alt="" />
                                        </div>
                                    </div>

                                    {/* 5 */}
                                    <div className="row">
                                        <div className="col-lg-5 col-md-6 left-text">
                                            <div className="text" dangerouslySetInnerHTMLs={this.putText(4)}>
                                                <h2>Broadened Patient Pool</h2>
                                                <br/>
                                                <div>Serenus.AI CTEAS™ expands the potential participant pool by analyzing vast amounts of patient data, including EHRs and medical literature, to identify suitable candidates.</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-7 col-md-6 right-image">
                                            <img src={market} alt="" />
                                        </div>
                                    </div>

                                    {/* 6 */}
                                    <div className="row">
                                        <div className="col-lg-6 col-md-5 left-image d-none d-md-block">
                                            <img src={team3} alt="" />
                                        </div>
                                        <div className="col-lg-6 col-md-7 right-text">
                                            <div className="text" dangerouslySetInnerHTMLs={this.putText(5)}>
                                                <h2>Seamless integration with Electronic Health Records</h2>
                                                <br/>
                                                <div>Serenus.AI CTEAS™ seamlessly integrates with electronic health records (EHRs) and other data sources, extracting relevant patient information. It employs advanced data analytics to identify patients who meet the eligibility criteria, considering factors such as age, medical history, comorbidities, genetic markers, and more.</div>
                                            </div>
                                        </div>
                                        <div className="left-image d-md-none">
                                            <img src={team3} alt="" />
                                        </div>
                                    </div>

                                    {/* 7 */}
                                    <div className="row">
                                        <div className="col-lg-5 col-md-6 left-text">
                                            <div className="text">
                                                <h2>Optimized patients Recruitment</h2>
                                                <br/>
                                                <div>Serenus.AI CTEAS™ accelerates patient recruitment, reducing delays in clinical trials and enabling faster access to potentially life-saving treatments.</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-7 col-md-6 right-image">
                                            <img src={solution6} alt="" />
                                        </div>
                                    </div>

                                    {/* 8 */}
                                    <div className="row">
                                        <div className="col-lg-6 col-md-5 left-image d-none d-md-block">
                                            <img src={team6} alt="" />
                                        </div>
                                        <div className="col-lg-6 col-md-7 right-text">
                                            <div className="text">
                                                <h2>Cost Savings</h2>
                                                <br/>
                                                <div>By automating and personalizing the eligibility assessment process, the system minimizes manual labor, reducing operational costs associated with participant screening leading to a significantly faster time for patients recruitment.</div>
                                            </div>
                                        </div>
                                        <div className="left-image d-md-none">
                                            <img src={team6} alt="" />
                                        </div>
                                    </div>

                                    {/* 9 */}
                                    <div className="row">
                                        <div className="col-lg-5 col-md-6 left-text">
                                            <div className="text">
                                                <h2>Eliminates patients&#39; recruitment mistakes</h2>
                                                <br/>
                                                <div>The Generative AI technology reduces human bias and subjectivity in patient selection, ensuring a more objective and accurate eligibility determination.</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-7 col-md-6 right-image">
                                            <img src={solution3} alt="" />
                                        </div>
                                    </div>

                                    {/* 10 */}
                                    <div className="row">
                                        <div className="col-lg-6 col-md-5 left-image d-none d-md-block">
                                            <img src={solution2} alt="" />
                                        </div>
                                        <div className="col-lg-6 col-md-7 right-text">
                                            <div className="text">
                                                <h2>Security and Compliance</h2>
                                                <br/>
                                                <div>At Serenus.AI CTEAS™ data security and privacy are our top priorities. We adhere to stringent industry standards and regulations to safeguard patient information. Our platform employs robust encryption protocols and anonymization techniques to ensure confidentiality.</div>
                                            </div>
                                        </div>
                                        <div className="left-image d-md-none">
                                            <img src={solution2} alt="" />
                                        </div>
                                    </div>

                                    {/* <div className="row">
                                        {/* <div className="col-lg-6 col-md-5 left-image teams-image d-none d-md-block">
                                            <img src={team} alt="" />
                                        </div> *\/}
                                        <div className="col-lg-6 col-md-7 left-text"  style={{minHeight:"550px"}}>
                                            <div className="text">
                                                <h2 className="margin-bottom-20">THE TEAM</h2>
                                                <div className="team-list">
                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={orly} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">ADV. Hillary Harel</p>
                                                                    Co-Founder & CEO
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                    Advocate (LLM, cum laude) by training, a seasoned entrepreneur;
                                                                    Winner Women in Tech 2020-2021, Finalist Quesnay's Female Founders; 58th Most Influential Woman in the Tech World
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={josef} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Prof. Josef Elidan</p>
                                                                    Co-Founder & CMO
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                    Former chairman of Otolaryngology dep. at Hadassah; former President of the Israeli Otolaryngology Society
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={dan} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Dan Berachowitz</p>
                                                                    Co-Founder & CTO
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>Experience in pioneering successful hi-tech internet initiatives</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={jonathan2} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Jonathan Gurfinkel</p>
                                                                    Chief Strategic Officer
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>Vast management and technical experience in leading high-tech companies</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={shmuel} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Dr. Shmuel Shannon</p>
                                                                    Algorithm Researcher
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>Machine Learning Expert with a Ph.D. in computation mechanics</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={gal} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Prof. Gal Elidan</p>
                                                                    Advisor
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                Machine Learning Expert. An associate professor position at the Department of statistics at the Hebrew University
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={einat} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Einat Soferman</p>
                                                                    Software Developer
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                Degree in Geophysics and Atmospheric Sciences. Experienced full-stack developer
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-5 right-image teams-image">
                                            <img src={team} alt="" />
                                        </div>
                                    </div> */}

                                    {/* THE TEAM */}
                                    {/* <div className="row">
                                        <div className="col-md-6 left-text"  style={{minHeight:"550px"}}>
                                            <div className="text">
                                                <h2 style={{textAlign:"left"}} className="margin-bottom-20">THE TEAM</h2>
                                                <div className="team-list">
                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={einat} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Einat Soferman</p>
                                                                    Software Developer
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                Degree in Geophysics and Atmospheric Sciences. Experienced full-stack developer
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={amir} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Amir Lifshiz</p>
                                                                    Software Developer
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                Graphic Designer with an Animation and Computer Programming certification
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={katie} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Katie Volovnik</p>
                                                                    Account Manager
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                A degree in Business Administration from Georgia State University, USA. Worked for a financial investment company in America before moving to Israel and focusing on marketing for the last 5 years
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={shaked} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Shaked Pinsker</p>
                                                                    Project Manager
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                A dual degree in Engineering/Industrial Management with a meaningful experience as project manager
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 right-image teams-image">
                                            <img src={team2} alt="" />
                                        </div>
                                    </div> */}

                                    {/* THE TEAM 2 */}
                                    {/* <div className="row">
                                        <div className="col-md-6 left-image teams-image d-none d-md-block">
                                            <img src={team3} alt="" />
                                        </div>
                                        <div className="col-md-6 right-text"  style={{minHeight:"550px"}}>
                                            <div className="text">
                                                <div className="team-list">
                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={andrew} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Andrew Michaelson</p>
                                                                    Europe Manager
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                Degrees in Computer Science & Network Engineering with more than 15 years of experience in the healthcare market
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={janice} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Janice Reese</p>
                                                                    US Strategic Partnership Manager
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                    Business Development, Sales and Marketing Executive – Over 30 years of experience
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={mauricio} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Mauricio Somekh</p>
                                                                    South America Manager
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                Over 30 years of experience in business development to the South American insurance market. working as an International Business Agent for a company called Noble Biomaterials, Inc
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 left-image d-md-none">
                                            <img src={team3} alt="" />
                                        </div>
                                    </div> */}

                                    {/* THE ADVISORS */}
                                    {/* <div className="row">
                                        <div className="col-md-6 left-text"  style={{minHeight:"550px"}}>
                                            <div className="text">
                                                <h2 style={{textAlign:"left"}} className="margin-bottom-20">THE ADVISORS (PARTIAL LIST)</h2>
                                                <div className="team-list">
                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={gal} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Prof. Gal Elidan</p>
                                                                    Machine Learning Expert
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                An associate professor position at the Department of statistics at the Hebrew University
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={jonathan2} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Jonathan Gurfinkel</p>
                                                                    Strategic Advisor
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                Vast management and technical experience in leading high-tech companies. B.Sc. and M.Sc. in Electrical Engineering, EMBA
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={david} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Prof. David Schneider</p>
                                                                    Gynecology
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                Head of the Gyno-Oncological Unit and the Gynaecology Section of the Obstetrics and Gynaecology Division, Shamir (Assaf Harofeh) Medical Center
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={dean} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Dr. Dean Ad-El</p>
                                                                    Plastic Surgery & Burns
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                Head of the Plastic Surgery and Burns unit, Belinson center. An expert in the field of plastic surgery
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 right-image teams-image">
                                            <img src={team4} alt="" />
                                        </div>
                                    </div> */}

                                    {/* THE ADVISORS 2 */}
                                    {/* <div className="row">
                                        <div className="col-md-6 left-image teams-image d-none d-md-block">
                                            <img src={team5} alt="" />
                                        </div>
                                        <div className="col-md-6 right-text"  style={{minHeight:"550px"}}>
                                            <div className="text">
                                                <div className="team-list">
                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={michael} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Prof. Michael Eldar</p>
                                                                    Cardiology
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                Former Director of the Heart Institute and the Leviev Heart Center at Sheba Medical Center, one of Israel’s largest and most prominent hospitals
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={ran} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Prof. Ran Oren</p>
                                                                    Gastroenterology
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                Former Head of Institute of Gastroenterology & Liver Diseases, Hadassah Medical Center
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={yael} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Dr. Oestreicher-Kedem Yael</p>
                                                                    Otolaryngology , Head and Neck Surgery
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                Senior Physician, Over 12 years of experience. Currently working as an Head and Neck surgeon at Assuta Medical Centers
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={steven} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Prof. Steven Velkes</p>
                                                                    Orthopedics
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                Head of orthopaedic department, Beilinson-Rabin Medical Center Previously Director, Department of Orthopaedics and Arthroscopy & Knee Surgery, Barzilai Hospital, Ashkelon.
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 left-image d-md-none">
                                            <img src={team5} alt="" />
                                        </div>
                                    </div> */}


                                    {/* THE ADVISORS 3 */}
                                    {/* <div className="row">
                                        <div className="col-md-6 left-text"  style={{minHeight:"550px"}}>
                                            <div className="text">
                                                <div className="team-list">
                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={shai} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Dr. Shai Shemesh</p>
                                                                    Orthopedics
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                Senior Physician, Over 12 years of experience. Currently working as an Orthopaedic Surgeon at Rabin Medical Center and as Clinical Fellow at Jackson and at Mount Sinai Health System
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={yehuda} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Prof. Yehuda Skornick</p>
                                                                    General Surgery
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                Senior Surgeon and one of the leading surgeons in Israel. General Surgery and Oncology Specialist
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={alexander} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Dr. Alexander Fardman</p>
                                                                    Cardiac Care Expert
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                Acute Cardiac Care , Risk Factors and Prevention, Acute Cardiac Care and Acute Coronary Syndromesa at Chaim Sheba Medical Center, Tel Hashomer
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={yair} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Prof. Yair Herishano</p>
                                                                    Hematology Expert
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                Head of CLL department, senior lecturer for Hematology in Tel Aviv university and over 17 years of experience in the medical field as doctor and director
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 right-image teams-image">
                                            <img src={team6} alt="" />
                                        </div>
                                    </div> */}

                                    {/* THE ADVISORS 4 */}
                                    {/* <div className="row">
                                        <div className="col-md-6 left-image teams-image d-none d-md-block">
                                            <img src={team7} alt="" />
                                        </div>
                                        <div className="col-md-6 right-text"  style={{minHeight:"550px"}}>
                                            <div className="text">
                                                <div className="team-list">
                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={ofer} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Ofer Ben-Yehuda</p>
                                                                    Investor
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                A Partner at Shibolet & Co's. High-Tech practice group.
                                                                Over 15 years of experience in mergers & acquisitions, centure capital and start-ups
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={ohad} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Ohad Madanes</p>
                                                                    Investor
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                CEO of the Madanes Group, the largest privately owned insurance broker in Israel and holds a BA in Business Managment from IDC Herzliya
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={kobi} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Kobi Bendelak</p>
                                                                    Investor
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                CEO of InsurTech Israel that was established by him to promote and lead the Israeli Insurtech ecosystem. Has 22 years' of experience in the insurance industry
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={boaz} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Boaz Toshav</p>
                                                                    Investor
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                Founder and CEO, Umbrella Advisors; Co-founder, Madanes Insure-Tech Inn. Lab; Board Member, public and private entities.
                                                                More than 17 years of professional experience as an investment banker
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 left-image d-md-none">
                                            <img src={team7} alt="" />
                                        </div>
                                    </div> */}

                                    {/* THE ADVISORS 5 */}
                                    {/* <div className="row">
                                        <div className="col-md-6 left-text"  style={{minHeight:"550px"}}>
                                            <div className="text">
                                                <div className="team-list">
                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={nir} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Nir Tarlovsky</p>
                                                                    Board Director
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                An entrepreneur and angel investor for the last 25 years. Skilled in E-commerce, Entrepreneurship, Venture Capital, Strategic Partnerships, and Mobile Applications
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={keren} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Keren Kopilov</p>
                                                                    Board Director
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                15 years experience in financial management, holds 4 board and advisor roles. Chairperson at JDRF, she has a BA in Economic and Business and MBA in accounting and financial management
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={jonathan} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Jonathan Benartzi</p>
                                                                    Investor
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                25 years experience in Start-ups, Strategic Partnerships, Business Strategy , Business Development in variety of top-level positions
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="team-item team-container">
                                                        <div className="style style-ES">
                                                            <div className="team-item-section">
                                                                <img className="team-image" src={james} alt=""/>
                                                                <span className="font-size-18" style={{paddingLeft: '10px'}}>
                                                                    <p className="team-title">Dr. James L. Voiland</p>
                                                                    Strategic Advisor
                                                                </span>
                                                            </div>
                                                            <div className="team-item-section">
                                                                <span style={{fontSize:"14px"}}>
                                                                Over 10 years experience as healthcare executive, COO and VP. During this time he managed the most efficient medical management department, rebuilt health plans, served as key advisor and business partner to the President/CEO and board of directors.
                                                                As well as, served in many more executive positions in organizations
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 right-image teams-image d-none d-md-block">
                                            <img src={team8} alt="" />
                                        </div>
                                    </div> */}


                                </div>
                            </div>
                        )
                    )
                }
            </React.Fragment>
        )
    }
}

export default CTEAS;
