import React, { Component } from "react";
import video from "../images/header.mp4";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';

class FinishedSimulator extends Component {

    state = {
        id: null,
        errorMessage: ""
    };

    componentDidMount = () => {
        // Extracting id from URL parameters
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');

        if (id) {
            this.setState({ id });
        } else {
            this.setState({ errorMessage: "Error. Questionnaire ID not found" });
        }
    }

    copyToClipboard = () => {
        const { id } = this.state;
        navigator.clipboard.writeText(id)
            .then(() => toast.success('ID copied to clipboard'))
            .catch(err => toast.error('Could not copy ID'));
    }

    render() {
        const { id, errorMessage } = this.state;

        return (
            <React.Fragment>
                <ToastContainer />
                <Helmet>	
                    <title>Merge Questionnaires Demo | Serenus.AI&reg;</title>
                </Helmet>
                <div className="row">
                    <div className="col col-12 video-ES" id="video-picker" style={{overflow: 'hidden'}}>
                        <video playsInline autoPlay muted loop src={video} type="video/mp4" id="ESvideo"/>
                        <div className="centered main-title main-title-ES">
                            <h1 className="header-for-pages header-for-pages-ES">Redirect from Questionnaire</h1>
                            <h4 className="sub-title">
                                {id ? (
                                    <p>
                                        Questionnaire #{id} is done
                                        <br/><br/>
                                        <button className="btn btn-info" onClick={this.copyToClipboard}>Copy ID to clipboard</button>
                                    </p>
                                ) : (
                                    <p style={{color: "red"}}>{errorMessage}</p>
                                )}
                            </h4>
                        </div>
                    </div>
                </div>	
                <div>
                    
                </div>
            </React.Fragment>
        );
    }
}

export default FinishedSimulator;