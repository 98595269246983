import React, { Component, useState } from "react";
import contact from '../images/contact.jpg';
import { useForm } from 'react-hook-form/dist/index.ie11'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	NavLink
  } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { b2cUrl } from "./constants";

const axios = require('axios');


const ContactUs = () => {

	const [isSubmit, setIsSubmit] = useState(false);
	const { register, handleSubmit, errors } = useForm(); 

	async function onSubmit(data) {
		setIsSubmit(true);
		try {
			let firstNameErr='';
			let lastNameErr='';
			let emailErr='';
			let phoneErr='';
			let subjectErr='';
			let messageErr='';

			// const url = 'http://localhost/serenus-ai/b2c/serenus-contact';
			const url = b2cUrl() + 'serenus-contact';

			let res = await axios({
				method: 'post',
				url: url,
				data: {
					firstName: data.firstname,
					lastName: data.lastname,
					email: data.email,
					phone: data.phone,
					subject: data.subject,
					message: data.message
				},
				headers: {
					'Content-Type': 'application/json;charset=UTF-8'
					// "Access-Control-Allow-Origin": "*",
					// 'Accept': 'application/json'
				}
			});

			if (res.status == 200) {
				if (Array.isArray(res.data)) {
					for (let i = 0; i < res.data.length; i++) {
						if (res.data[i]) {
							if (i == 0) {
								firstNameErr = res.data[i];
							} else if (i == 1) {
								lastNameErr = res.data[i];
							} else if (i == 2) {
								emailErr = res.data[i];
							} else if (i == 3) {
								phoneErr = res.data[i];
							} else if (i == 4) {
								subjectErr = res.data[i];
							} else if (i == 5) {
								messageErr = res.data[i];
							}
						}
					}
					
					if (firstNameErr || lastNameErr || emailErr || phoneErr || subjectErr || messageErr) {
						if (firstNameErr) {
							document.getElementById("err-first-name").innerHTML = firstNameErr;
						}
						if (lastNameErr) {
							document.getElementById("err-last-name").innerHTML = lastNameErr;
						}
						if (emailErr) {
							document.getElementById("err-email").innerHTML = emailErr;
						}
						if (phoneErr) {
							document.getElementById("err-phone").innerHTML = phoneErr;
						}
						if (subjectErr) {
							document.getElementById("err-company").innerHTML = subjectErr;
						}
						if (messageErr) {
							document.getElementById("err-title").innerHTML = messageErr;
						}
					}
				} else {
					if (res.data.includes("Message has been sent")) {
						toast.success("Your message sent successfully!");
						let frm = document.getElementById('contact-form');
						frm.reset();
						setIsSubmit(false);
						return false;
					} else {
						toast.error("The site is experiencing technical difficulties, please try later");
					}
				}
			}
			setIsSubmit(false);
		} catch {
			toast.error("The site is experiencing technical difficulties, please try later");
			setIsSubmit(false);
		}
	}

	return ( 
		<React.Fragment>
			<ToastContainer />
			<Helmet>	
				<title>Contact | Serenus.AI&reg;</title>
			</Helmet>
			<div className="col col-12" id="image-picker">
				<img src={contact} alt="" width="100%" height="450px" />
				<div style={{position:"absolute", width:"100%", height:"100%", background:"#000000", opacity:"0.5", top:0}}></div>
				<div className="centered main-title" style={{textAlign:"center", width:"100%"}}>
					<h1 class="header-for-pages">Contact us</h1>
					<p>Tell us a little bit about yourself and we will be in touch shortly. <br/>
					We would love to hear from you.</p>
				</div>
			</div>
			<div className="col col-12">
				<div className="row" style={{marginTop:"40px"}}>
					<div className="col-md-8 col-xs-12">
						<form onSubmit={handleSubmit(onSubmit)} className="contact-us" id="contact-form">
							<div className="col-12">
								<h4 style={{color:"#172852"}}>Let's Talk:</h4>
								<div className="row">
									<div className="col-md-6 col-xs-12 contact-page">
										<label htmlFor="fname" >First Name*</label>
										<input type="text" id="fname" name="firstname" ref={register({ required: true, maxLength:100})}  placeholder="First Name"/>
										<span className="err">
											{errors.firstname && errors.firstname.type === "required" && <span>First name is required</span>}
											{errors.firstname && errors.firstname.type === "maxLength" && <span>Max length exceeded</span> }
										</span>
									</div>
									<div className="col-md-6 col-xs-12 contact-page">
										<label htmlFor="lname" >Last Name*</label>
										<input type="text" id="lname" name="lastname" ref={register({ required: true, maxLength:100 })} placeholder="Last Name"/>
										<span className="err">
											{errors.lastname && errors.lastname.type === "required" && <span>Last name is required</span>}
											{errors.lastname && errors.lastname.type === "maxLength" && <span>Max length exceeded</span> }		
										</span>	
									</div>
								</div>

								<div className="row">
									<div className="col-md-6 col-xs-12 contact-page " style={{justifyContent:"right"}}>
										<label htmlFor="email" >Email*</label>
										<input type="email" id="email" name="email" ref={register({ required: true })} placeholder="Email"/>
										<span className="err">{errors.email && '*Email is required'} </span>
									</div>

									<div className="col-md-6 col-xs-12 contact-page" style={{justifyContent:"right"}}>
										<label htmlFor="phone" >Phone*</label>
										<input type="phone" id="phone" name="phone" ref={register({ required: true, maxLength:14, minLength:9 })} placeholder="Phone"/>
										<span className="err">
											{errors.phone && errors.phone.type === "required" && <span>Phone is required</span>}
											{errors.phone && errors.phone.type === "maxLength" && <span>Max length exceeded</span> }	
											{errors.phone && errors.phone.type === "minLength" && <span>Min length exceeded</span> }			
										</span>	
									</div>
								</div>
					
								<div className="row">
									<div className="col-12 contact-page">
										<label htmlFor="subject">Subject*</label>
										<input type="text" id="subject" name="subject" ref={register({ required: true, maxLength:200 })} placeholder="Subject"></input>
										<span className="err">
											{errors.subject && errors.subject.type === "required" && <span>Subject is required</span>}
											{errors.phone && errors.phone.type === "maxLength" && <span>Max length exceeded</span> }	
										</span>
									</div>
								</div>

								<div className="row">
									<div className="col-12 contact-page">
										<label htmlFor="message">Message*</label>
										<textarea name="message" id="message" cols="30" ref={register({ required: true, maxLength: 500 })} rows="5" placeholder="Message"></textarea>
										<span className="err">
											{errors.message && errors.message.type === "required" && <span>Subject is required</span>}
											{errors.message && errors.message.type === "maxLength" && <span>Max length exceeded</span> }	
										</span>
									</div>
								</div>
							</div>

							<div className="col-12" style={{textAlign: "center"}}>
								<button className="submit" type="submit" value="Submit" style={{background:"#172852", color:"white"}} disabled={isSubmit}>
									{isSubmit
										? (<React.Fragment><span className="spinner-border spinner-border-sm"></span> Sending...</React.Fragment>)
										: 'Submit'
									}
								</button>
							</div>
							<br/>
							<p style={{color:"#172852", marginBottom:"0px"}}>*By submitting I acknowledge Serenus.AI's <Link style={{color:"#172852"}} to="/privacy-policy">Privacy Policy</Link>.</p>
						</form>

					</div> 

					<div className="col-md-4 col-xs-12">
						<div className="info center" style={{marginBottom:"13px", padding:"31px"}}>
							<h4 className="bold" style={{textAlign:"left"}}>Israel</h4>
							<p style={{textAlign:"left"}}>
								<span>Headquarters: <br/>
									Rofe HaMahtarot 32, Tel-Aviv<br/>
								</span>
							</p>
							<span style={{textAlign:"left"}}>
								<p>
									Email: <br/>
									Information:  <a href="mailto:info@serenusai.com" >info@serenusai.com</a> <br/>
									Investor Relations:<a href="mailto: investors@serenusai.com" className="margin-top-5"> investors@serenusai.com</a> <br/>
									Press:<a href="mailto: pr@serenusai.com" > pr@serenusai.com</a> <br/>
								</p>
							</span>
						</div>

						<div className="info center" style={{textAlign:"left", padding:"31px"}}>
							<h4 className="bold">Global Inquiries</h4>
							<p>
								Europe: <a href="mailto:europe@serenusai.com">europe@serenusai.com</a> <br/>
								Asia: <a href="mailto: asia@serenusai.com"> asia@serenusai.com</a> <br/>
								USA: <a href="mailto: usa@serenusai.com"> usa@serenusai.com</a> <br/>
								South America: <a href="mailto: southamerica@serenusai.com"> southamerica@serenusai.com</a> <br/>
								Australia: <a href="mailto: australia@serenusai.com"> australia@serenusai.com</a> <br/>
								Africa: <a href="mailto: africa@serenusai.com"> africa@serenusai.com</a>
							</p>
						</div>
					</div>
				</div>
			</div>
			<br/>
		</React.Fragment>
	);
}

export default ContactUs;
