const isLocalhost = window.location.hostname === 'localhost';

export function baseApiUrl() {
    return isLocalhost ? 'http://localhost/medecide-api/public/' : 'https://core-dev.serenusai.com/public/';
}

export function b2cUrl() {
    return isLocalhost ? 'http://localhost:8001/' : 'https://mymedicalowl.com/';
}

export function clientUrl() {
    return isLocalhost ? 'http://localhost:8080' : 'https://dev.serenusai.com';
}

export function selfUrl() {
    return isLocalhost ? 'http://localhost:3000' : 'https://serenusai.com';
}