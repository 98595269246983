import React, { Component } from "react";


class AdminPanel extends Component {

	state = {  }
	
	render() { 
		return ( 
			<p>works</p>
		 );
	}
}
 
export default AdminPanel;