import React, { Component } from "react";
import Slider from "react-slick";
import innovation from "../images/award/innovation2.png"
import astra from "../images/award/astra.jpg"
import bird from "../images/award/bird.jpg"
import center from "../images/award/center.png"
import ey from "../images/award/ey.jpg"
import hartford from "../images/award/hartford.png"
import health from "../images/award/health.png"
import mtl from "../images/award/mtl.jpg"
import scripts from "../images/award/scripts.png"
import venture from "../images/award/venture.png"
import economic from "../images/award/economic.jfif"


const Logos = () => {

	var settings = {
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 5,
		slidesToScroll: 2
	  };

	return ( 
		<React.Fragment className="margin-bottom-50">
			<div className="row" style={{textAlign:"center"}}>
				<div className="col-md-3 col-sm-6 col-xs-12">
					<img className="img-height-60 logos innovation" src={innovation} alt=""/>
				</div>
				<div className="col-md-3 col-sm-6 col-xs-12">
					<img className="img-height-140 logos"  src={astra} alt=""/>
				</div>
				<div className="col-md-3 col-sm-6 col-xs-12">
					<img className="img-height-100 logos"  src={bird} alt=""/>
				</div>
				<div className="col-md-3 col-sm-6 col-xs-12 center-logo" style={{position:"relative", top:"20px"}}>
					<img className="img-height-60 logos" src={center} alt=""/>
				</div>
			</div>

			<div className="row" style={{textAlign:"center"}}>
				<div className="col-md-3 col-sm-6 col-xs-12">
					<img className="img-height-140 logos"  src={health} alt=""/>
				</div>
				<div className="col-md-3 col-sm-6 col-xs-12">
					<img className="img-height-100 logos"  src={mtl} alt=""/>
				</div>
				<div className="col-md-3 col-sm-6 col-xs-12">
					<img className="img-height-100 logos"  src={scripts} alt=""/>
				</div>
				<div className="col-md-3 col-sm-6 col-xs-12 venture">
					<img className="img-height-100 logos"  src={venture} alt=""/>
				</div>
			</div>

			<div className="row" style={{textAlign:"center"}}>
				<div className="col-md-3 col-sm-6 col-xs-12 venture">
					<img className="img-height-100 logos"  src={hartford} alt=""/>
				</div>
				<div className="col-md-3 col-sm-6 col-xs-12 margin-bottom-40" style={{display:"inline-block"}}> 
					<div className=" logos women" alt="" style={{fontSize:"0.75em", height:"60px", width:"130px", paddingTop:"4px", paddingRight:"10px", paddingLeft:"10px",position:"relative",top:"20px", color:"white",fontWeight:"400",textAlign:"center",margin:"0 auto"}}>2020 EUROPE WOMEN TECH AWARD</div>
				</div>

				<div className="col-md-3 col-sm-6 col-xs-12">
					<img className="img-height-100 logos"  src={ey} alt=""/>
				</div>
				<div className="col-md-3 col-sm-6 col-xs-12">
					<img className="img-height-120 logos"  src={economic} alt=""/>
				</div>
			</div>
	  	</React.Fragment>
	);
}
 
export default Logos;