import React, { Component } from "react";
import { Helmet } from 'react-helmet';
import Background from '../images/team.jpg';
// People
import orly from '../images/team/orly.png';
import josef from '../images/team/josef.png';
import dan from '../images/team/dan.png';
import shmuel from '../images/team/shmuel.png';
import einat from '../images/team/einat.png';
import katie from '../images/team/katie.png';
import shaked from '../images/team/shaked.png';
import andrew from '../images/team/andrew.png';
import janice from '../images/team/janice.png';
import mauricio from '../images/team/mauricio.png';
import david from '../images/team/david.png';
import dean from '../images/team/dean.png';
import michael from '../images/team/michael.png';
import ran from '../images/team/ran.png';
import yael from "../images/team/yael.jpg";
import steven from '../images/team/steven.png';
import shai from '../images/team/shai.png';
import yehuda from '../images/team/yehuda.png';
import alexander from '../images/team/alexander.png';
import yair from '../images/team/yair.png';
import gal from '../images/team/gal.png';
import ofer from '../images/team/ofer.png';
import ohad from '../images/team/ohad.png';
import kobi from '../images/team/kobi.png';
import boaz from '../images/team/boaz.png';
import nir from '../images/team/nir.png';
import keren from '../images/team/keren.png';
import jonathan from '../images/team/jonathan.png';
import james from '../images/team/james.png';
import jonathan2 from '../images/team/jonathan2.png';
import dana from '../images/team/dana-new.png';
import amir from '../images/team/amir.png';
import sapir from '../images/team/sapir.jpg';
import mosh from '../images/team/mosh.png';
import shanee from '../images/team/shanee.png';
import keith from '../images/team/keith.png';
import shaindy from '../images/team/shaindy.png';
// Education institutions
import huji from "../images/education/huji.png";
import ucla from "../images/education/ucla.png";
import technion from "../images/education/technion.png";
import tau from "../images/education/tel-aviv.png";
import benGurion from "../images/education/bgu.png";
import barilan from "../images/education/bar-ilan.png";
import gsu from "../images/education/gsu.png";
import tennessee from "../images/education/tennessee.png";
import stanford from "../images/education/stanford.png";
import saddleback from "../images/education/saddleback.png";
import elpaso from "../images/education/el-paso.png";
import ono from "../images/education/ono.png";
import idc from "../images/education/idc.png";
import shenkar from "../images/education/shenkar.jpeg";
import hackeru from "../images/education/hackeru.png";
import openuniversity from "../images/education/openuniversity.png";
import shore from "../images/education/shore.png";
import buniversity from "../images/education/buniversity.png";
import northeastern from "../images/education/northeastern.jpg";
// Hospitals
import hadassah from "../images/hospitals/hadassah.jpg";
import sheba from "../images/hospitals/sheba.png";
import sourasky from "../images/hospitals/sourasky.png";
import ssm from "../images/ssm.png";
import rabin from "../images/hospitals/rabin.png";
import shamir from "../images/hospitals/shamir.png";
import assaf from "../images/hospitals/assaf.png";


const Team = () => {

	return ( 
		<React.Fragment>
			<Helmet>
				<title>Team | Serenus.AI&reg;</title>
			</Helmet>
			<div className="col col-12" id="image-picker">
				<img src={Background} alt="" height="400px" width="100%" />
				<div style={{position:"absolute", width:"100%", height:"100%", background:"#000000", opacity:"0.4", top:0}}></div>
				<div className="centered main-title" style={{textAlign:"center", width:"100%"}}>
					<h1 class="header-for-pages">Team</h1>
				</div>
			</div>

			<div className="wrapper">
				<div className="container">
					<div className="col col-12 margin-bottom-50">
						<h2 className="margin-bottom-20">Meet the team behind Serenus.AI</h2>
						<p style={{textAlign:"center", marginBottom:"20px"}}>
							The professional staff of Serenus.AI, including top physicians from various medical fields and machine learning specialists, have the requisite experience, knowledge and resources to rapidly and effectively meet the diverse needs of Serenus.AI's customers implementing appropriate science and technology. <br/>
							Our team combines a cutting-edge innovation mindset with a dedicated belief in making a real impact on the healthcare world today.
						</p>
						<br/>
						<h4 className="team-sections margin-bottom-20">Our Leadership Team</h4> <br />
						<div className="row  margin-bottom-20"  style={{justifyContent:"center"}}>
							<div className="row" style={{width:"100%",justifyContent:"center"}}></div>
							<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
								<div className="style">
									<img className="team" src={orly} alt=""/>
									<span className="font-size-18">
										<p className="team-title">ADV. Hillary Harel</p>
										Co-Founder & CEO
									</span> <br/>
									<span style={{fontSize:"14px"}}>
										Advocate (LLM, cum laude) by training, a seasoned entrepreneur;
										Winner Women in Tech 2020-2021, Finalist Quesnay's Female Founders; 58th Most Influential Woman in the Tech World
										<br/>  
										<img className="academi-logo" src={huji} alt="" style={{bottom:"10px"}}	/>
									</span>
								</div>
							</div>
							<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
								<div className="style">
									<img className="team" src={josef} alt=""/>
									<span className="font-size-18">
										<p className="team-title">Prof. Josef Elidan</p>
										Co-Founder & CMO
									</span> <br/>
									<span style={{fontSize:"14px"}}>
										Former chairman of Otolaryngology dep. at Hadassah; former President of the Israeli Otolaryngology Society <br/> <br /><br />
										<br/>
										<img className="academi-logo" src={huji} alt=""/>
										<img className="academi-logo" style={{marginRight:"5px",marginLeft:"5px"}} src={ucla} alt=""/>
										<img className="academi-logo" src={hadassah} alt=""/>
									</span>
								</div>
							</div>

							<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
								<div className="style">
									<img className="team" src={dan} alt=""/>
									<span className="font-size-18">
										<p className="team-title">Dan Berachowitz</p>
										Co-Founder & CTO
									</span> <br/>
									<span style={{fontSize:"14px"}}>Experience in pioneering successful hi-tech internet initiatives</span><br/> <br /> <br />
									<br/>
									<br/>
									<img className="academi-logo" src={technion} alt="" style={{bottom:"8px"}}/>
								</div>
							</div>

							{/* <div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
								<div className="style">
									<img className="team" src={shmuel} alt=""/>
									<span className="font-size-18">
										<p className="team-title">Dr. Shmuel Shannon</p>
										Algorithm Researcher
									</span> <br/>
									<span style={{fontSize:"14px"}}>Machine Learning Expert with a Ph.D. in computation mechanics</span><br/> <br /> <br />
									<br/>
									<br/>
									<img className="academi-logo" src={benGurion} alt="" style={{bottom:"8px"}}/>
								</div>
							</div> */}

						</div>

						<div className="row  margin-bottom-20" style={{justifyContent:"center"}}>
							<div className="row" style={{width:"100%",justifyContent:"center"}}></div>

							{/* <div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
								<div className="style">
									<img className="team" src={jonathan2} alt=""/>
									<span className="font-size-18">
										<p className="team-title">Jonathan Gurfinkel</p>
										Chief Strategic Officer
									</span> <br/>
									<span style={{fontSize:"14px"}}>
										Vast management and technical experience in leading high-tech companies. B.Sc. and M.Sc. in Electrical Engineering, EMBA from the Hebrew University of Jerusalem
										<br/> <br/>
										<img className="academi-logo" src={benGurion} alt="" />
										<img className="academi-logo" src={huji} alt="" />
									</span>
								</div>
							</div> */}

							<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
								<div className="style">
									<img className="team" src={shmuel} alt=""/>
									<span className="font-size-18">
										<p className="team-title">Dr. Shmuel Shannon</p>
										Algorithm Researcher
									</span> <br/>
									<span style={{fontSize:"14px"}}>Machine Learning Expert with a Ph.D. in computation mechanics</span><br/> <br /> <br />
									<br/>
									<br/>
									<img className="academi-logo" src={benGurion} alt="" style={{bottom:"8px"}}/>
								</div>
							</div>

							<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
								<div className="style">
									<img className="team" src={einat} alt=""/>
									<span className="font-size-18">
										<p className="team-title">Einat Soferman</p>
										Software Developer
									</span> <br/>
									<span style={{fontSize:"14px"}}>
										Degree in Geophysics and Atmospheric Sciences. Former Senior Meteorology Officer. Experienced full-stack developer and a technology enthusiast<br/>
										<br/>
										<img className="academi-logo" src={tau} alt="" />
									</span>
								</div>
							</div>
						</div>
						
						{/* <br /><br />
						<h4 className="team-sections margin-bottom-20">Team</h4> <br />
						<div className="row margin-bottom-20">


							<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
								<div className="style">
									<img className="team" src={einat} alt=""/>
									<span className="font-size-18">
										<p className="team-title">Einat Soferman</p>
										Software Developer
									</span> <br/>
									<span style={{fontSize:"14px"}}>
										Degree in Geophysics and Atmospheric Sciences. Former Senior Meteorology Officer. Experienced full-stack developer and a technology enthusiast<br/>
										<br/>
										<img className="academi-logo" src={tau} alt="" />
									</span>
								</div>
							</div>

							<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
								<div className="style">
									<img className="team" src={amir} alt=""/>
									<span className="font-size-18">
										<p className="team-title">Amir Lifshiz</p>
										Software Developer
									</span> <br/>
									<span style={{fontSize:"14px"}}>
										Graphic Designer with an Animation and Computer Programming certification <br/>
										<br/> <br/><br />
										<img className="academi-logo" src={hackeru} alt="" />
									</span>
								</div>
							</div>

							<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
								<div className="style" style={{width:"100%"}}>
									<img className="team object-cover" src={katie} alt=""/>
									<span className="font-size-18">
										<p className="team-title">Katie Volovnik</p>
										Account Manager
									</span> <br/>
									<span style={{fontSize:"14px"}}>
										A degree in Business Administration from Georgia State University, USA.
										Worked for a financial investment company in America before moving to Israel and focusing on marketing for the last 5 years<br/>
										<img className="academi-logo" src={gsu} alt="" />
									</span>
								</div>
							</div>

							<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
								<div className="style">
									<img src={shaindy} style={{width:"120px !important", height:"80px"}} alt=""/>
									<span className="font-size-18">
										<p className="team-title">Shaindy Eisenberger</p>
										Account Manager
									</span> <br/>
									<span style={{fontSize:"14px"}}>
										A dual degree in political science and communications. <br /> As well as over 7 years of experience in organizing, marketing and management.<br/>
										<br/><img className="academi-logo" src={barilan} alt="" />
									</span>
								</div>
							</div>

							<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
								<div className="style">
									<img src={shaked} style={{width:"120px !important", height:"80px"}} alt=""/>
									<span className="font-size-18">
										<p className="team-title">Shaked Pinsker</p>
										Project Manager
									</span> <br/>
									<span style={{fontSize:"14px"}}>
										A dual degree in Engineering/Industrial Management with a meaningful experience as project manager and administrative professional<br/>
										<br/><img className="academi-logo" src={shenkar} alt="" />
									</span>
								</div>
							</div>
						
						</div> */}
						{/* <div className="row margin-bottom-20" style={{justifyContent:"center"}}>

							<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
								<div className="style">
									<img className="team" src={andrew} alt=""/>
									<span className="font-size-18">
										<p className="team-title">Andrew Michaelson</p>
										Europe Manager
									</span> <br/>
									<span style={{fontSize:"14px"}}	>
										Degrees in Computer Science & Network Engineering with more than 15 years of experience in the healthcare market <br/>
										<br/> <br />
										<img className="academi-logo relative" src={saddleback} alt="" />
									</span>
								</div>
							</div>

							<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
								<div className="style">
									<img src={janice} style={{width:"120px !important", height:"80px"}} alt=""/>
									<span className="font-size-18">
										<p className="team-title">Janice Reese</p>
										US Strategic Partnership Manager
									</span> <br/>
									<span style={{fontSize:"14px"}}>
										Business Development, Sales and Marketing Executive –
										Over 30 years of experience <br/> <br />
										<br/><img className="academi-logo" src={tennessee} alt="" />
									</span>
								</div>
							</div>

							<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
								<div className="style">
									<img className="team" src={mauricio} alt=""/>
									<span className="font-size-18">
										<p className="team-title">Mauricio Somekh</p>
										South America Manager
									</span> <br/>
									<span style={{fontSize:"14px"}}>
										Over 30 years of experience in business development to the South American insurance market. working as an International Business Agent for a company called Noble Biomaterials, Inc
									</span>
								</div>
							</div>
						
						</div>  */}
					</div>

					<br /><br />
					<h4 className="team-sections margin-bottom-20">Our Top-Notch Advisors (Partial List)</h4> <br />
					<div className="row margin-bottom-20">

						<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
							<div className="style">
								<img className="team" src={gal} alt=""/>
								<span className="font-size-18">
									<p className="team-title">Prof. Gal Elidan</p>
									Machine Learning Expert
								</span> <br/>
								<span style={{fontSize:"14px"}}>
									An associate professor position at the
									Department of statistics at the Hebrew University
									<br/> <br/><br/><br/>
									<img className="academi-logo" src={technion} alt="" />
									<img className="stanford" style={{width:"70px !important"}} src={stanford} alt="" />
									<img className="academi-logo" src={huji} alt="" />
								</span>
							</div>
						</div>

						{/* <div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
							<div className="style">
								<img className="team" src={jonathan2} alt=""/>
								<span className="font-size-18">
									<p className="team-title">Jonathan Gurfinkel</p>
									Strategic Advisor
								</span> <br/>
								<span style={{fontSize:"14px"}}>
									Vast management and technical experience in leading high-tech companies. B.Sc. and M.Sc. in Electrical Engineering, EMBA from the Hebrew University of Jerusalem
									<br/> <br/>
									<img className="academi-logo" src={benGurion} alt="" />
									<img className="academi-logo" src={huji} alt="" />
								</span>
							</div>
						</div> */}

						<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
							<div className="style">
								<img className="team" src={david} alt=""/>
								<span className="font-size-18">
									<p className="team-title">Prof. David Schneider</p>
									Gynecology
								</span> <br/>
								<span style={{fontSize:"14px"}}>
									Head of the Gyno-Oncological Unit and the Gynaecology Section of the Obstetrics and Gynaecology Division, Shamir (Assaf Harofeh) Medical Center <br/>
									<br/>
									<img className="academi-logo " src={shamir} alt="" />
								</span>
							</div>
						</div>

						<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
							<div className="style">
								<img className="team" src={dean} alt=""/>
								<span className="font-size-18">
									<p className="team-title">Dr. Dean Ad-El</p>
									Plastic Surgery & Burns 
								</span> <br/>
								<span style={{fontSize:"14px"}}>
									Head of the Plastic Surgery and Burns unit, Belinson center. Dr Dean D. Ad-El is an expert in the field of plastic surgery <br/>
									<br/><br/>
									<img src={rabin} className="academi-logo" alt="" style={{position:"relative"}}/>
									{/* className="top-30-px" */}
								</span>
							</div>
						</div>

						<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
							<div className="style">
								<img className="team" src={michael} alt=""/>
								<span className="font-size-18">
									<p className="team-title">Prof. Michael Eldar</p>
									Cardiology
								</span> <br/><br/>
								<span style={{fontSize:"14px"}}>
									Former Director of the Heart Institute and the Leviev Heart Center at Sheba Medical Center, one of Israel’s largest and most prominent hospitals <br/>
									<br/><img className="academi-logo " src={sheba} alt="" />
									{/* style={{position:"relative", top:"10px"}} */}
								</span>
							</div>
						</div>

					</div>

					<div className="row margin-bottom-20">

						<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
							<div className="style">
								<img className="team" src={ran} alt=""/>
								<span className="font-size-18">
									<p className="team-title">Prof. Ran Oren</p>
									Gastroenterology
								</span> <br/><br/>
								<span style={{fontSize:"14px"}}>
									Former Head of Institute of Gastroenterology & Liver Diseases, Hadassah Medical Center <br/>
									<br/><br/><br/>	<img className="academi-logo" src={hadassah} alt="" />
								</span>
							</div>
						</div>

						<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
							<div className="style">
								<img className="team" src={yael} alt=""/>
								<span className="font-size-18">
									<p className="team-title">Dr. Oestreicher-Kedem Yael</p>
									Otolaryngology , Head and Neck Surgery
								</span> <br/>
								<span style={{fontSize:"14px"}}>
									Senior Physician, Over 12 years of experience.
									Currently working as an Head and Neck surgeon at Assuta Medical Centers <br/>
									<br/><br/>	<img className="academi-logo" src={tau} alt="" />
								</span>
								
							</div>
						</div>

						<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
							<div className="style">
								<img className="team" src={steven} alt=""/>
								<span className="font-size-18">
									<p className="team-title">Prof. Steven Velkes</p>
									Orthopedics 
								</span> <br/>
								<span style={{fontSize:"14px"}}>
									Head of orthopaedic department, Beilinson-Rabin Medical Center Previously Director, Department of Orthopaedics and Arthroscopy & Knee Surgery, Barzilai Hospital, Ashkelon. <br/>
									<br/>	<img src={rabin} className="academi-logo" alt="" style={{position:"relative", top:"10px"}}/>
								</span>
							</div>
						</div>

						<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
							<div className="style">
								<img className="team" src={shai} alt=""/>
								<span className="font-size-18">
									<p className="team-title">Dr. Shai Shemesh</p>
									Orthopedics
								</span> <br/>
								<span style={{fontSize:"14px"}}>
									Senior Physician, Over 12 years of experience. 
									Currently working as an Orthopaedic Surgeon at Rabin Medical Center and as Clinical Fellow at Jackson and at Mount Sinai Health System <br/>
									<br/>
									<img src={rabin} alt="" className="academi-logo" style={{position:"relative", top:"5px"}}/>
								</span>
							</div>
						</div>

					</div>

					<div className="row margin-bottom-20" style={{justifyContent: "center"}}>
					
						<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
							<div className="style">
								<img className="team" src={yehuda} alt=""/>
								<span className="font-size-18">
									<p className="team-title">Prof. Yehuda Skornick</p>
									General Surgery
								</span> <br/>
								<span style={{fontSize:"14px"}}>
									Senior Surgeon and one of the leading surgeons in Israel. General Surgery and Oncology Specialist <br/>
									<br/><br/>	<br/><br/><img src={sourasky} className="academi-logo" alt="" style={{position:"relative", bottom:"5px", top:"0px"}}/>
								</span>
							</div>
						</div>

						<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
							<div className="style">
								<img className="team" src={alexander} alt=""/>
								<span className="font-size-18">
									<p className="team-title">Dr. Alexander Fardman</p>
									Cardiac Care Expert
								</span> <br/>
								<span style={{fontSize:"14px"}}>
									Acute Cardiac Care , Risk Factors and Prevention, Acute Cardiac Care and Acute Coronary Syndromesa at Chaim Sheba Medical Center, Tel Hashomer
									<br/> <br/><br/> 
									<img className="academi-logo" src={tau} alt="" />
								</span>
								
							</div>
						</div>

						<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
							<div className="style" style={{width:"100%"}}>
								<img className="team" src={yair} alt=""/>
								<span className="font-size-18">
									<p className="team-title">Prof. Yair Herishano</p>
									Hematology Expert
								</span> <br/>
								<span style={{fontSize:"14px"}}>
									Head of CLL department, senior lecturer for Hematology in Tel Aviv university and over 17 years of experience in the medical field as doctor and director<br /><br /> <br />
									<img className="academi-logo" src={benGurion} alt="" />
								</span>
							</div>
						</div>

						<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
							<div className="style" style={{width:"100%"}}>
								<img className="team" src={ofer} alt=""/>
								<span className="font-size-18">
									<p className="team-title">Ofer Ben-Yehuda</p>
									Investor
								</span> <br/>
								<span style={{fontSize:"14px"}}>
									A Partner at Shibolet & Co's. <br /> High-Tech practice group. <br />
									Over 15 years of experience in mergers & acquisitions, centure capital and start-ups.
								</span>
							</div>
						</div>
				
					</div>

					<div className="row margin-bottom-20" style={{justifyContent: "center"}}>

						<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
							<div className="style" style={{width:"100%"}}>
								<img className="team" src={ohad} alt=""/>
								<span className="font-size-18">
									<p className="team-title">Ohad Madanes</p>
									Investor
								</span> <br/>
								<span style={{fontSize:"14px"}}>
									CEO of the Madanes Group,
									the largest privately owned insurance broker in Israel and holds a BA in Business Managment from IDC Herzliya. <br /><br /> <br /><br />
									<img className="academi-logo" src={idc} alt="" />
								</span>
							</div>
						</div>

						<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
							<div className="style" style={{width:"100%"}}>
								<img className="team" src={kobi} alt=""/>
								<span className="font-size-18">
									<p className="team-title">Kobi Bendelak</p>
									Investor
								</span> <br/>
								<span style={{fontSize:"14px"}}>
									CEO of InsurTech Israel that was established by him to promote and lead the Israeli Insurtech ecosystem.
									Has 22 years' of experience in the insurance industry.
									<br /> <br /> <br /><br />
									<img className="academi-logo" src={barilan} alt="" />
								</span>
							</div>
						</div>


						<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
							<div className="style" style={{width:"100%"}}>
								<img className="team" src={boaz} alt=""/>
								<span className="font-size-18">
									<p className="team-title">Boaz Toshav</p>
									Investor
								</span> <br/>
								<span style={{fontSize:"14px"}}>
									Founder and CEO, Umbrella Advisors; Co-founder, Madanes Insure-Tech Inn. Lab; Board Member, public and private entities.<br />
									More than 17 years of professional experience as an investment banker. <br /><br /><br />
									<img className="academi-logo" src={tau} alt="" />
								</span>
							</div>
						</div>

						<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
							<div className="style">
								<img className="team" src={nir} alt=""/>
								<span className="font-size-18">
									<p className="team-title">Nir Tarlovsky</p>
									Board Director
								</span> <br/>
								<span style={{fontSize:"14px"}}>
									An entrepreneur and angel investor for the last 25 years.
									Skilled in E-commerce, Entrepreneurship, Venture Capital, Strategic Partnerships, and Mobile Applications.
									<br/> <br/><br/><br/><br/><br/>
									<img className="academi-logo" src={tau} alt="" />
								</span>
							</div>
						</div>

					</div>

					<div className="row margin-bottom-20" style={{justifyContent: "center"}}>
					</div>

					
					<div className="row margin-bottom-20">

						<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
							<div className="style">
								<img className="team" src={keren} alt=""/>
								<span className="font-size-18">
									<p className="team-title">Keren Kopilov</p>
									Board Director
								</span> <br/>
								<span style={{fontSize:"14px"}}>
									15 years experience in financial management, holds 4 board and advisor roles.
									Chairperson at JDRF, she has a BA in Economic and Business and MBA in accounting and financial management
									<br/> <br/><br/><br/><br /><br />
									<img className="academi-logo mr-2" src={tau} alt="" />
									<img className="stanford mr-2" style={{width:"70px !important"}} src={ono} alt="" />
									<img className="academi-logo" src={huji} alt="" />
								</span>
							</div>
						</div>
							
						<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
							<div className="style">
								<img className="team" src={jonathan} alt=""/>
								<span className="font-size-18">
									<p className="team-title">Jonathan Benartzi </p>
									Investor
								</span> <br/>
								<span style={{fontSize:"14px"}}>
									25 years experience in Start-ups, Strategic Partnerships, Business Strategy , Business Development in variety of top-level positions.
									<br/> <br/><br/><br/> <br /> <br /><br />	<br />
									<img className="academi-logo mr-2" src={idc} alt="" />
								</span>
							</div>
						</div>

						<div className="col-md-3 col-sm-6 col-xs-12 items-center team-container margin-icons">
							<div className="style">
								<img className="team" src={james} alt=""/>
								<span className="font-size-18">
									<p className="team-title">Dr. James L. Voiland </p>
									Strategic Advisor
								</span> <br/>
								<span style={{fontSize:"14px"}}>
									Over 10 years experience as healthcare executive, COO and VP.
									During this time he managed the most efficient medical management department, rebuilt health plans, served as key advisor and business partner to the President/CEO and board of directors. <br />
									As well as, served in many more executive positions in organizations.
									<br/> <br/>
									<img className="academi-logo mr-2" src={elpaso} alt="" />
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
 
export default Team;