import video from "../images/header.mp4"
import logo from "../images/logoBanner.png"
import patented from "../images/patented.png"


const Video = () => {

	return ( 
		<div className="col col-12" id="video-picker">
			<video playsInline autoPlay muted loop src={video} type="video/mp4" id="video"/>
			<div className="centered main-title" style={{textAlign:"center"}}>
				<img src={patented} alt="" id="patented"/>
				<img id="logo-banner" src={logo} alt="" width="800px" />
			</div>
		</div>	
	);
}
 
export default Video;