import header from '../images/work.jpg'
import React, { Component } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	NavLink
  } from 'react-router-dom';
import { Helmet } from 'react-helmet'


const FullStack = () => {

	return ( 
		<React.Fragment>
			<Helmet>	
				<title>EVP US Market | Serenus.AI&reg;</title>
			</Helmet>
			<div className="col col-12" id="image-picker">
				<img src={header} alt="" width="100%" height="400px" />
				<div style={{position:"absolute", width:"100%", height:"100%", background:"#000000", opacity:"0.4", top:0}}></div>
				<div className="centered main-title" style={{textAlign:"center", width:"100%"}}>
					<h1 class="header-for-pages">EVP US Market</h1>
					<div  className="mr-3"><span className="mr-3"><i class="fas fa-map-marker-alt mr-1"></i> Tel Aviv, Israel</span> <span><i class="fas fa-business-time mr-1"></i>Full-Time</span></div>
				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-md-8">
						<p>We are an exciting life-saving start-up developing an innovative AI-based system for the medical field. You will join a high-profile team to work on groundbreaking problems in health outcomes. The predictive analytics team works hand-in-hand with top medical professionals, leveraging a vast variety of anonymous patient-level information and top medical professionals’ labels.  </p>
						<p>We're looking for a talented and experienced U.S. based EVP US Market Executive/Consultant to join our team; You will be responsible for building and maintaining relationships with large insurance companies and lead fundraising rounds beside top U.S investors.</p>
						<p>The focus of the role will be initiating business partnerships and developing strong relations with leading investors and insurances in the US. The ideal candidate  has excellent people and networking skills, knowledge in the health insurance sphere and executive background.</p>
						<h5 style={{fontWeight:"bold"}}>Responsibilities:</h5>
						<ul className="ul-big">
							<li>Responsible for the initiation of strategic long term relationships with large insurance companies, HMOs and hospitals.</li>
							<li>Developing business relations to their full potential to ensure consistent recurring revenues.</li>
							<li>A strong individual who will contribute to our next fundraising round and has connections with leading investors in the U.S.</li>
							<li>Will lead commercial collaborations, negotiations and signing contracts.</li>
							<li>Identify, develop and maintain new business partnerships and opportunities.</li>
							<li>Conduct research to identify new markets and potential integrations.</li>
							<li>Direct, hands-on engagement throughout all business development life cycle milestones including pitching, negotiations, contract drafting, stakeholder approvals ensuring partners are active and deliver consistent growth.</li>
							<li>Build a funnel of both coalition & business opportunities executing those that align to company strategic objectives.</li>
							<li>Focus on the company’s growth goals, objectives, metrics, milestones and initiatives.</li>
						</ul>
						<br/>
						<h5 style={{fontWeight:"bold"}}>Requirements:</h5>
						<ul className="ul-big">
							<li>US Resident.</li>
							<li>Experienced and talented business executive.</li>
							<li>Highly motivated, proactive with excellent interpersonal skills.</li>
							<li>Bachelor’s degree in Business Administration, Marketing, Finance or a related field; Master’s degree preferred.</li>
							<li>Fluent in written and spoken English.</li>
							<li>Effective communication and negotiation skills.</li>
							<li>Excellent analytical, problem-solving and decision-making skills.</li>
							<li>Exceptional leadership and management skills.</li>
							<li>Experience networking with insurance companies.</li>
							<li>Demonstrable experience of successfully connecting with decision-makers in target companies.</li>
							<li>A strong individual who will contribute to our next fundraising round and has connections with leading investors in the U.S.</li>
							<li>Will lead commercial collaborations, negotiations and signing contracts.</li>
							<li>You have relationships across various healthcare, federal, and insurance agencies.</li>
						</ul>
						<br/>

						<p style={{fontWeight:"bold"}}>The position will be located within the central area of Israel.</p>
						<p style={{fontWeight:"bold"}}>Position Capacity: Full-Time</p>
						<p>**We are committed to creating a diverse environment and proud to be an equal opportunity employer.</p>
					</div>
					<div className="col-md-4">
						<div className="row">
							<Link className="btn btn-position" to="evp-us/apply" style={{width:"100%"}}>
								<i class="fas fa-pencil-alt mr-2"></i>
							Apply To Position</Link>
						</div>					
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
 
export default FullStack;