import Background from '../images/background4.jpg'
import React, { Component } from "react";
import { Helmet } from 'react-helmet'


const Providers = () => {
	return ( 
		<React.Fragment>
			<Helmet>	
				<title>Serenus.AI for Providers and Physicians | Serenus.AI&reg;</title>
			</Helmet>
			<div className="col col-12" id="image-picker">
				<img src={Background} alt="" height="500px" width="100%" />
				<div style={{position:"absolute", width:"100%", height:"100%", background:"#000000", opacity:"0.4", top:0}}></div>
				<div className="centered main-title" style={{textAlign:"center", width:"100%"}}>
					<h1 class="header-for-pages">Serenus.AI for Providers and Physicians</h1>
				</div>
			</div>
			
			<div className="wrapper">
				<div className="container">
					<div className="col col-12 margin-bottom-40 margin-top-40">
						<p style={{textAlign:"left"}}>
							Serenus.AI PPW™ is the first and only Generative AI system for patients' referrals before medical procedures and treatments, while prioritizing long patients' waiting lists. <br/>
							The system provides professionals with the best evidence-based clinical pathways in real-time, and increases the level of care, improves efficiencies and saves valuable resources.
						</p>
						<br/>
						<h2 className="center">Main Benefits include:</h2>
						<br/>
						<div className="handle">
							<div className="row">
								<div className="col-md-6 margin-bottom-20 why-serenus borderHover">
									<div className="row">
										<div className="col-2">
											<img className="img-height-60 margin-top-5 mr-3" src="https://img.icons8.com/carbon-copy/100/000000/customer-support.png"/>
										</div>
										<div className="col-10">
											<span className="color position"> Decision support system for professionals </span>
										</div>
									</div>
								</div>
						
								<div className="col-md-6 margin-bottom-20 why-serenus borderHover">
									<div className="row">
										<div className="col-2">
											<img className="img-height-60 margin-top-5  mr-3"  src="https://img.icons8.com/carbon-copy/100/000000/best-seller.png"/>
										</div>
										<div className="col-10">
											<span className="color"> Implements and promotes the best medical practice at the point of care </span>
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-md-6 margin-bottom-20 why-serenus borderHover">
									<div className="row">
										<div className="col-2">
											<img className="img-height-60 margin-top-5 mr-3"  src="https://img.icons8.com/carbon-copy/100/000000/flow-chart.png"/>
										</div>
										<div className="col-10">
											<span className="color position"> Improves patient history and data flow </span>
										</div>
									</div>
								</div>

								<div className="col-md-6 margin-bottom-20 why-serenus borderHover">
									<div className="row">
										<div className="col-2">
											<img className="img-height-60 margin-top-5  mr-3" src="https://img.icons8.com/carbon-copy/100/000000/negative-dynamic.png"/>
										</div>
										<div className="col-10">
											<span className="color position"> Prioritizing patients' waiting lists </span>
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-md-6 margin-bottom-20 why-serenus borderHover">
									<div className="row">
										<div className="col-2">
											<img className="img-height-50 margin-top-5  mr-4" src="https://img.icons8.com/carbon-copy/100/000000/total-sales.png"/>
										</div>
										<div className="col-10">
											<span className="color position"> Improves patients' outcomes </span>
										</div>
									</div>
								</div>
								<div className="col-md-6 margin-bottom-20 why-serenus borderHover">
									<div className="row">
										<div className="col-2">
											<img className="img-height-50 margin-top-5  mr-4"  src="https://img.icons8.com/dotty/80/000000/cost.png"/>
										</div>
										<div className="col-10">
											<span className="color position"> Reduces unplanned readmission rates </span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<br/>
		</React.Fragment>
	);
}
 
export default Providers;