import React, { Component } from "react";
import video from "../images/header.mp4";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import { baseApiUrl } from "./constants";
import jwt_decode from "jwt-decode";
import kneeVideo from "../images/demo/knee_silent.mp4";
import kneeVideo2 from "../images/demo/knee2_silent.mp4";
import eartubesVideo from "../images/demo/eartubes_silent.mp4";
import cllVideo from "../images/demo/cll_silent.mp4";
import tonsilsVideo from "../images/demo/tonsils_silent.mp4";
import tonsilsNegativeVideo from "../images/demo/tonsils_negative.mp4";
import hipVideo from "../images/demo/hip_silent.mp4";
import priorityDashboardVideo from "../images/demo/prioritydashboard.mp4";
import AIToProcedureVideo from "../images/demo/aitoprocedure.mp4";
import labelingVideo from "../images/demo/labeling.mp4";
import adminVideo from "../images/demo/admin.mp4";
import b2cVideo from "../images/demo/b2c.mp4";
import b2cChatVideo from "../images/demo/b2c_chat.mp4";
import childDevelopmentVideo from "../images/demo/child_development.mp4";

const axios = require('axios');

const reports = {
    '263': { // CLL
        'positive': 143669,
        'negative': 143691
    },
    '170': { // tonsillectmoy
        'positive': 145430,
        'negative': 145413
    },
    '220': { // knee replacement
        'positive': 145431,
        'negative': 145432
    },
    '225': { // hip replacement
        'positive': 145435,
        'negative': 145436
    },
    '224': { // ear tubes
        'positive': 145433,
        'negative': 145434
    }
}


class SimulatorDemo extends Component {

    state = {
        isLoading: true,
        isLoggedIn: localStorage.getItem('user_id') ? true : false,
        password: localStorage.getItem('SDPassword') || '',
        masterPassword: localStorage.getItem('IPPassword'),
        token: null
    };

    componentDidMount = async () => {
        if (this.state.masterPassword && await this.checkMasterPassword()) {
            this.setState({ isLoggedIn: true });
        }

        if (this.state.isLoggedIn) {
            await this.loginToSimulator();
            this.setState({ isLoading: false });
        } else if (!this.state.isLoggedIn && this.state.password != '') {
            await this.handleLogin();
        } else {
            this.setState({ isLoading: false });
        }

        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
              event.preventDefault();
              this.handleLogin();
            }
          };
          document.addEventListener("keydown", listener);
          return () => {
            document.removeEventListener("keydown", listener);
          };
    }

    handleChange = (event) => {
        this.setState({password: event.target.value});
    }

    handleLogin = async (event) => {
        const isCorrectPassword = await this.checkPassword();
        if (isCorrectPassword) {
            localStorage.setItem('SDPassword', this.state.password);
            await this.loginToSimulator();
            if (this.state.token) {
                this.setState({ isLoading: false, isLoggedIn: true });
            } else {
                toast.error('Error connecting to demo user', {
                    position: "bottom-right"
                });
            }
        }
    }

    checkPassword = async () => {
        const url = baseApiUrl() + 'serenusai/password-check';
        try {
            let res = await axios({
				method: 'post',
				url: url,
				data: {
					page: 'demo',
					password: this.state.password
				}
			});
            console.log(res);
            if (res.data.status == "ok") {
                return true;
            } else if (res.data.status == "error") {
                toast.error('Wrong password', {
                    position: "bottom-right"
                });
                return false;
            }      
        } catch (error) {
            toast.error('Error', {
                position: "bottom-right"
            });
            return false;
        }
    }

    checkMasterPassword = async () => {
        const url = baseApiUrl() + 'serenusai/password-check';
        try {
            let res = await axios({
				method: 'post',
				url: url,
				data: {
					page: 'information-portal',
					password: this.state.masterPassword
				}
			});
            if (res.data.status == "ok") {
                return true;
            } else if (res.data.status == "error") {
                localStorage.removeItem('IPPassword');
                return false;
            }      
        } catch (error) {
            return false;
        }
    }

    loginToSimulator = async () => {
        try {
			const url = baseApiUrl() + 'login';

			let res = await axios({
				method: 'post',
				url: url,
				data: {
					email: "demo@cll.serenus",
					password: "Aa123456!",
				}
			});
			if (!res.data.token) {
				toast('Error login', 'error');
				return;
			}
			this.setState({ token: res.data.token });
		} catch (error) {
			console.log(error);
            toast('Error login', 'error');
            return;
		}
    }

    isSessionExpired = () => {
        if (this.state.token) {
			let exp = jwt_decode(this.state.token).exp.toString();
			let now = Date.now()
				.toString()
				.slice(0, 10);
			if (now > exp) {
                console.log("token expired");
				return true;
			}
            console.log("token okay");
            return false;
		}
        console.log("no token");
        return true;
    }

    goToSimulator = async (proc) => {
        if (this.isSessionExpired()) {
            await this.loginToSimulator();
        }
        window.open('https://dev.serenusai.com/run-test/' + proc + '?token=' + this.state.token, '_blank');
    }

    goToReport = async (id) => {
        if (this.isSessionExpired()) {
            await this.loginToSimulator();
        }
        window.open('https://dev.serenusai.com/run-reports/' + id + '?token=' + this.state.token, '_blank');
    }

    render() {
        const isLoading = this.state.isLoading;
        const isLoggedIn = this.state.isLoggedIn;
        return (
            <React.Fragment>
                <ToastContainer />
                <Helmet>	
                    <title>Demo | Serenus.AI&reg;</title>
                </Helmet>
                <div className="row">
                    <div className="col col-12 video-ES" id="video-picker" style={{overflow: 'hidden'}}>
                        <video playsInline autoPlay muted loop src={video} type="video/mp4" id="ESvideo"/>
                        <div className="centered main-title main-title-ES">
                            <h1 className="header-for-pages header-for-pages-ES">Serenus.AI Live Demo</h1>
                            {/* <h4 className="sub-title">Providing the right treatment, to the right patient, at the right time</h4> */}
                        </div>
                    </div>
                </div>	

                {!isLoading && (
                    !isLoggedIn
                        ? (
                            <div className="col-md-4 offset-md-4 col-sm-6 offset-sm-3 col-12 text-center mt-5">
                                <input type="text" name="password" value={this.state.password} onChange={this.handleChange} placeholder="Please enter the password" />
                                <br />
                                <button className="read-more-press" onClick={this.handleLogin}>Log In</button>
                            </div>
                        )
                        : (
                            <div className="row main-page-ES">
                                <div className="col col-12" id="accordion">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="demo center" style={{margin: '10px', paddingTop: '50px'}}>
                                                <h4 data-toggle="collapse" data-target="#demo1">Chronic lymphocytic leukemia/small lymphocytic lymphoma</h4>
                                                <div id="demo1" class="collapse" data-parent="#accordion">
                                                    <br/><br/>
                                                    {/* <button className="btn btn-primary mb-2" onClick={() => this.goToSimulator(263)} style={{width: '40%'}}>Try the simulator</button>
                                                    <button className="btn btn-success mb-2 mx-1" style={{width: '20%'}}>Positive report</button>
                                                    <button className="btn btn-danger mb-2" style={{width: '20%'}}>Negative report</button> */}
                                                    <div className="btn-group mb-2" style={{width: '80%'}}>
                                                        <button className="btn btn-primary" onClick={() => this.goToSimulator(263)} style={{width: '50%'}}>Try the simulator</button>
                                                        <button className="btn btn-success" onClick={() => this.goToReport(reports['263']['positive'])}>Positive result</button>
                                                        <button className="btn btn-danger" onClick={() => this.goToReport(reports['263']['negative'])}>Negative result</button>
                                                    </div>
                                                    <div className="center mb-2" style={{width: '80%'}}>
                                                        <button className="btn btn-light btn-block" data-toggle="collapse" data-target="#cllVid">Watch video</button>
                                                    </div>
                                                    <div id="cllVid" class="collapse" data-parent="#demo1">
                                                        <video controls src={cllVideo} type="video/mp4" style={{width: '80%'}} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="demo center" style={{margin: '10px', paddingTop: '50px'}}>
                                                <h4 data-toggle="collapse" data-target="#demo2">Tonsillectomy</h4>
                                                <div id="demo2" class="collapse" data-parent="#accordion">
                                                    <br/><br/>
                                                    {/* <button className="btn btn-primary mb-2" onClick={() => this.goToSimulator(170)} style={{width: '80%'}}>Try the simulator</button> */}
                                                    <div className="btn-group mb-2" style={{width: '80%'}}>
                                                        <button className="btn btn-primary" onClick={() => this.goToSimulator(170)} style={{width: '50%'}}>Try the simulator</button>
                                                        <button className="btn btn-success" onClick={() => this.goToReport(reports['170']['positive'])}>Positive result</button>
                                                        <button className="btn btn-danger" onClick={() => this.goToReport(reports['170']['negative'])}>Negative result</button>
                                                    </div>
                                                    <div className="btn-group center mb-2" style={{width: '80%'}}>
                                                        <button className="btn btn-light" data-toggle="collapse" data-target="#tonsilsVid" style={{color: 'darkgreen'}}>Watch video with positive result</button>
                                                        <button className="btn btn-light" data-toggle="collapse" data-target="#tonsilsNegativeVid" style={{color: 'darkred'}}>Watch video with negative result</button>
                                                    </div>
                                                    <div id="tonsilsVid" class="collapse" data-parent="#demo2">
                                                        <video controls src={tonsilsVideo} type="video/mp4" style={{width: '80%'}} />
                                                    </div>
                                                    <div id="tonsilsNegativeVid" class="collapse" data-parent="#demo2">
                                                        <video controls src={tonsilsNegativeVideo} type="video/mp4" style={{width: '80%'}} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="demo center" style={{margin: '10px', paddingTop: '50px'}}>
                                                <h4 data-toggle="collapse" data-target="#demo3">Total Knee Replacement</h4>
                                                <div id="demo3" class="collapse" data-parent="#accordion">
                                                    <br/><br/>
                                                    {/* <button className="btn btn-primary mb-2" onClick={() => this.goToSimulator(220)} style={{width: '80%'}}>Try the simulator</button> */}
                                                    <div className="btn-group mb-2" style={{width: '80%'}}>
                                                        <button className="btn btn-primary" onClick={() => this.goToSimulator(220)} style={{width: '50%'}}>Try the simulator</button>
                                                        <button className="btn btn-success" onClick={() => this.goToReport(reports['220']['positive'])}>Positive result</button>
                                                        <button className="btn btn-danger" onClick={() => this.goToReport(reports['220']['negative'])}>Negative result</button>
                                                    </div>
                                                    <div className="btn-group mb-2" style={{width: '80%'}}>
                                                        <button className="btn btn-light" data-toggle="collapse" data-target="#kneeVid1" style={{color: 'darkgreen'}}>Watch video with positive result</button>
                                                        <button className="btn btn-light" data-toggle="collapse" data-target="#kneeVid2" style={{color: 'darkred'}}>Watch video with negative result</button>
                                                    </div>
                                                    <div id="kneeVid1" class="collapse" data-parent="#demo3">
                                                        <video controls src={kneeVideo} type="video/mp4" style={{width: '80%'}} />
                                                    </div>
                                                    <div id="kneeVid2" class="collapse" data-parent="#demo3">
                                                        <video controls src={kneeVideo2} type="video/mp4" style={{width: '80%'}} />
                                                    </div>    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="demo center" style={{margin: '10px', paddingTop: '50px'}}>
                                                <h4 data-toggle="collapse" data-target="#demo4">Total Hip Replacement</h4>
                                                <div id="demo4" class="collapse" data-parent="#accordion">
                                                    <br/><br/>
                                                    {/* <button className="btn btn-primary mb-2" onClick={() => this.goToSimulator(225)} style={{width: '80%'}}>Try the simulator</button> */}
                                                    <div className="btn-group mb-2" style={{width: '80%'}}>
                                                        <button className="btn btn-primary" onClick={() => this.goToSimulator(225)} style={{width: '50%'}}>Try the simulator</button>
                                                        <button className="btn btn-success" onClick={() => this.goToReport(reports['225']['positive'])}>Positive result</button>
                                                        <button className="btn btn-danger" onClick={() => this.goToReport(reports['225']['negative'])}>Negative result</button>
                                                    </div>
                                                    <div className="center mb-2" style={{width: '80%'}}>
                                                        <button className="btn btn-light btn-block" data-toggle="collapse" data-target="#hipVid">Watch video</button>
                                                    </div>
                                                    <div id="hipVid" class="collapse" data-parent="#demo4">
                                                        <video controls src={hipVideo} type="video/mp4" style={{width: '80%'}} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="demo center" style={{margin: '10px', paddingTop: '50px'}}>
                                                <h4 data-toggle="collapse" data-target="#demo5">Ear Tubes Surgery</h4>
                                                <div id="demo5" class="collapse" data-parent="#accordion">
                                                    <br/><br/>
                                                    {/* <button className="btn btn-primary mb-2" onClick={() => this.goToSimulator(224)} style={{width: '80%'}}>Try the simulator</button> */}
                                                    <div className="btn-group mb-2" style={{width: '80%'}}>
                                                        <button className="btn btn-primary" onClick={() => this.goToSimulator(224)} style={{width: '50%'}}>Try the simulator</button>
                                                        <button className="btn btn-success" onClick={() => this.goToReport(reports['224']['positive'])}>Positive result</button>
                                                        <button className="btn btn-danger" onClick={() => this.goToReport(reports['224']['negative'])}>Negative result</button>
                                                    </div>
                                                    <div className="center mb-2" style={{width: '80%'}}>
                                                        <button className="btn btn-light btn-block" data-toggle="collapse" data-target="#eartubesVid">Watch video</button>
                                                    </div>
                                                    <div id="eartubesVid" class="collapse" data-parent="#demo5">
                                                        <video controls src={eartubesVideo} type="video/mp4" style={{width: '80%'}} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="demo center" style={{margin: '10px', paddingTop: '50px'}}>
                                                <h4 data-toggle="collapse" data-target="#demo6">DB waiting lists</h4>
                                                <div id="demo6" class="collapse" data-parent="#accordion">
                                                    <br/><br/>
                                                    <div className="center mb-2" style={{width: '80%'}}>
                                                        <button className="btn btn-light btn-block" data-toggle="collapse" data-target="#priorityDashboardVid">Watch video</button>
                                                        <a href="https://dashboard.serenusai.com/" target="_blank" className="btn btn-light btn-block">Go To Dashboard</a>
                                                    </div>
                                                    <div id="priorityDashboardVid" class="collapse" data-parent="#demo6">
                                                        <video controls src={priorityDashboardVideo} type="video/mp4" style={{width: '80%'}} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="demo center" style={{margin: '10px', paddingTop: '50px'}}>
                                                <h4 data-toggle="collapse" data-target="#demo7">Procedure from Guidelines</h4>
                                                <div id="demo7" class="collapse" data-parent="#accordion">
                                                    <br/><br/>
                                                    <div className="center mb-2" style={{width: '80%'}}>
                                                        <button className="btn btn-light btn-block" data-toggle="collapse" data-target="#aiToProcedureVid">Watch video</button>
                                                    </div>
                                                    <div id="aiToProcedureVid" class="collapse" data-parent="#demo7">
                                                        <video controls src={AIToProcedureVideo} type="video/mp4" style={{width: '80%'}} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="demo center" style={{margin: '10px', paddingTop: '50px'}}>
                                                <h4 data-toggle="collapse" data-target="#demo8">Labeling Process</h4>
                                                <div id="demo8" class="collapse" data-parent="#accordion">
                                                    <br/><br/>
                                                    <div className="center mb-2" style={{width: '80%'}}>
                                                        <button className="btn btn-light btn-block" data-toggle="collapse" data-target="#labelingVid">Watch video</button>
                                                    </div>
                                                    <div id="labelingVid" class="collapse" data-parent="#demo8">
                                                        <video controls src={labelingVideo} type="video/mp4" style={{width: '80%'}} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="demo center" style={{margin: '10px', paddingTop: '50px'}}>
                                                <h4 data-toggle="collapse" data-target="#demo9">Admin General</h4>
                                                <div id="demo9" class="collapse" data-parent="#accordion">
                                                    <br/><br/>
                                                    <div className="center mb-2" style={{width: '80%'}}>
                                                        <button className="btn btn-light btn-block" data-toggle="collapse" data-target="#adminVid">Watch video</button>
                                                    </div>
                                                    <div id="adminVid" class="collapse" data-parent="#demo9">
                                                        <video controls src={adminVideo} type="video/mp4" style={{width: '80%'}} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="demo center" style={{margin: '10px', paddingTop: '50px'}}>
                                                <h4 data-toggle="collapse" data-target="#demo10">My Medical Owl</h4>
                                                <div id="demo10" class="collapse" data-parent="#accordion">
                                                    <br/><br/>
                                                    <div className="btn-group mb-2" style={{width: '80%'}}>
                                                        <button className="btn btn-light" data-toggle="collapse" data-target="#b2cVid">Watch video</button>
                                                        <button className="btn btn-info" data-toggle="collapse" data-target="#b2cChatVid">Watch video with chat</button>
                                                    </div>
                                                    <div id="b2cVid" class="collapse" data-parent="#demo10">
                                                        <video controls src={b2cVideo} type="video/mp4" style={{width: '80%'}} />
                                                    </div>
                                                    <div id="b2cChatVid" class="collapse" data-parent="#demo10">
                                                        <video controls src={b2cChatVideo} type="video/mp4" style={{width: '80%'}} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="demo center" style={{margin: '10px', paddingTop: '50px'}}>
                                                <h4 data-toggle="collapse" data-target="#demo11">Child Development / Merge Simulators</h4>
                                                <div id="demo11" class="collapse" data-parent="#accordion">
                                                    <br/><br/>
                                                    <div className="btn-group mb-2" style={{width: '80%'}}>
                                                        <button className="btn btn-light" data-toggle="collapse" data-target="#mergeVid">Watch video</button>
                                                    </div>
                                                    <div id="mergeVid" class="collapse" data-parent="#demo10">
                                                        <video controls src={childDevelopmentVideo} type="video/mp4" style={{width: '80%'}} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-12">
                                            <div className="demo center" style={{margin: '10px', paddingTop: '50px'}}>
                                                <h4 data-toggle="collapse" data-target="#demo10">My Medical Owl</h4>
                                                <div id="demo10" class="collapse" data-parent="#accordion">
                                                    <br/><br/>
                                                    <div className="center mb-2" style={{width: '80%'}}>
                                                        <button className="btn btn-light btn-block" data-toggle="collapse" data-target="#b2cVid">Watch video</button>
                                                    </div>
                                                    <div id="b2cVid" class="collapse" data-parent="#demo10" style={{width: '80%', margin: 'auto'}}>
                                                        <div style={{ position: 'relative', paddingBottom: '56.25%', height: '0', overflow: 'hidden' }}>
                                                            <iframe
                                                                width="80%"
                                                                height="100%"
                                                                src={`https://www.youtube.com/embed/gpLvV_3KOEI`}
                                                                title="YouTube Video"
                                                                frameBorder="0"
                                                                allowFullScreen
                                                                style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                                                            ></iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        )
                    )
                }
            </React.Fragment>
        )
    }
}

export default SimulatorDemo;
