import Background2 from '../images/background2.jpg';
import React, { Component } from "react";
import { Helmet } from 'react-helmet'
import Slider from "react-slick";
// import yaffe from "../images/hillel_yaffe.jpg";
import yaffe from "../images/hospitals/hillel_yaffe.jpeg";
import nhs from "../images/nsh.png";
// import leeds from "../images/leeds.jpg";
import leeds from "../images/education/leeds.png";


const Partners = () => {

	var settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay:true,
		autoplaySpeed:3000
	 };

	return ( 
		<React.Fragment>
			<Helmet>
				<title>Partners | Serenus.AI&reg;</title>
			</Helmet>
			<div className="col col-12" id="image-picker">
				<img src={Background2} alt="" height="500px" width="100%" />
				<div className="centered main-title" style={{textAlign:"center", width:"100%"}}>
					<h1 class="header-for-pages">Partners</h1>
				</div>
			</div>
			<div className="wrapper">
				<div className="container">
					<div className="col-12">
						<div className="row">
							<br/>
							<p>
								Serenus.AI’s partners are essential to us. <br/>
								Serenus.AI’s partners are comprised of the most leading and influential players in the insurance/healthcare ecosystem including insurers, hospitals, HMOs and large employers. <br/> 
								Serenus.AI is committed to delivering cutting-edge technologies and uncompromised service to meet the growing needs of our partners. <br/> 
								Our aim is to build strong, long-term relationships with our partners and offer win-win programs that will generate profits for all sides. <br/> 
							</p>
						</div>
					</div>
				</div>

				<div className="col-12 margin-bottom-50">
					<h4 style={{textAlign:"center", fontWeight:"bold"}}>Join Serenus.AI’s network of worldwide partners and enjoy Serenus.AI’s competitive advantage:</h4>
					<div className="" style={{padding:"0px"}}>
						<div className="col col-12">
							<div className="row items-center">
								<div className="col-md-4 col-xs-10" >
									<div className="what-serenus">
										<img className="img-height-70 margin-top-5" src="https://img.icons8.com/carbon-copy/100/000000/saving-book.png"/>
										<h5 className="margin-top-10">Knowledge</h5>
										<p className="margin-top-20">Combining high-level knowledge of top physicians, continuously simulating real-world situations.</p>
									</div>
								</div>
								<div className="col-md-4 col-sm-4 col-xs-10" >
									<div className="what-serenus">
										<img className="margin-top-5"  style={{height:"67px"}} src="https://img.icons8.com/carbon-copy/100/000000/goal.png"/>
										<h5 className="margin-top-10">No Direct Competitor </h5>
										<p className="margin-top-20">Serenus.AI is a unique solution, providing machine learning based medical decision-making system at the post-diagnostic stage.</p>
									</div>
								</div>
								<div className="col-md-4 col-sm-4 col-xs-10">
									<div className="what-serenus">
										<img  className="img-height-60 margin-top-10" src="https://img.icons8.com/carbon-copy/100/000000/learning.png"/>
										<h5 className="margin-top-10">Keeps Learning</h5>
										<p className="margin-top-20">Ongoing learning mechanism through real-time cases, experts' inputs and industry protocols make the system continuously smarter.</p>
									</div>
								</div>
							</div>

							<div className="row items-center">
								<div className="col-md-4 col-sm-4 col-xs-10" style={{marginLeft:"0px"}}>
									<div className="what-serenus">
										<img className="img-height-70 margin-top-5" src="https://img.icons8.com/dotty/80/000000/machine-learning.png"/>
										<h5 className="margin-top-10">Machine Learning</h5>
										<p className="margin-top-20">Unmatched technology in deep learning and algorithms providing advanced and objective decision-making.</p>
									</div>
								</div>

								<div className="col-md-4 col-sm-4 col-xs-10">
									<div className="what-serenus">
										<img className="img-height-70 margin-top-5" src="https://img.icons8.com/carbon-copy/100/000000/download-from-cloud.png"/>
										<h5 className="margin-top-10">Cloud Based</h5>
										<p className="margin-top-20">Accessible Any Time, Anywhere, without the need for EMR integration. Advanced privacy and security measures are in place (HIPPA etc.)</p>
									</div>
								</div>

								<div className="col-md-4 col-sm-4 col-xs-10">
									<div className="what-serenus">
										<img className="margin-top-10" style={{height:"63px"}} src="https://img.icons8.com/dotty/80/000000/business-group.png"/>	
										<h5 className="margin-top-10">Advisory Board</h5>
										<p className="margin-top-20">Comprised of today’s leading and well-known physicians, providing insights and credibility.</p>
										<br/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<br/>
				</div>

				<div className="wrapper">
					<h2 className="margin-bottom-20">What Our Partners Say</h2>
					<Slider {...settings} style={{textAlign:"center"}}>
						<div className="col-md-8">
							<div className="say" style={{border:"1px solid rgb(217, 217, 217)", padding:"30px", textAlign:"left"}}>
								<i class="fas fa-2x fa-quote-left" style={{color:"rgb(217, 217, 217)"}}></i> <br/>
								<p>Serenus.AI technology has the ability to improve patient outcomes, reduce variation in clinical practice and reduce the waiting times. It will also save the NHS
								significant money by improving efficiency and reducing the number of unindicated unnecessary treatments</p>
								<span style={{fontSize:"12px", fontWeight:"bold"}}>Prof. Hemant Pandit FRCS (Orth) DPhil (Oxon) <br/>
								NIHR Senior Investigator <br/>
								Professor of Orthopedics and Honorary Consultant <br/>
								Director for Research an Innovation, LIRMM
								</span> <br/><br/>
								<div className="row">
									<img src={nhs} alt=""  height="50" width="100" style={{marginRight:"30px"}} />
									<img src={leeds} alt=""  height="50" width="100" />
								</div>
							</div>
						</div>

						<div className="col-md-8">
							<div className="say" style={{border:"1px solid rgb(217, 217, 217)", padding:"30px", textAlign:"left"}}>
								<i class="fas fa-2x fa-quote-left" style={{color:"rgb(217, 217, 217)"}}></i> <br/>
								<p>I strongly recommend implementing the Serenus.AI system in medical institutions in order to improve the professional decision-making process prior to preforming any medical procedure</p>
								<span style={{fontSize:"12px", fontWeight:"bold"}}>Prof. Itzhak Braverman <br/>
								Head of ENT department <br/>
								Hill-Yaffe Hospital
								</span>
								<br/> <br/> 
								<img src={yaffe} height="50" width="50" alt=""/>
							</div>
						</div>
					</Slider>
				</div>
			</div>
			<br/> <br/>
		</React.Fragment>
	);
}
 
export default Partners;