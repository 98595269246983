import header from '../images/work.jpg'
import React, { Component } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	NavLink
  } from 'react-router-dom';
import { Helmet } from 'react-helmet'


const Digital = () => {

	return ( 
		<React.Fragment>
			<Helmet>	
				<title>Online Digital Marketing Specialist | Serenus.AI&reg;</title>
			</Helmet>
			<div className="col col-12" id="image-picker">
				<img src={header} alt="" width="100%" height="400px" />
				<div style={{position:"absolute", width:"100%", height:"100%", background:"#000000", opacity:"0.4", top:0}}></div>
				<div className="centered main-title" style={{textAlign:"center", width:"100%"}}>
					<h1 class="header-for-pages">Online Digital Marketing Specialist</h1>
					<div  className="mr-3"><span className="mr-3"><i class="fas fa-map-marker-alt mr-1"></i> Tel Aviv, Israel</span> <span><i class="fas fa-business-time mr-1"></i>Full-Time</span></div>
				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-md-8">
						<h5>Job description:</h5>
						<p>We are an exciting life-saving start-up developing an innovative AI-based system for the medical field. You will join a high-profile team to work on groundbreaking problems in health outcomes.</p>
						<p>We're looking for a talented and experienced Online Digital Marketing Manager to join our team; You will be responsible for the growth and outreach of the company, leading and executing strategic campaigns that will bring greater awareness to the company and its’ product. </p>
						<p>The focus of the role is to develop and manage new marketing campaigns from start to finish that will generate brand awareness and bring higher traffic numbers to the company’s website by implementing online marketing strategies. </p>
						<p>The ideal candidate will be a creative, analytical and independent worker with a good grasp of social media and ad optimization to promote the company and its products.</p>
						<br/>
						<h5 style={{fontWeight:"bold"}}>Responsibilities:</h5>
						<ul className="ul-big">
							<li>Create new marketing campaigns including web, email, SEO/SEM.</li>
							<li>Maintain social media presence across all platforms.</li>
							<li>Track and report marketing growth/performance.</li>
							<li>Lead integrated digital marketing campaigns from concept to execution.</li>
							<li>Develop short and long terms goals to reach target revenue.</li>
							<li>Focus on the company’s growth goals, objectives, metrics, milestones and initiatives.</li>
							<li>Identify trends and insights, and optimize spend and performance based on the insights.</li>
						</ul>

						<h5 style={{fontWeight:"bold"}}>Requirements:</h5>
						<ul className="ul-big">
							<li>Proven working experience in digital marketing.</li>
							<li>Bachelor’s degree in Business Administration, Marketing, Communications or a similar field.</li>
							<li>Fluent in written and spoken English.</li>
							<li>Independent, analytical and data driven worker.</li>
							<li>Extensive knowledge of ad working tools and optimization.</li>
							<li>Highly creative with experience in identifying target audiences and devising digital campaigns that engage, inform and motivate.</li>
							<li>Exceptional leadership and management skills</li>
							<li>Up-to-date with the latest trends and best practices in online marketing and measurement</li>
						</ul>
						<br/>

						<p style={{fontWeight:"bold"}}>The position will be located within the central area of Israel.</p>
						<p style={{fontWeight:"bold"}}>Position Capacity: Full-Time</p>
						<p>**We are committed to creating a diverse environment and proud to be an equal opportunity employer.</p>
					</div>
					<div className="col-md-4">
						<div className="row">
							<Link className="btn btn-position" to="digital/apply" style={{width:"100%"}}>
							<i class="fas fa-pencil-alt mr-2"></i>
							Apply To Position</Link>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
 
export default Digital;