import React, { Component, useState } from "react";
import header from '../images/about.png';
import { Helmet } from 'react-helmet'
import mission from '../images/vision1.jpg';
import chatbot from "../images/chatbot.png";
import serenus from "../images/serenus.png";
import report from "../images/report.png";
import patent1 from '../images/patent1.png';
import patent2 from '../images/patent2.png';
import patent3 from '../images/patent3.png';
import patent4 from '../images/patent4.png';
import patent5 from '../images/patent5.png';
import abracadabra from '../images/abracadabra.png';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	NavLink
  } from 'react-router-dom';
  import { useForm } from 'react-hook-form/dist/index.ie11'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { b2cUrl } from "./constants";

const axios = require('axios');


const Vision = () => {

	const [isSubmit, setIsSubmit] = useState(false);
	const { register, handleSubmit, errors } = useForm(); 

	async function onSubmit(data) {
		setIsSubmit(true);
		try {
			let firstNameErr='';
			let lastNameErr='';
			let emailErr='';
			let phoneErr='';
			let subjectErr='';
			let messageErr='';

			// const url = 'http://localhost/serenus-ai/b2c/serenus-contact';
			const url = b2cUrl() + 'serenus-contact';

			let res = await axios({
				method: 'post',
				url: url,
				data: {
					firstName: data.firstname,
					lastName: data.lastname,
					email: data.email,
					phone: data.phone,
					subject: data.subject,
					message: data.message
				},
				headers: {
					'Content-Type': 'application/json;charset=UTF-8'
					// "Access-Control-Allow-Origin": "*",
				//   'Accept': 'application/json'
				}
			});

			if (res.status == 200) {
				if (Array.isArray(res.data)) {
					for (let i = 0; i < res.data.length; i++) {
						if (res.data[i]) {
							if (i == 0) {
								firstNameErr = res.data[i];
							} else if (i == 1) {
								lastNameErr = res.data[i];
							} else if (i == 2) {
								emailErr = res.data[i];
							} else if (i == 3) {
								phoneErr = res.data[i];
							} else if (i == 4) {
								subjectErr = res.data[i];
							} else if (i == 5) {
								messageErr = res.data[i];
							}
						}
					}
					
					if (firstNameErr || lastNameErr || emailErr || phoneErr || subjectErr || messageErr) {
						if (firstNameErr) {
							document.getElementById("err-first-name").innerHTML = firstNameErr;
						}
						if (lastNameErr) {
							document.getElementById("err-last-name").innerHTML = lastNameErr;
						}
						if (emailErr) {
							document.getElementById("err-email").innerHTML = emailErr;
						}
						if (phoneErr) {
							document.getElementById("err-phone").innerHTML = phoneErr;
						}
						if (subjectErr) {
							document.getElementById("err-company").innerHTML = subjectErr;
						}
						if (messageErr) {
							document.getElementById("err-title").innerHTML = messageErr;
						}
					}
				} else {
					if (res.data.includes("Message has been sent")) {
						toast.success("Your message sent successfully!");
						let frm = document.getElementById('contact-form');
						frm.reset();
						setIsSubmit(false);
						return false;
					} else {
						// console.log('else 1');
						toast.error("The site is experiencing technical difficulties, please try again later");
					}
				}
			}
			setIsSubmit(false);
		} catch {
			// console.log('else 2');
			toast.error("The site is experiencing technical difficulties, please try again later");
			setIsSubmit(false);
		}
	}

	return ( 
		<React.Fragment>
			<ToastContainer />
			<Helmet>	
				<title>Mission | Serenus.AI&reg;</title>
			</Helmet>
			<div className="col col-12" id="image-picker">
				<img src={header} alt="" width="100%" height="400px" />
				<div style={{position:"absolute", width:"100%", height:"100%", background:"#000000", opacity:"0.4", top:0}}></div>
				<div className="centered main-title" style={{textAlign:"center", width:"100%"}}>
					<h1 className="header-for-pages">Our Mission</h1>
				</div>
			</div>

			<div className="col col-12 margin-bottom-20">
				<div className="row">
					<div className="col-md-7">
						<img src={mission} alt="" width="100%" height="450px"/>
					</div>
					<div className="col-md-5" style={{display:"table",height:"450px"}}>
						<div style={{verticalAlign:"middle", display:"table-cell"}}>
							<h2 style={{textAlign:"left"}} className="margin-bottom-20">Serenus.AI Mission : </h2>
							<span className="em-18">Improving patient outcomes by harnessing the power of AI/ML technologies - providing the right treatment, to the right patient, at right time. 
							</span>
							{/* 	<p style={{marginRight:"40px"}}>At Serenus.AI, we want to solve a biggest problem : unnecessary medical procedures. <br/> 
							Our first product is the first clinically objective, AI-based, automated case-by-case review system to improve the utilization and prior authorization processes before medical procedures. <br/> The solution reduces both medical and administrative costs, boosts satisfaction across the board, and advances care quality and effectiveness. 
							<br/>
							Serenus.AI  is committed to providing effective solutions that improve the medical decision making process of professionals and patients.
							</p> */}
							{/* <p>Serenus.AI mission is to improving patient outcomes by harnessing the power of AI/ML technologies - <br/>
							providing the right treatment, to the right patient, at the minimum time.
							</p> */}
						</div>
					</div>
				</div>
			</div>

			<div className="col col-12 margin-bottom-50">
				<h2 className="margin-bottom-20">Who was Serenus ?</h2>
				<div style={{width:"100%", textAlign:"center"}}>
					<div className="row">
						<div className="col-md-6">
							<img src={abracadabra} alt="" style={{marginBottom:"20px"}} className="full-width"/>
							<span> 	<br/>*Serenus Sammonicus advocated the use of abracadabra as a literary amulet against fever</span>
						</div>
						<div className="col-md-6">
							<p style={{textAlign:"left"}}>
								Serenus - Quintus Serenus Sammonicus (Born in 2nd century) was a Roman ancient physician and author of a didactic medical poem, Liber Medicinalis ("The Medical Book"; also known as De medicina praecepta saluberrima). <br/>
								His most quoted work out of at least five books was Res reconditae, of which only fragments only are preserved in quotations. <br/> The surviving work, De medicina praecepta, in 1115 hexameters, contains a number of popular remedies, borrowed from Pliny the Elder and Pedanius Dioscorides, and various magic formulae, among them the famous abracadabra, as a cure for fever and ague. <br/> It concludes with a description of the famous antidote of Mithridates VI of Pontus. <br/>
								It was much used in the 2nd century, and is of value to the ancient history of popular medicine. <br/> The syntax and metre are remarkably precise. <br/>
							</p>
						</div>
					</div>
				</div>
			</div>

	
			<h2 className="margin-bottom-20">Our Advantages</h2>
			<div className="margin-left-right" style={{justifyContent:"center"}}>
				<div className="col-12">
					<div className="row adv-container" style={{justifyContent:"center"}}>
						<div className="col-md-2 col-sm-6 col-xs-12 margin-right-35">
							<div className="icon-adv">
								<div style={{background:"#4c96c1", textAlign:"center"}}>
									<img src="https://img.icons8.com/carbon-copy/100/000000/brain.png"/>
								</div>
							</div>
							<div className="circle" style={{background:"rgba(76,150,193,0.3)"}}>
								<div style={{position:"relative", top:"-15px"}}>
									<p>AI-based objective personalized decision-making</p> 
								</div>
							</div>
						</div>
						<div className="col-md-2 col-sm-6 col-xs-12 margin-right-35">
							<div className="icon-adv">
								<div style={{background:"#8a5b85", textAlign:"center"}}>
									<img  src="https://img.icons8.com/dotty/80/000000/report-file.png"/>
								</div>
							</div>
							<div className="circle" style={{background:"rgba(144,101,138,0.3)"}}>
								<div style={{position:"relative", top:"-15px"}}>
									<p>Inclusive review of all relevant factors </p> 
								</div>
							</div>
						</div>
						<div className="col-md-2 col-sm-6 col-xs-12 margin-right-35">
							<div className="icon-adv">
								<div style={{background:"#37abac",textAlign:"center"}}>
									<img src="https://img.icons8.com/carbon-copy/100/000000/flow-chart.png"/>
								</div>
							</div>
							<div className="circle" style={{background:"rgba(78,178,179,0.3)"}}>
								<div style={{position:"relative", top:"-15px"}}>
									<p>Improved patient history and data flow </p>
								</div>
							</div>
						</div>
						<div className="col-md-2 col-sm-6 col-xs-12 margin-right-35">
							<div className="icon-adv">
								<div style={{background:"#668a5b",textAlign:"center"}}>
									<img src="https://img.icons8.com/dotty/80/000000/graph.png"/>
								</div>
							</div>
							<div className="circle" style={{background:"rgba(105,208,95,0.3)"}}>
								<div style={{position:"relative", top:"-15px"}}>
									<p>Advanced dashboard</p>
								</div>
							</div>
						</div>
						<div className="col-md-2 col-sm-6 col-xs-12 margin-right-35" >
							<div className="icon-adv">
								<div style={{background:"rgb(238, 181, 124)",textAlign:"center"}}>
									<img src="https://img.icons8.com/dotty/80/000000/financial-analytics.png"/>
								</div>
							</div>
							<div className="circle" style={{background:"rgba(238, 181, 124,0.3)"}}>
								<div style={{position:"relative", top:"-15px"}}>
									<p>Powerful AI-driven analytics</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="col col-12">
				<div className="row" style={{marginTop:"0px"}}>
					<div className="col-md-8">
						<form onSubmit={handleSubmit(onSubmit)} className="contact-us" id="contact-form" style={{marginBottom:"50px",border:"1px solid rgb(23, 40, 82)"}}>
							<div className="col-12">
								<h4 style={{color:"#172852"}}>Let's Talk:</h4>
								<div className="row">
									<div className="col-md-6 col-xs-12 contact-page">
										<label htmlFor="fname" style={{marginBottom:"0px"}}>First Name*</label>
										<input type="text" id="fname" name="firstname" ref={register({ required: true, maxLength:100})}  placeholder="First Name"/>
										<span className="err">
											{errors.firstname && errors.firstname.type === "required" && <span>First name is required</span>}
											{errors.firstname && errors.firstname.type === "maxLength" && <span>Max length exceeded</span> }
										</span>
									</div>
									<div className="col-md-6 col-xs-12 contact-page">
										<label htmlFor="lname" style={{marginBottom:"0px"}}>Last Name*</label>
										<input type="text" id="lname" name="lastname" ref={register({ required: true, maxLength:100 })} placeholder="Last Name"/>
										<span className="err">
											{errors.lastname && errors.lastname.type === "required" && <span>Last name is required</span>}
											{errors.lastname && errors.lastname.type === "maxLength" && <span>Max length exceeded</span> }		
										</span>	
									</div>
								</div>

								<div className="row">
									<div className="col-md-6 col-xs-12 contact-page " style={{justifyContent:"right"}}>
										<label htmlFor="email" style={{marginBottom:"0px"}}>Email*</label>
										<input type="email" id="email" name="email" ref={register({ required: true })} placeholder="Email"/>
										<span className="err">{errors.email && '*Email is required'} </span>
									</div>

									<div className="col-md-6 col-xs-12 contact-page" style={{justifyContent:"right"}}>
										<label htmlFor="phone" style={{marginBottom:"0px"}}>Phone*</label>
										<input type="phone" id="phone" name="phone" ref={register({ required: true, maxLength:14, minLength:9 })} placeholder="Phone"/>
										<span className="err">
											{errors.phone && errors.phone.type === "required" && <span>Phone is required</span>}
											{errors.phone && errors.phone.type === "maxLength" && <span>Max length exceeded</span> }	
											{errors.phone && errors.phone.type === "minLength" && <span>Min length exceeded</span> }			
										</span>	
									</div>
								</div>

								<div className="row">
									<div className="col-12 contact-page">
										<label htmlFor="subject" style={{marginBottom:"0px"}}>Subject*</label>
										<input type="text" id="subject" name="subject" ref={register({ required: true, maxLength:200 })} placeholder="Subject"></input>
										<span className="err">
											{errors.subject && errors.subject.type === "required" && <span>Subject is required</span>}
											{errors.phone && errors.phone.type === "maxLength" && <span>Max length exceeded</span> }	
										</span>
									</div>
								</div>

								<div className="row">
									<div className="col-12 contact-page">
										<label htmlFor="message" style={{marginBottom:"0px"}}>Message*</label>
										<textarea name="message" id="message" cols="30" ref={register({ required: true, maxLength: 500 })} rows="3" placeholder="Message"></textarea>
										<span className="err">
											{errors.message && errors.message.type === "required" && <span>Subject is required</span>}
											{errors.message && errors.message.type === "maxLength" && <span>Max length exceeded</span> }	
										</span>
									</div>
								</div>
							</div>

							<div className="col-12" style={{textAlign: "center"}}>
								<button className="submit" type="submit" value="Submit" style={{background:"#172852", color:"white"}} disabled={isSubmit}>
									{isSubmit
										? (<React.Fragment><span className="spinner-border spinner-border-sm"></span> Sending...</React.Fragment>)
										: 'Submit'
									}
								</button>
							</div>
							<p style={{color:"#172852", marginBottom:"0px"}}>*By submitting I acknowledge Serenus.AI's <Link style={{color:"#172852"}} to="/privacy-policy">Privacy Policy</Link>.</p>
  						</form>
					</div>

					<div className="col-md-4">
						<div className="press" style={{textAlign:"center", width:"100%", borderRadius:"10px", borderBottom:"none", border:"1px solid rgb(23, 40, 82)"}}>
							<h5 style={{color:"rgb(23, 40, 82)"}}><i class="far fa-lightbulb"></i> See how Serenus.AI can  help you</h5>
							<Link to="/#demo"><button className="contact-us-vision" style={{width:"100%"}}>Request a Demo</button></Link>
							<Link to="/careers"><button className="demo-vision" style={{width:"100%"}}>See Open Positions</button></Link>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	 );
}
 
export default Vision;
