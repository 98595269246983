import React from "react";
import header from '../images/404.jpg';
import { Helmet } from 'react-helmet'


const FourZeroFour = () => {
	
	return ( 
		<React.Fragment>
			<Helmet>	
				<title>404 | Serenus.AI&reg;</title>
			</Helmet>
			<div className="col col-12" id="image-picker">
				<img src={header} alt="" width="100%" height="400px" />
				<div style={{position:"absolute", width:"100%", height:"100%", background:"#000000", opacity:"0.4", top:0}}></div>
				<div className="centered main-title" style={{textAlign:"center", width:"100%"}}>
					<h1 className="header-for-pages">Oops!</h1>
					<p>We can't seem to find the page you are looking for </p>
				</div>
			</div>
		</React.Fragment>
	 );
}
 
export default FourZeroFour;