import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './css/main.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "jquery/dist/jquery";
import "bootstrap/dist/css/bootstrap.css";
import "popper.js/dist/popper";
import "bootstrap/dist/js/bootstrap";
import "@fortawesome/fontawesome-free/css/all.css";
import "animate.css/animate.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { BrowserRouter } from "react-router-dom";


ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
