import React, { Component, useEffect } from "react";
import '../css/header.css';
import '../css/login.css';
import { useForm } from 'react-hook-form/dist/index.ie11';
import { baseApiUrl } from './constants';

const axios = require('axios');


const Login = ({ handleLogin, toast }) => {
	
	const { register, handleSubmit, errors } = useForm(); 
	async function onSubmit(data) {
		try {

			// const url = 'http://localhost/serenus-backend/login.php';

			const url = baseApiUrl() + 'login';

			let res = await axios({
				method: 'post',
				url: url,
				data: {
					email: data.email,
					password: data.password,
				}
			});
			if (!res.data.token) {
				toast('Error login', 'error');
				return;
			}
			if (res.data.token) {
				const url2 = baseApiUrl() + 'me?token=' + res.data.token;
				let res2 = await axios(url2);
				if (res2.data.role != 'admin') {
					toast('Error, user is not an admin', 'error');
					return;
				}
				handleLogin(res2.data.id, res2.data.first_name, res.data.token);
			}

		} catch (error) {
			console.log(error);
		}
	
	};

	useEffect(() => {
		const listener = event => {
		  if (event.code === "Enter" || event.code === "NumpadEnter") {
			event.preventDefault();
			handleSubmit(onSubmit)();
		  }
		};
		document.addEventListener("keydown", listener);
		return () => {
		  document.removeEventListener("keydown", listener);
		};
	  }, []);

	return ( 
		<div className="row" style={{background: 'linear-gradient(to bottom, black, transparent)'}}>
		<div className="col-12 col-lg-4 offset-lg-4 col-md-6 offset-md-3 login-card mt-5 mb-5 px-4 hv-center">
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="form-group text-left">
					<input type="email" 
						className="form-control" 
						id="email" 
						aria-describedby="emailHelp" 
						placeholder="Email"
						style={{borderRadius:"10px"}}
						name="email"
						ref={register({ required: true })}
					/>
					<span className="err">{errors.email && '*Email is required'} </span>
				</div>
				<div className="form-group text-left">
					<input type="password" 
						className="form-control" 
						id="password" 
						placeholder="Password"
						style={{borderRadius:"10px", fontSize:'20px'}}
						name="password"
						ref={register({ required: true })}
					/>
					<span className="err">{errors.password && '*Password is required'} </span>
				</div>
				<button className="read-more-press" type="submit" value="Submit">
					Login
				</button>
			</form>
		</div>
		</div>
	);
}
 
export default Login;