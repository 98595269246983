import header from '../images/work.jpg'
import React, { Component } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	NavLink
  } from 'react-router-dom';
import { Helmet } from 'react-helmet'


const Cso = () => {

	return ( 
		<React.Fragment>
			<Helmet>	
				<title>Chief Strategy Officer | Serenus.AI&reg;</title>
			</Helmet>
			<div className="col col-12" id="image-picker">
				<img src={header} alt="" width="100%" height="400px" />
				<div style={{position:"absolute", width:"100%", height:"100%", background:"#000000", opacity:"0.4", top:0}}></div>
				<div className="centered main-title" style={{textAlign:"center", width:"100%"}}>
					<h1 class="header-for-pages">Chief Strategy Officer</h1>
					<div  className="mr-3"><span className="mr-3"><i class="fas fa-map-marker-alt mr-1"></i> Tel Aviv, Israel</span> <span><i class="fas fa-business-time mr-1"></i>Full-Time</span></div>
				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-md-8">
						<p>We are an exciting lifesaving start-up developing an innovative AI-based system for the medical field. You will join a high-profile team to work on groundbreaking problems in health outcomes.
						We are looking for a talented and experienced Chief Strategy Officer executive to join our team; You will be the company’s second-in-command and responsible for the efficiency and growth of the business.</p>
	
						<h5 style={{fontWeight:"bold"}}>As the CSO, you will be:</h5>
						<p>The CSO role is a key member of the senior management team, reporting only to the Chief Executive Officer (CEO). You will have to maintain control of diverse business operations, so we expect you to be an experienced and efficient leader. If you also have excellent people skills, business acumen and exemplary work ethics, we would like to meet you.
						</p>
						<br/>
						<p>The goal of the CSO position is to secure the functionality of the business to drive extensive and sustainable growth.
						</p>
						<br/>
						<h5 style={{fontWeight:"bold"}}>Responsibilities:</h5>
						<ul className="ul-big">
							<li>Participate in expansion activities (investments, acquisitions, corporate alliances, etc.)</li>
							<li>Assist CEO in fundraising ventures</li>
							<li>Manage relationships with partners/vendors</li>
							<li>Oversee daily operations of the company and the work of executives (IT, Marketing, Sales, Finance, etc.)</li>
							<li>Lead employees to encourage maximum performance and dedication</li>
							<li>Evaluate performance by analyzing and interpreting data and metrics</li>
						</ul>
						<br/>
						<h5 style={{fontWeight:"bold"}}>Responsibilities:</h5>
						<ul className="ul-big">
							<li>Proven experience as CSO or relevant role</li>
							<li>Understanding the role of business functions such as HR, Finance, marketing, etc.</li>
							<li>Demonstrable competency in strategic planning and business development</li>
							<li>Experience in fundraising will be a plus</li>
							<li>Outstanding organizational and leadership abilities</li>
							<li>Excellent interpersonal and public speaking skills</li>
							<li>Aptitude in decision-making and problem-solving</li>
						</ul>

						<p style={{fontWeight:"bold"}}>The position will be located within the central area of Israel.</p>
						<p style={{fontWeight:"bold"}}>Position Capacity: Full-Time</p>
						<p>**We are committed to creating a diverse environment and proud to be an equal opportunity employer.</p>
					</div>
					<div className="col-md-4">
						<div className="row">
							<Link className="btn btn-position" to="cso/apply" style={{width:"100%"}}>
								<i class="fas fa-pencil-alt mr-2"></i>
							Apply To Position</Link>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
 
export default Cso;