import { useEffect } from "react";
import { useLocation } from "react-router-dom";


export default function ScrollToTop() {
  
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash == '')
        window.scrollTo(0, 0);
    else {
        const section = document.querySelector( '#demo' );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }
  }, [pathname]);

  return null;
}
