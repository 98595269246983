import terms from '../images/privacy.jpg'
import React, { Component } from "react";


const Privacy = () => {
	return ( 
		<React.Fragment>
			<div className="col col-12" id="image-picker">
				<img src={terms} alt="" height="350px" width="100%" />
				<div style={{position:"absolute", width:"100%", height:"100%", background:"#000000", opacity:"0.6", top:0}}></div>
				<div className="centered main-title" style={{textAlign:"center", width:"100%"}}>
					<h1 class="header-for-pages">Privacy Policy</h1>
				</div>
			</div>
	
			<br/><br/>
			<div class="container" style={{width:"80%"}}>
				<b>LAST MODIFIED: April 14TH, 2021</b>
 				<br/><br/><br/><br/>
				<p className="font-one-em">
					Serenus.AI Ltd. (“Serenus”, “we”) is committed to maintaining the privacy of its users (“user”, “you”). The following information describes how Serenus collects and processes information about you when you use our Services and Website (as defined below).
				</p>
				<p className="font-one-em">
					Our Privacy Policy explains:
				</p>
				<ul className="font-one-em">
					<li>What information we collect and why we collect it.</li>
					<li>How we use that information.</li>
					<li>How we secure your information.</li>
					<li>Your rights with regard to the collection of such information.</li>
				</ul>
				<p className="font-one-em">
					By using our Services and Website, you agree to this Privacy Policy.
				</p>

				<h6 style={{fontWeight:"bold"}}>GENERAL </h6>
				<p className="font-one-em">
					Serenus provides an artificial intelligence-based platform developed to assist medical professionals, insurers and patients with their medical treatment decisions, by utilizing advance machine learning technologies integrating patient’s profile with the medical knowledge known today <b>(“Services”)</b> . Serenus’ website <b>(“Website”)</b>  is designed to provide essential information on the Services to users and to enable users to contact Serenus regarding the Services and to request a demo of the Services. 
				</p>

				<h6 style={{fontWeight:"bold"}}>COLLECTING INFORMATION </h6>
				<p className="font-one-em">
					Please note that the scope of this Privacy Policy is limited only to information collected by Serenus through your use of its Website and Services. Some information may be automatically collected, and some is collected when you interact with our Service and Website. The type of information that may be automatically collected is non-personal information, which includes your session durations, the content you accessed on the Website information about your computer and your internet connection and the frequency and scope of your use of the Website. 
				</p>
				<p className="font-one-em">
					Information from which you can be personally identified may also be collected, including but not limited to your name, country, email address, telephone number, company, job title and the location of the computer through which you used the Services and Website (hereinafter: “Personal Information”). Personal Information shall be collected only if received voluntarily from you, including, without limitation, via the following ways: 
				</p>

				<h6 style={{fontWeight:"bold"}}>a.	PROVIDING A DEMO OF THE SERVICES</h6>
				<p className="font-one-em">If you wish to receive a demo of the Services, we will collect your full name, email address, country, company name, job title and phone number to enable you to request the demo.</p>

				<h6 style={{fontWeight:"bold"}}>b.	SUBSCRIBING TO USE THE SERVICES </h6>
				<p className="font-one-em">
					If you wish to register to the Services, we will collect your first and last name, email address, phone and address, to enable you to register to use the Services. We will use a third party payment processor who will collect your payment information and process your payment information in a highly secured manner. <br/>
					We will also enable you to log-in to use the Services via the Website when you submit your username and selected password. 
				</p>

				<h6 style={{fontWeight:"bold"}}>c.	CONTACTING US</h6>
				<p className="font-one-em">
					If you wish to contact us regarding our Website and Services, we will collect your full name, email address, phone number and the information related to your inquiry.
				</p>

				<h6 style={{fontWeight:"bold"}}>d.	APPLYING FOR A JOB</h6>
				<p className="font-one-em">
					If you wish to apply for a job at Serenus, will collect the information you provided in your resume, your full name and contact information (email, LinkedIn profile and phone number), to process your resume in our information systems dedicated to recruitment and HR management as well as to contact you with respect to a potential position at Serenus.
				</p>

				<h6 style={{fontWeight:"bold"}}>USE OF INFORMATION </h6>
				<p className="font-one-em">We use the Personal Information we collect from you for a range of different business purposes according to different legal bases of processing. We may use or process your Personal Information for the following purposes. One or more purposes may apply simultaneously.</p>

				<h6 style={{fontWeight:"bold"}}>1.	PROVIDING THE REQUESTED SERVICES</h6>
				<ul>
					<li>We may collect Personal Information to provide you if you wish to experience our demo, apply for a job or contact us regarding the Services. </li>
					<li>Such collection of information will enable us to provide you with technical and professional assistance, with regard to the Services you use or intend to use.</li>
				</ul>
				<p className="font-one-em">
					We process the Personal Information where it is necessary for the adequate performance of the contract or negotiation of a contract regarding the requested Services.
				</p>

				<h6 style={{fontWeight:"bold"}}>2.	IMPROVEMENT AND DEVELOPMENT OF THE SERVICES</h6>
				<ul className="font-one-em">
					<li>We may collect Personal Information to improve and develop our Services and understand feedback on the Services and to help provide more information on the use of our Services quickly and easily.</li>
					<li>We collect Personal Information for ongoing review and improvement of the information provided on our Services and Website to ensure it is user friendly. </li>
					<li>We collect Personal Information to improve the management and administration of our business and maintain compliancy with our internal policies and procedures.</li>
					<li>We conduct surveys and research, test features in development, and analyze the information we have to evaluate and improve our Services, develop new features, and conduct audits and troubleshooting activities. </li>
				</ul>
				<p className="font-one-em">
					We process this information in light of our legitimate interest in improving the Services, to allow our users to have the best experience.
				</p>

				<h6 style={{fontWeight:"bold"}}>3.	MAINTAIN A SAFE AND SECURE ENVIRONMENT</h6>
				<p className="font-one-em">We may use your information to detect and prevent fraud, abuse and security incidents in the following ways;</p>
				<ul className="font-one-em">
					<li>Verify and authenticate your identity and prevent unauthorized or illegal activity;</li>
					<li>Enhance the safety and security of our Website and Services;</li>
					<li>Conduct security investigations and risk assessments;</li>
					<li>Prevent or take action against activities that are, or may be, in breach of our terms of service or applicable law. </li>
				</ul>
				<p className="font-one-em">
					We process this information in light of our legitimate interest in improving our Services and enabling our users to browse in a secure environment.
				</p>

				<h6 style={{fontWeight:"bold"}}>DISCLOSURE OF INFORMATION AND TRANSFER OF DATA </h6>
				<p className="font-one-em">Except as otherwise provided in this Privacy Policy, we reasonably attempt to ensure that we never intentionally disclose any of your Personal Information, to any third party without having received your permission, except as provided for herein or otherwise as permitted or required under law.</p>
				<p className="font-one-em">In order to perform our contractual and other legal responsibilities or purposes, we may, from time to time, need to share your Personal Information with third parties. We may as well share your Personal Information with our affiliates, subsidiaries or any third party service providers and individuals to facilitate our Services or any portion thereof, such as marketing, data management or maintenance services. We may also share your information with analytics service providers for analytics services. Such analytics service providers set their own cookies or other identifiers on your computer, through which they can collect information about your usage of our Services and Website. This helps us compile aggregated statistics about the effectiveness of our Services. </p>
				<p className="font-one-em">The above mentioned third parties may be located in countries other than your own, and we may send them information we receive. When such third party service providers process your Personal Information on our behalf, we will assure that they comply with obligations similar to those which are set forth in this Privacy Policy. We will also assure that they will abide by our data privacy and security requirements, and will be allowed to use the Personal Information solely for the purposes we set. We will transfer your Personal Information while using appropriate and suitable safeguards, while using a variety of legal mechanisms, including contracts, to ensure your rights and protections travel with your data. </p>
				<p className="font-one-em">We may also transfer your information, including Personal Information, in connection with a corporate merger, consolidation, the sale of related assets or corporate division or other fundamental corporate changes. Furthermore, information about you may also be released in order to comply with any valid legal obligation or inquiry or process such as a search warrant, subpoena, statute or court order. We will also release specific information in special cases, such as if you use the Services and Website to perform an unlawful act or omission or take any act or omission that may damage Serenus, its property and goodwill, or if there is an attempted breach of the security of the Services and Website or a physical or property threat to you or others. The authority supervising such activities is the Israeli Privacy Protection Authority, and you have the right to file a complaint to it or any other relevant supervisory authority.</p>

				<h6 style={{fontWeight:"bold"}}>YOUR RIGHTS</h6>
				<p className="font-one-em">You have the right at any time to request to access or modify your information. To exercise these options, please contact us at info@serenusai.com.</p>
				<p className="font-one-em">In some jurisdictions, in particular those located within the European Union (the <b>"EU"</b>) or within the European Economic Area (the <b> "EEA"</b>), you may be afforded specific rights regarding your Personal Information. Subject to such eligibility, you may have the following rights to:</p>
				<p className="font-one-em">
					1.	Request a rectification of your Personal Information where the information we hold about you is incorrect or incomplete.
				</p>
				<p className="font-one-em">
					2.	Object to the processing of your Personal Information for direct marketing purposes.
				</p>
				<p className="font-one-em">
					3.	Object to the processing of your Personal Information where our legal basis for that processing is that such processing is necessary for our legitimate interests.
				</p>
				<p className="font-one-em">
					4.	Object to an automated decision-making (including profiling) in certain circumstances.
				</p >
				<p className="font-one-em">
					5.	Request the erasure of your Personal Information in certain circumstances, such as where processing is no longer necessary for the purpose it was originally collected for, and there is no compelling reason for us to continue to process or store it;
				</p>
				<p className="font-one-em">
					6.	Receive your Personal Information, or ask us to transfer it to another organization that you have provided to us, which we process by automated means, where our processing is either based on your consent or is necessary for the performance of a contract with you.
				</p>
				<p className="font-one-em">If you wish to file a request regarding any of the above, you may contact us at: info@serenusai.com.</p>

				<h6 style={{fontWeight:"bold"}}>CALIFORNIA ONLINE PRIVACY PROTECTION ACT</h6>
				<p  className="font-one-em">CalOPPA requires commercial websites and online services to post a privacy policy. The law's reach stretches well beyond California to require any person or company in the United States (and conceivably the world) that operates websites collecting Personally Identifiable Information from California consumers, to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals or companies with whom it is being shared. See more at: <a href="http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf">http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf.    </a></p>
				<p className="font-one-em">According to CalOPPA, Serenus agrees to the following:</p>
				<ul className="font-one-em">
					<li>Once this Privacy Policy is created, we will add a link to it on our home page or on the first significant page after entering the Website. </li>
					<li>Serenus’s Privacy Policy link includes the word 'Privacy' and can easily be found on each page on the Website.  </li>
					<li>You can request to change your Personal Information by emailing us at: <a href="mailto:info@serenusai.com. ">info@serenusai.com</a>.</li>
				</ul>

				<h6 style={{fontWeight:"bold"}}>CALIFORNIA PRIVACY RIGHTS</h6>
				The California Consumer Privacy Act of 2018 (“CCPA”) permits users who are California residents to request to exercise certain rights. If you are a California resident, the CCPA grants you the right to request certain information about our practices with respect to your Personal Information. In particular, you can request to receive information on the following:
				<ul className="font-one-em">
					<li>The categories and specific pieces of your Personal Information that we have collected.</li>
					<li>The categories of sources from which we collected your Personal Information.</li>
					<li>The business or commercial purposes for which we collected your Personal Information.</li>
					<li>The categories of third parties with which we shared your Personal Information.</li>
				</ul>
				<p className="font-one-em">You can be rest assured that we do not sell your Personal Information. </p>
				<p className="font-one-em">If you choose to exercise your rights, we will not charge you different prices or provide different quality of our Services, unless those differences are related to your provision of your Personal Information.</p>
				<p className="font-one-em">Please note that you must verify your identity and request before further action is taken. As a part of this process, government identification may be required. Moreover, you may designate an authorized agent to make a request on your behalf. </p>
				<p className="font-one-em">We endeavor to respond to a verifiable consumer request within 45 days of its receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing. Any disclosures we provide, will only cover the 12 month period preceding your verifiable request's receipt. If, for some reason, we cannot reply within such time frame, our response will include an explanation for our inability to comply. If you wish to exercise your CCPA rights, please contact us at: info@serenusai.com.</p>
				<p className="font-one-em">We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:</p>
				<p className="font-one-em">1.	Deny you goods or services.</p>
				<p className="font-one-em">2.	Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.</p>
				<p className="font-one-em">3.	Provide you with a different level or quality of goods or services.</p>
				<p className="font-one-em">4.	Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</p>

				<h6 style={{fontWeight:"bold"}}>CAN SPAM ACT</h6>
				<p>The CAN-SPAM Act is a Federal US law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out strict penalties for violations.</p>
				<p  className="font-one-em" style={{textDecoration:"underline"}}>To be in accordance with CANSPAM, Serenus agrees to the following:</p>
				<ul className="font-one-em">
					<li>Not use false or misleading subjects or email addresses.</li>
					<li>Identify the commercial message sent to you as an advertisement when required. </li>
					<li>Include the physical address of our business or site headquarters.</li>
					<li>Monitor third-party email marketing services for compliance, if one is used.</li>
					<li>Honor opt-out/unsubscribe requests quickly.</li>
					<li>Allow users to unsubscribe by using the link at the bottom of each email.</li>
				</ul>

				<p className="font-one-em">
					If at any time you would like to unsubscribe from receiving future emails, you can email us at <a href="mailto:info@serenusai.com">info@serenusai.com</a> and we will promptly remove you from ALL correspondence.
				</p>

				<h6 style={{fontWeight:"bold"}}>COOKIES</h6>
				<p className="font-one-em">
					We may use “cookies” and/or other technologies or files (collectively, “cookies”) to identify how visitors make use of our Services and Website. This aggregated tracking information may be used to help us improve and enhance the Services and Website experience for all of our visitors. In addition, cookies are used for adjusting the Website to your personal preferences. Cookies contain information such as the pages you visited, the length of time you stayed on the Services and Website, the location from which you accessed the Services and Website and more. If you would prefer not to have cookies stored on your computer, you may modify your browser settings to reject most cookies, or manually remove cookies that have been placed on your computer. However, by rejecting the cookies, you may be unable to fully access the offerings on our Services and Website. To find out more about cookies, visit <a href="www.allaboutcookies.org.">www.allaboutcookies.org.</a>
				</p>

				<h6 style={{fontWeight:"bold"}}>OPT IN OR OPT OUT</h6>
				<p className="font-one-em">
					You are always in control of your data, and if you choose to receive information from us, or others, you can change your mind later. If, at any time, you would like to stop receiving such information or opt out of a feature, you may notify us by writing to info@serenusai.com. You should be aware, however, that it is not always possible to completely remove or modify information in our databases and servers, although we will always make reasonable efforts to do so upon your request. 
				</p>

				<h6 style={{fontWeight:"bold"}}>LINKS TO OTHER WEBSITES</h6>
				<p className="font-one-em">The Services and Website may provide links to other websites. Please be aware that these other websites are not covered by our Privacy Policy. This Privacy Policy does not cover the information practices exercised by other providers of products or services, advertisers or other websites, companies or individuals, which are not owned or controlled by Serenus. We suggest that when linking to another website, you always read that website’s privacy policy before volunteering any personally identifiable information. </p>

				<h6 style={{fontWeight:"bold"}}>DATA SECURITY</h6>
				<p className="font-one-em">
					We deploy industry standard measures to ensure the security, confidentiality, integrity and availability of the Personal Information we process. We maintain physical, technical and administrative safeguards, and test and update these periodically. We endeavor to restrict access to Personal Information on a ‘need to know’ basis for the provision of Services to you. No such measures are perfect or impenetrable. In the event of a security breach, we will take all reasonable action to minimize any harm. Although we will do our best to protect Personal Information, we cannot guarantee the security of data transmitted to our Services and Website and transmission is at the users own risk.
				</p>

				<h6 style={{fontWeight:"bold"}}>DATA RETENTION </h6>
				<p className="font-one-em">Generally, Serenus does not retain information longer than necessary to provide its Services and for its reasonable business and lawful needs. If you withdraw your consent to us processing your Personal Information, we will erase your Personal Information from our systems, unless the Personal Information is required for Serenus to establish, exercise or defend against legal claims or it is necessary for the performance of the requested services.</p>

				<h6 style={{fontWeight:"bold"}}>CHILDREN'S PRIVACY</h6>
				<p className="font-one-em">The service is not intended for children under the age of 16. We do not, knowingly or intentionally, collect information about children who are under 16 years of age. </p>

				<h6><b> IF YOU ARE UNDER THE AGE OF 18 YOU MAY NOT USE THE SERVICE, UNLESS PARENTAL CONSENT IS PROVIDED ACCORDINGLY</b></h6>

				<h6 style={{fontWeight:"bold"}}>QUESTIONS REGARDING OUR PRIVACY POLICY</h6>
				<p className="font-one-em">If you have any questions regarding this Privacy Policy or the practices described above, you are always welcome to contact us at info@serenusai.com.</p>

				<h6>REVISIONS AND MODIFICATIONS TO OUR PRIVACY POLICY</h6>
				<p className="font-one-em">
					We reserve the right to revise, amend, or modify this Privacy Policy at any time. When changing the policy, we will update this posting accordingly. Please review this Privacy Policy often so that you will remain updated regarding our current policies.	
				</p>

				<h6 style={{fontWeight:"bold"}}>GOVERNING LAW AND JURISDICTION</h6>
				<p className="font-one-em">This Privacy Policy will be governed and interpreted pursuant to the laws of the State of Israel without giving effect to its choice of law rules. You expressly agree that the exclusive jurisdiction for any claim or action arising out of or relating to this Privacy Policy shall be to the competent courts in Tel Aviv, Israel, to the exclusion of any other jurisdiction.</p>
				<br/>
				<p className="font-one-em">This page was updated on April, 2021.</p>
			</div>

		</React.Fragment>
	);
}
 
export default Privacy;