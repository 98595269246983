import React, { Component } from "react";
import video from "../images/header.mp4";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import { baseApiUrl, clientUrl, selfUrl } from "./constants";
import jwt_decode from "jwt-decode";
import '../css/merge.css';

const axios = require('axios');


class MergeQuestionnaires extends Component {

    state = {
        isLoading: true,
        isLoggedIn: localStorage.getItem('user_id') ? true : false,
        password: localStorage.getItem('SDPassword') || '',
        masterPassword: localStorage.getItem('IPPassword'),
        token: null, // API Maccabi user token
        select1: 'parent',
        select2: 'teacher',
        ID1: localStorage.getItem('ID1') || '', // First ID variable
        ID2: localStorage.getItem('ID2') || '', // Second ID variable
        final: null,
        loadingAnalyzedReport: false, // Initially set to false
    };

    componentDidMount = async () => {
        // Check Master Password
        if (this.state.masterPassword && await this.checkMasterPassword()) {
            this.setState({ isLoggedIn: true });
        }

        if (this.state.isLoggedIn) {
            await this.loginToSimulator();
            this.setState({ isLoading: false });
        } else if (!this.state.isLoggedIn && this.state.password != '') {
            await this.handleLogin();
        } else {
            this.setState({ isLoading: false });
        }

        // Extracting id from URL parameters
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        if (id) {
            if (this.state.ID1 === '') {
                this.setState({ ID1: id }, () => {
                    // Save updated value to local storage
                    localStorage.setItem('ID1', id);
                });
            } else if (this.state.ID2 === '') {
                this.setState({ ID2: id }, () => {
                    // Save updated value to local storage
                    localStorage.setItem('ID2', id);
                });
            }
        }

        // Enable pressing "Enter" after typing the password
        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
              event.preventDefault();
              this.handleLogin();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }

    handleChange = (event) => {
        this.setState({password: event.target.value});
    }

    handleLogin = async (event) => {
        const isCorrectPassword = await this.checkPassword();
        if (isCorrectPassword) {
            localStorage.setItem('SDPassword', this.state.password);
            await this.loginToSimulator();
            if (this.state.token) {
                this.setState({ isLoading: false, isLoggedIn: true });
            } else {
                toast.error('Error connecting to demo user', {
                    position: "bottom-right"
                });
            }
        }
    }

    checkPassword = async () => {
        const url = baseApiUrl() + 'serenusai/password-check';
        try {
            let res = await axios({
				method: 'post',
				url: url,
				data: {
					page: 'demo',
					password: this.state.password
				}
			});
            console.log(res);
            if (res.data.status == "ok") {
                return true;
            } else if (res.data.status == "error") {
                toast.error('Wrong password', {
                    position: "bottom-right"
                });
                return false;
            }      
        } catch (error) {
            toast.error('Error', {
                position: "bottom-right"
            });
            return false;
        }
    }

    checkMasterPassword = async () => {
        const url = baseApiUrl() + 'serenusai/password-check';
        try {
            let res = await axios({
				method: 'post',
				url: url,
				data: {
					page: 'information-portal',
					password: this.state.masterPassword
				}
			});
            if (res.data.status == "ok") {
                return true;
            } else if (res.data.status == "error") {
                localStorage.removeItem('IPPassword');
                return false;
            }      
        } catch (error) {
            return false;
        }
    }

    loginToSimulator = async () => {
        try {
			const url = baseApiUrl() + 'login';

			let res = await axios({
				method: 'post',
				url: url,
				data: {
					email: "maccabi@serenusai.com",
					password: "Aa123456!",
				}
			});
			if (!res.data.token) {
				toast('Error login', 'error');
				return;
			}
			this.setState({ token: res.data.token });
		} catch (error) {
			console.log(error);
            toast('Error login', 'error');
            return;
		}
    }

    isSessionExpired = () => {
        if (this.state.token) {
			let exp = jwt_decode(this.state.token).exp.toString();
			let now = Date.now()
				.toString()
				.slice(0, 10);
			if (now > exp) {
                console.log("token expired");
				return true;
			}
            console.log("token okay");
            return false;
		}
        console.log("no token");
        return true;
    }

    goToSimulator = async () => {
        const isLocalhost = window.location.hostname === 'localhost';
        const proc = isLocalhost ? 370 : 341;
        if (this.isSessionExpired()) {
            await this.loginToSimulator();
        }

        // Opening simulator on this page and redirecting back here
        const url = `${clientUrl()}/run-test/${proc}?token=${this.state.token}&redirectURL=${selfUrl()}/merge-questionnaires`;
        window.location.href = url;

        // Opening simulator in another page and redirecting to a special page
        // const url = `${clientUrl()}/run-test/${proc}?token=${this.state.token}&redirectURL=${selfUrl()}/finished-simulator`;
        // window.open(url, '_blank');
    }

    handleSelectChange = (event, inputId) => {
        const selectedValue = event.target.value;
        const otherSelectType = inputId === 'select1' ? 'select2' : 'select1';
        const otherSelectedValue = selectedValue === 'parent' ? 'teacher' : 'parent';
    
        this.setState({
            [inputId]: selectedValue,
            [otherSelectType]: otherSelectedValue,
            final: null
        });
    }

    // Function to handle input changes
    handleInputChange = (event, idName) => {
        const newValue = event.target.value;
        this.setState({
            [idName]: newValue,
            final: null
        }, () => {
            // Save updated value to local storage
            localStorage.setItem(idName, newValue);
        });
    };

    // Function to handle clearing the input value
    handleClearInput = (event, idName) => {
        event.preventDefault();
        this.setState({
            [idName]: '',
            final: null
        }, () => {
            // Clear value from local storage
            localStorage.removeItem(idName);
        });
    };

    mergeQuestionnaires = async (event) => {
        event.preventDefault();
        const ID1 = parseInt(this.state.ID1);
        const ID2 = parseInt(this.state.ID2);

        // Validation
        if (isNaN(ID1) || isNaN(ID2)) {
            toast.error('Error - one or more of the IDs is not an integer', {
                position: "bottom-right"
            });
            return;
        }

        if (this.state.select1 === this.state.select2) {
            toast.error('Error - simulators configuration problem', {
                position: "bottom-right"
            });
            return;
        }

        // Disable the button
        this.setState({ loadingAnalyzedReport: true });

        // Send POST request to the API
        const url = baseApiUrl() + 'maccabi/child-development/final-result';
        try {
            let res = await axios({
				method: 'post',
				url: url,
				data: {
					parent_report: this.state.select1 === 'parent' ? ID1 : ID2,
					teacher_report: this.state.select2 === 'teacher' ? ID2 : ID1
				}
			});
            console.log(res);
            if (res.status == 200 && res.data.id) {
                this.setState({ final: res.data.id });
                this.goToReport();
            } else {
                toast.error('An error has occured', {
                    position: "bottom-right"
                });
            }      
        } catch (error) {
            toast.error('Error', {
                position: "bottom-right"
            });
        } finally {
            // Re-enable the button after the request completes
            this.setState({ loadingAnalyzedReport: false });
        }
    }

    goToReport = async (event = null) => {
        if (event) {
            event.preventDefault();
        }

        if (this.isSessionExpired()) {
            await this.loginToSimulator();
        }
        const url = `${clientUrl()}/run-reports/${this.state.final}?token=${this.state.token}`;
        window.open(url, '_blank');
    }

    computeDisableAnalyzeButton = () => {
        return this.state.ID1 === '' || this.state.ID2 === '' || this.state.loadingAnalyzedReport;
    }

    render() {
        const isLoading = this.state.isLoading;
        const isLoggedIn = this.state.isLoggedIn;
        const final = this.state.final;
        const disableAnalyzeButton = this.computeDisableAnalyzeButton();
        
        const selectStyle = {
            fontSize: '1rem !important'
        };


        return (
            <React.Fragment>
                <ToastContainer />
                <Helmet>	
                    <title>Merge Questionnaires Demo | Serenus.AI&reg;</title>
                </Helmet>
                <div className="row">
                    <div className="col col-12 video-ES" id="video-picker" style={{overflow: 'hidden'}}>
                        <video playsInline autoPlay muted loop src={video} type="video/mp4" id="ESvideo"/>
                        <div className="centered main-title main-title-ES">
                            <h1 className="header-for-pages header-for-pages-ES">דמו התפתחות הילד</h1>
                            {/* <h4 className="sub-title">Providing the right treatment, to the right patient, at the right time</h4> */}
                        </div>
                    </div>
                </div>	

                {!isLoading && (
                    !isLoggedIn
                        ? (
                            <div className="col-md-4 offset-md-4 col-sm-6 offset-sm-3 col-12 text-center mt-5">
                                <input type="text" name="password" value={this.state.password} onChange={this.handleChange} placeholder="Please enter the password" />
                                <br />
                                <button className="read-more-press" onClick={this.handleLogin}>Log In</button>
                            </div>
                        )
                        : (
                            <div className="wrapper">
                                <div className="container">
                                    <div className="col-12" dir="rtl" style={{textAlign: "center"}}>
                                        <br/>
                                        <h4>שלב 1 - ממלאים שני סימולטורים</h4>
                                        <br/>
                                        <p>
                                            <button className="btn btn-primary" onClick={() => this.goToSimulator()} style={{width: "100%"}}>להפעלת הסימולטור</button>
                                        </p>
                                        <br/><br/>
                                        <h4>שלב 2 - מנוע הבינה המלאכותית מייצר פרידיקציה מאוחדת</h4>
                                        <br/>
                                        <p>
                                            <form>
                                                <div className="row">
                                                    <div className="col-sm-1 pl-1">
                                                        <button className="btn btn-danger" onClick={(event) => this.handleClearInput(event, 'ID1')} style={{width: "100%"}}><i class="fas fa-trash"></i></button>
                                                    </div>
                                                    <div className="col-sm-2 pl-1">
                                                        <select class="form-control custom-select merge-select" id="select1" value={this.state.select1} onChange={(event) => this.handleSelectChange(event, 'select1')}>
                                                            <option selected value="parent">הורה</option>
                                                            <option value="teacher">גננת</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-sm-2 pr-0">
                                                        <input
                                                            id="ID1"
                                                            class="form-control"
                                                            placeholder="מספר שאלון"
                                                            value={this.state.ID1}
                                                            onChange={(event) => this.handleInputChange(event, 'ID1')}
                                                        />
                                                    </div>
                                                    
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-sm-1 pl-1">
                                                        <button className="btn btn-danger" onClick={(event) => this.handleClearInput(event, 'ID2')} style={{width: "100%"}}><i class="fas fa-trash"></i></button>
                                                    </div>
                                                    <div className="col-sm-2 pl-1">
                                                        <select class="form-control custom-select merge-select" id="select2" value={this.state.select2} onChange={(event) => this.handleSelectChange(event, 'select2')}>
                                                            <option value="parent">הורה</option>
                                                            <option selected value="teacher">גננת</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-sm-2 pr-0">
                                                        <input
                                                            id="ID2"
                                                            class="form-control" 
                                                            placeholder="מספר שאלון"
                                                            value={this.state.ID2}
                                                            onChange={(event) => this.handleInputChange(event, 'ID2')}
                                                        />
                                                    </div>
                                                </div>

                                                {final == null && (
                                                    <div className="row mt-3">
                                                        <div className="col">
                                                            <button className="btn btn-warning" onClick={(event) => this.mergeQuestionnaires(event)} disabled={disableAnalyzeButton} style={{width: "100%"}}>אנליזה של שני הסימולטורים</button>
                                                        </div>
                                                    </div>
                                                )}

                                                {final !== null && (
                                                    <div className="row mt-3">
                                                        <div className="col">
                                                            <button className="btn btn-success" onClick={(event) => this.goToReport(event)} style={{width: "100%"}}>
                                                                צפייה בדו"ח
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </form>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                    )
                }
            </React.Fragment>
        )
    }
}

export default MergeQuestionnaires;
