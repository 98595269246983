import React, { Component } from "react";
import { Helmet } from 'react-helmet'
import header from '../images/company.jpg'


const AboutUs = () => {
	return ( 
		<p></p>
	);
}
 
export default AboutUs;
