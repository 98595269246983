import React, { useEffect } from 'react';
import {
	BrowserRouter as Router,
	Link,
  } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { Redirect  } from 'react-router'
import logo from "../images/logoBanner.png";


const Footer = () => {

	let privacyPath;
	let termsPath;

	useEffect(() => {
		privacyPath = 'privacy-policy';
		let url1 = window.location.href;
		let res1 = url1.split("/");
		let length1 = res1.length;
		if (length1 == 6) {
			privacyPath = '../../privacy-policy';
		}
		if (length1 == 7) {
			privacyPath = '../../../privacy-policy';
		}

		termsPath = 'terms-of-use';
		let url2 = window.location.href;
		let res2 = url2.split("/");
		let length2 = res2.length;
		if (length2 == 6) {
			termsPath = '../../terms-of-use';
		}
		if (length2 == 7) {
			termsPath = '../../../terms-of-use';
		}
	}, []);
	const history = useHistory();


  return (
	  <React.Fragment>
      <footer className="text-center text-lg-start">
        <div className="p-4">
          <div className="row">
            <div className="col-md-4 mb-4 mb-md-0 footer-links" style={{textAlign:"left",marginRight:"auto",marginLeft:"10px"}}>
              <img src={logo} alt="" style={{maxWidth:"100%"}} />
            </div>
            <div className="col-md-4" style={{margin:"0 auto"}}>
              <div className="row">
                <div className="col-md-4 mb-4 mb-md-0" style={{textAlign:"left"}}>
                  <h5 style={{textAlign:"left",color:"white"}}>Company</h5>
                  <ul className="list-unstyled mb-0">
                    <li>
                      <Link to="/"><a className="text-grey" > Home</a></Link>
                    </li>
                    <li>
                      <Link to="/mission"><a className="text-grey" > Mission</a></Link>
                    </li>
                    <li>
                      <Link to="/team"><a className="text-grey" > Team</a></Link>
                    </li>
                    <li>
                      <Link to="/investors"><a className="text-grey" > Investors</a></Link>
                    </li>
                    <li>
                      <Link to="/releases"><a className="text-grey" > Press Releases</a></Link>
                    </li>
                    <li >
                      <Link to="/benefits"><a className="text-grey" > Benefits</a></Link>
                    </li>
                    <li>
                      <Link to="/specialties"><a className="text-grey">Specialties</a></Link>
                    </li>
                    <li>
                      <Link to="/partners"><a className="text-grey">Partners</a></Link>
                    </li>
                    <li>
                      <Link to="/media"> <a className="text-grey">Media</a> </Link>
                    </li>
                    <li>
                      <Link to="/careers"><a className="text-grey">Careers</a> </Link>
                    </li>
                    <li>
                      <Link to="/contact-us"><a className="text-grey">Contact Us</a></Link>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-6 col-md-6 mb-4 mb-md-0 margin-if-big" style={{textAlign:"left"}}>
                  <h5 style={{color:"white"}}>Products</h5>

                  <ul className="list-unstyled mb-0">
                    <li>
                      <Link to="/serenus-ai-for-payers-employers"><a className="text-grey">Serenus.AI for Payers/Employers</a></Link>
                    </li>
                    <li>
                      <Link to="serenus-ai-for-providers-and-physicians"><a className="text-grey">Serenus.AI for Providers and Physicans</a></Link>
                    </li>
                    <li>
                      <Link to="serenus-ai-for-patient"><a className="text-grey">Serenus.AI for Patients</a></Link> 
                    </li>
                    <li>
                      <Link to="emr-api"><a className="text-grey">Serenus EMP API</a></Link> 
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-2 mb-4 mb-md-0 footer-contact" style={{textAlign:"right",marginLeft:"auto",marginRight:"10px"}}>
              <div style={{display:"inline-block",textAlign:"left"}}>
                <h5 style={{color:"white"}}>Contact Us </h5> 
                <i className="fas fa-envelope text-grey"></i><span className="text-grey"> <a href="mailto:info@serenusai.com" className="text-gray" style={{}}>info@serenusai.com</a></span>
                <br/> <br/>
                <a href="https://www.linkedin.com/company/serenus-ai/" target="_blank" style={{color:"white"}}> <i className="fab fa-2x fa-linkedin"></i></a>
                <a href="https://www.facebook.com/SerenusAI" target="_blank" style={{marginLeft:"10px", color:"white"}} className="text-grey"><i className="fab fa-2x fa-facebook-f"></i></a> 
                <a href="https://twitter.com/SerenusAI?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" target="_blank" style={{marginLeft:"10px", color:"white"}} className="text-grey"><i className="fab fa-2x fa-twitter"></i></a> 
                <a href="https://www.youtube.com/channel/UCwJgL9kSG74FKzyZmFgkkBg" target="_blank" style={{marginLeft:"10px", color:"white"}} className="text-grey"><i className="fab fa-2x fa-youtube"></i></a> 
              </div>
            </div>
          </div>
        </div>

        <div className="text-center p-3" style={{color:"white"}}>
          <span className="move-right" style={{position:"relative", top:"30px"}}>
            Copyrights © {new Date().getFullYear()} All Rights Reserved to Serenus.AI &#174; 
            <br/>
            <Link to="terms-of-use"  id="terms" style={{color:"white"}}>Terms of Use</Link>  / <Link to="privacy-policy"  style={{color:"white"}} id="privacy" >Privacy Policy</Link> <br/>
          </span>
          <span style={{fontSize:"8px", position:"absolute", right:"0", color:"black",position:"relative", left:"48%",top:"35px"}}>App icon by <a style={{color:"black"}} href="https://icons8.com/">Icons8</a></span>
          <div style={{position:"absolute",left:"0", position:"relative",top:"10px", left:"-35%"}}>
            <span style={{fontSize:"8px", color:"black"}}><a style={{color:"black"}} href='https://pngtree.com/so/icons-converter'>icons converter png from pngtree.com</a></span> 
            <span style={{fontSize:"8px", color:"black"}}>point PNG Designed By 千图网 from <a href="https://pngtree.com/" style={{color:"black"}}>Pngtree.com</a></span> 
            <span style={{fontSize:"8px", color:"black"}}> blue clipart PNG Designed By 千图网 from <a style={{color:"black"}}  href="https://pngtree.com/">Pngtree.com</a></span>
          </div>
        </div>

      </footer>

	  </React.Fragment>
  );
}

export default Footer;
