import React, { Component } from "react";
import video from "../images/header.mp4";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import { baseApiUrl } from "./constants";
import priorityDashboardVideo from "../images/demo/prioritydashboardnodoctor.mp4";

const axios = require('axios');


class Dashboards extends Component {

    state = {
        isLoading: true,
        isLoggedIn: localStorage.getItem('user_id') ? true : false,
        password: localStorage.getItem('DBPassword') || '',
        masterPassword: localStorage.getItem('IPPassword')
    };

    componentDidMount = async () => {
        if (this.state.masterPassword && await this.checkMasterPassword()) {
            this.setState({ isLoggedIn: true });
        }

        if (this.state.isLoggedIn) {
            this.setState({ isLoading: false });
        } else if (!this.state.isLoggedIn && this.state.password != '') {
            await this.handleLogin();
        } else {
            this.setState({ isLoading: false });
        }

        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
              event.preventDefault();
              this.handleLogin();
            }
          };
          document.addEventListener("keydown", listener);
          return () => {
            document.removeEventListener("keydown", listener);
          };
    }

    handleChange = (event) => {
        this.setState({password: event.target.value});
    }

    handleLogin = async (event) => {
        const isCorrectPassword = await this.checkPassword();
        if (isCorrectPassword) {
            localStorage.setItem('DBPassword', this.state.password);
            this.setState({ isLoading: false, isLoggedIn: true });
        } else {
            localStorage.removeItem('DBPassword');
        }
    }

    checkPassword = async () => {
        const url = baseApiUrl() + 'serenusai/password-check';
        try {
            let res = await axios({
				method: 'post',
				url: url,
				data: {
					page: 'dashboards',
					password: this.state.password
				}
			});
            console.log(res);
            if (res.data.status == "ok") {
                return true;
            } else if (res.data.status == "error") {
                toast.error('Wrong password', {
                    position: "bottom-right"
                });
                return false;
            }      
        } catch (error) {
            toast.error('Error', {
                position: "bottom-right"
            });
            return false;
        }
    }

    checkMasterPassword = async () => {
        const url = baseApiUrl() + 'serenusai/password-check';
        try {
            let res = await axios({
				method: 'post',
				url: url,
				data: {
					page: 'information-portal',
					password: this.state.masterPassword
				}
			});
            if (res.data.status == "ok") {
                return true;
            } else if (res.data.status == "error") {
                localStorage.removeItem('IPPassword');
                return false;
            }      
        } catch (error) {
            return false;
        }
    }

    render() {
        const isLoading = this.state.isLoading;
        const isLoggedIn = this.state.isLoggedIn;
        return (
            <React.Fragment>
                <ToastContainer />
                <Helmet>	
                    <title>Dashboards | Serenus.AI&reg;</title>
                </Helmet>
                <div className="row">
                    <div className="col col-12 video-ES" id="video-picker" style={{overflow: 'hidden'}}>
                        <video playsInline autoPlay muted loop src={video} type="video/mp4" id="ESvideo"/>
                        <div className="centered main-title main-title-ES">
                            <h1 className="header-for-pages header-for-pages-ES">Dashboards</h1>
                            {/* <h4 className="sub-title">Providing the right treatment, to the right patient, at the right time</h4> */}
                        </div>
                    </div>
                </div>	

                {!isLoading && (
                    !isLoggedIn
                        ? (
                            <div className="col-md-4 offset-md-4 col-sm-6 offset-sm-3 col-12 text-center mt-5">
                                <input type="text" name="password" value={this.state.password} onChange={this.handleChange} placeholder="Please enter the password" />
                                <br />
                                <button className="read-more-press" onClick={this.handleLogin}>Log In</button>
                            </div>
                        )
                        : (
                            <div className="row main-page-ES">
                                <div className="col col-12" id="accordion">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="demo center" style={{margin: '10px', paddingTop: '50px'}}>
                                                <h4 data-toggle="collapse" data-target="#db1">Waiting List</h4>
                                                <div id="db1" class="collapse" data-parent="#accordion">
                                                    <br/><br/>
                                                    <p>Track and manage patients waiting list.<br/>
                                                       Prioritize based on your preferred balance between waiting time and urgency while manually controlling specific cases.</p>
												    <button className="btn btn-info my-4" onClick={() => window.open('https://dashboard.serenusai.com', '_blank')}>Go To Dashboard</button>
                                                    <button className="btn btn-info my-4 mx-1" data-toggle="collapse" data-target="#priorityDashboardVid">Watch Video</button>
                                                    <div id="priorityDashboardVid" class="collapse">
                                                        <video controls src={priorityDashboardVideo} type="video/mp4" style={{width: '80%'}} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="demo center" style={{margin: '10px', paddingTop: '50px'}}>
                                                <h4 data-toggle="collapse" data-target="#db2">Labeling Statistics</h4>
                                                <div id="db2" class="collapse" data-parent="#accordion">
                                                    <br/><br/>
                                                    <p>Track and manage case labeling by doctors.<br/>
                                                       Check the progress of labeling doctors and find anomalies.</p>
												    <button className="btn btn-info my-4" onClick={() => window.open('https://admin.serenusai.com/label-db', '_blank')}>Go To Dashboard</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="demo center" style={{margin: '10px', paddingTop: '50px'}}>
                                                <h4 data-toggle="collapse" data-target="#db3">Labeling by Doctor</h4>
                                                <div id="db3" class="collapse" data-parent="#accordion">
                                                    <br/><br/>
                                                    <p>Learn about the labeling status by doctor.</p>
												    <button className="btn btn-info my-4" onClick={() => window.open('https://admin.serenusai.com/admin-commands/label-info', '_blank')}>Go To Dashboard</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="demo center" style={{margin: '10px', paddingTop: '50px'}}>
                                                <h4 data-toggle="collapse" data-target="#db4">Labeling by Procedure</h4>
                                                <div id="db4" class="collapse" data-parent="#accordion">
                                                    <br/><br/>
                                                    <p>Learn about the labeling status by procedure.</p>
												    <button className="btn btn-info my-4" onClick={() => window.open('https://admin.serenusai.com/admin-commands/label-info-by-proc', '_blank')}>Go To Dashboard</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="demo center" style={{margin: '10px', paddingTop: '50px'}}>
                                                <h4 data-toggle="collapse" data-target="#db5">B2C</h4>
                                                <div id="db5" class="collapse" data-parent="#accordion">
                                                    <br/><br/>
                                                    <p>Track and manage B2C products and track data about clients.</p>
												    <button className="btn btn-info my-4" onClick={() => window.open('https://admin.serenusai.com/admin-commands/b2c-dashboard', '_blank')}>Go To Dashboard</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    )
                }
            </React.Fragment>
        )
    }
}

export default Dashboards;
