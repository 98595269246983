import Background from '../images/background4.jpg'
import React, { Component } from "react";
import { Helmet } from 'react-helmet'


const Patient = () => {

	return ( 
		<React.Fragment>
			<Helmet>	
				<title>Serenus.AI for Patients | Serenus.AI&reg;</title>
			</Helmet>
			<div className="col col-12" id="image-picker">
				<img className="img" src={Background} alt="" height="500px" width="100%" />
				<div style={{position:"absolute", width:"100%", height:"100%", background:"#000000", opacity:"0.4", top:0}}></div>
				<div className="centered main-title" style={{textAlign:"center", width:"100%"}}>
					<h1 class="header-for-pages">Serenus.AI for Patients</h1>
				</div>
			</div>

			<div className="wrapper" style={{marginBottom:"0px !important"}}>
				<div className="container">
					<div className="col col-12 margin-bottom-40 margin-top-40">
						<p style={{textAlign:"left"}}>
							MyMedicalOwl&#8482; is an informative platform that helps patients become better acquainted with the best medical practice for their condition and procedure.
						</p>
						<br/>
						<h2 className="center">Major Benefits:</h2>
						<br/>
						<div className="handle">
							<div className="row">
								<div className="col-md-6 margin-bottom-20 why-serenus borderHover">
									<div className="row">
										<div className="col-2">
											<img className="img-height-60 margin-top-5 mr-3" src="https://img.icons8.com/wired/64/000000/flex-biceps.png"/>
										</div>
										<div className="col-10">
											<span className="color position"> Empowers patients</span>
										</div>
									</div>
								</div>
								<div className="col-md-6 margin-bottom-20 why-serenus borderHover">
									<div className="row">
										<div className="col-2">
											<img className="img-height-60 margin-top-5 mr-3" src="https://img.icons8.com/dotty/80/000000/decision-making.png"/>
										</div>
										<div className="col-10">
											<span className="color"> Enhances knowledge and mutual decision-making</span>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6 margin-bottom-20 why-serenus borderHover">
									<div className="row">
										<div className="col-2">
											<img className="img-height-60 margin-top-5 mr-3" src="https://img.icons8.com/wired/64/000000/heart-with-pulse.png"/>
										</div>
										<div className="col-10">
											<span className="color position"> Improves care and patient satisfaction </span>
										</div>
									</div>
								</div>

								<div className="col-md-6 margin-bottom-20 why-serenus borderHover">
									<div className="row">
										<div className="col-2">
											<img className="img-height-50 margin-top-5  mr-4" src="https://img.icons8.com/carbon-copy/100/000000/positive-dynamic--v1.png"/>
										</div>
										<div className="col-10">
											<span className="color position"> Improves patient outcomes</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<br/> 
		</React.Fragment>
	);
}
 
export default Patient;