import { Helmet } from 'react-helmet'
import React, { Component } from "react";
import header from '../images/press.jpg';
import {
	Link,
	NavLink
  } from 'react-router-dom';
import press from '../images/press.png';
import press2 from '../images/press2.png';
import paper from '../images/paper.png';
import serenus1 from '../images/serenus1.png';
import serenus10 from '../images/serenus10.png';
import serenus20 from '../images/serenus20.png';
import patent from '../images/patent1.jpg';
import Serenus13 from '../images/serenus13.png'
import Serenus14 from '../images/serenus14.png'
import Serenus15 from '../images/serenus15.png'
import Serenus16 from '../images/serenus16.png'
import Serenus17 from '../images/serenus17.png'


const Releases = () => {

	return ( 
		<React.Fragment>
			<Helmet>	
				<title>Press Releases | Serenus.AI&reg;</title>
			</Helmet>
			<div className="col col-12 margin-bottom-20" id="image-picker">
				<img src={header} alt="" width="100%" height="450px" />
				<div style={{position:"absolute", width:"100%", height:"100%", background:"#000000", opacity:"0.4", top:0}}></div>
				<div className="centered main-title" style={{textAlign:"center", width:"100%"}}>
					<h1 className="header-for-pages">Press Releases</h1>
				</div>
			</div>

			<div className="col-12">
				<div className="row">
					<div className="col-md-9">
						<p className="margin-bottom-20" style={{textAlign:"left"}}>Serenus.AI fast growth is a result of its unique products, developed by Serenus.AI's R&D laboratories, to deliver leading innovations and state-of-the art technologies. <br/>
							We are committed to providing effective solutions that improve the medical decision making process of professionals and patients. <br/>
							We will be glad to share more about ourselves and to be in touch with journalists in the following email : <a href="mailto:pr@serenusai.com">pr@serenusai.com</a>.</p>
					</div>
					<div className="col-md-3">
						<img src={paper} width="100%" height="220px" alt=""/>
					</div>
				</div>
			</div>

			<div className="col col-12 wrapper" style={{textAlign:"center"}}>
				<h2 className="margin-bottom-20">Latest Press Releases</h2>
				<div className="col col-12 margin-bottom-50">

				<div className="row">
						<div className="press">
							<div className="row">
								<div className="col-md-2">
									<img src={Serenus17} width="100%" height="100%" alt=""/>
								</div>
								<div className="col-md-8">
									<p className="font-size-16">February 27, 2024</p> 
									<h5>Serenus.AI Unveils Patented Generative AI System, Transforming Patients’ Referrals and Prioritization of Waiting lists</h5>
									<p>Serenus.AI, a trailblazer in healthcare technology, announced the launch of Serenus.AI PPW™, a revolutionary Generative AI system designed to streamline patients’ referrals before medical procedures and treatments, with a primary focus on prioritizing patients' waiting lists.</p>
								</div>
								<div className="col-md-2 vertical-align">
									<a className="read-more-press" href="https://www.healthindustrywatch.com/article/691593590-serenus-ai-unveils-patented-generative-ai-system-transforming-patients-referrals-and-prioritization-of-waiting-lists"  target = "_blank">Read More</a>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="press">
							<div className="row">
								<div className="col-md-2">
									<img src={Serenus16} width="100%" height="100%" alt=""/>
								</div>
								<div className="col-md-8">
									<p className="font-size-16">April 18, 2023</p> 
									<h5>Serenus.AI Launches the First Fully Autonomous Generative AI Personalized Medical Authorizations System</h5>
									<p>Serenus.AI launches the first patented autonomous clinically objective, Generative AI, case-by-case review system to improve the utilization and prior-authorization processes before medical procedures.</p>
								</div>
								<div className="col-md-2 vertical-align">
									<a className="read-more-press" href="https://finance.yahoo.com/news/serenus-ai-launches-first-fully-130000073.html"  target = "_blank">Read More</a>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="press">
							<div className="row">
								<div className="col-md-2">
									<img src={Serenus13} width="100%" height="100%" alt=""/>
								</div>
								<div className="col-md-8">
									<p className="font-size-16">December 12, 2022</p> 
									<h5>Serenus.AI will present a development of an AI-based personalized CDSS for the treatment of Chronic Lymphocytic Leukemia, at the 2022 annual ASH meeting</h5>
									<p>The work is the result of a collaboration with J-C Health Care Ltd Israel with the aim to improve outcomes for patients living with CLL.
										The American Cancer Society estimates that in 2022 about 20,160 new cases of CLL were diagnosed, with estimated deaths of 4,410 persons1.
										With advanced machine learning technologies, the system shall allow medical professionals in the future to constantly be updated with the latest research in the field and improve patients' outcomes.</p>
								</div>
								<div className="col-md-2 vertical-align">
									<a className="read-more-press" href="https://finance.yahoo.com/news/serenus-ai-present-development-ai-130000405.html"  target = "_blank">Read More</a>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="press">
							<div className="row">
								<div className="col-md-2">
									<img src={Serenus14} width="100%" height="100%" alt=""/>
								</div>
								<div className="col-md-8">
									<p className="font-size-16">November 30, 2022</p> 
									<h5>Serenus.AI and HealthTrio Launch an AI-based Module to Streamline Medical Authorizations for Knee Replacements</h5>
									<p>Serenus.AI and HealthTrio are continuing an established partnership to launch an AI-based module to streamline and automate medical authorizations for knee replacements. As part of their joint "AI Prior-Auth Connect" project, their first module to streamline medical authorizations for knee replacements will be followed by modules for additional common elective surgeries.</p>
								</div>
								<div className="col-md-2 vertical-align">
									<a className="read-more-press" href="https://finance.yahoo.com/news/serenus-ai-healthtrio-launch-ai-130000396.html"  target = "_blank">Read More</a>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="press">
							<div className="row">
								<div className="col-md-2">
									<img src={Serenus15} width="100%" height="100%" alt=""/>
								</div>
								<div className="col-md-8">
									<p className="font-size-16">November 16, 2022</p> 
									<h5>Serenus.AI announced winner - first place at the prestigious international 2022 World Internet Conference Wuzhen Summit innovation competition</h5>
									<p>Chosen out of hundreds of startups from across the globe, Serenus.AI  wins first place at the at the 2022 World Internet Conference Wuzhen Summit innovation competition on Nov 9.
									The 2022 World Internet Conference Wuzhen Summit that concluded Friday, had a record of 2,100 guests from over 120 countries and regions.
									Serenus.AI specializes in the development of a breakthrough, patented, lifesaving AI-based system, providing personalized medical treatment pathways, providing the right treatment, to the right patient and the right time.</p>
								</div>
								<div className="col-md-2 vertical-align">
									<a className="read-more-press" href="https://finance.yahoo.com/news/serenus-ai-announced-winner-first-140000197.html"  target = "_blank">Read More</a>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="press">
							<div className="row">
								<div className="col-md-2">
									<img src={patent} width="100%" height="100%" alt=""/>
								</div>
								<div className="col-md-8">
									<p className="font-size-16">May 18, 2021</p> 
									<h5>Serenus.AI Granted New US Patent for 'AUTOMATED METHOD AND SYSTEM FOR SCREENING AND PREVENTION OF UNNECESSARY MEDICAL PROCEDURES'</h5>
									<p>Serenus.AI , an innovative startup developing AI-based solutions to improve medical treatment decisions, obtained a Notice of Allowance from the US Patent and Trademark Office that the application 'AUTOMATED METHOD AND SYSTEM FOR SCREENING AND PREVENTION OF UNNECESSARY MEDICAL PROCEDURES' will be registered as a US patent.</p>
								</div>
								<div className="col-md-2 vertical-align">
									<a className="read-more-press" href="https://www.prnewswire.com/il/news-releases/serenusai-granted-new-us-patent-for-automated-method-and-system-for-screening-and-prevention-of-unnecessary-medical-procedures-301293865.html"  target = "_blank">Read More</a>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="press">
							<div className="row">
								<div className="col-md-2">
									<img src={serenus1} width="100%" height="100%" alt=""/>
								</div>
								<div className="col-md-8">
									<p className="font-size-16">Feb 03, 2021</p> 
									<h5>Serenus.AI's Chief Medical Officer featured in the Haaretz Newspaper</h5>
									<p>Recent research has revealed that an alarmingly high percentage of medical procedures are unnecessary, risking patients’ lives and wasting valuable resources. This problem rapidly attains extreme proportions during extraordinary morbidities such as the novel corona virus (Sars-CoV-2) pandemic, which is putting a huge burden on overstretched medical professionals, impacting patients unnecessarily hospitalized and wasting valuable resources that can be directed where they are most needed.</p>
								</div>
								<div className="col-md-2 vertical-align">
									<a className="read-more-press" href="https://www.haaretz.com/haaretz-labels/1.9507648"  target = "_blank">Read More</a>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="press">
							<div className="row">
								<div className="col-md-2">
									<img src={serenus10} width="100%" alt=""/>
								</div>
								<div className="col-md-8">
									<p className="font-size-16">Jan 14, 2021</p> 
									<h5>Serenus.AI is listed as one of the Top InsurTech Companies in Israel (2021)</h5>
									<p>This article showcases our top picks for the best Israel based InsurTech companies. These startups and companies are taking a variety of approaches to innovate the InsurTech industry, but are all exceptional companies well worth a follow. We tried to pick companies across the size spectrum from cutting edge startups to established brands.</p>
								</div>
								<div className="col-md-2 vertical-align">
									<a className="read-more-press" href="https://df.media/these-are-the-top-insurtech-companies-in-israel-2021/"  target = "_blank">Read More</a>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="press-noborder">
							<div className="row">
								<div className="col-md-2">
									<img src={serenus20} width="100%" height="100%" alt=""/>
								</div>
								<div className="col-md-8">
									<p className="font-size-16">Aug 19, 2020</p> 
									<h5>Hillary Harel, CEO & Founder of Serenus.AI won in UK tech awards for being inspirational in her field</h5>
									<p>The UK’s Department for International Trade (DIT) Europe Network will host the first-ever DIT European Tech Women event on September 2nd, which will focus on, you guessed it, prominent female leaders in Europe’s tech sphere. The event was originally planned for March, until COVID came and ruined everyone's fun.</p>
								</div>
								<div className="col-md-2 vertical-align">
									<a className="read-more-press" href="https://www.geektime.com/4-israeli-female-founders-win-uk-awards/?fbclid=IwAR1FTEWR-8BlM8Dy0SFprSHMTLGACCd-c0ruJ93RV3AUOUn5X65KCQ-AZtk"  target = "_blank">Read More</a>
								</div>
							</div>
						</div>
					</div>

					<br/>
					<div className="col-md-4" style={{margin:"0 auto"}}>
						<Link to="/media"><button className="read-more-press" style={{border:"1px solid #0076BB"}}> See More</button></Link>
					</div>

				</div>
			</div>
		</React.Fragment>
	);
}
 
export default Releases;