import React, { Component } from "react";
import video from "../images/header.mp4";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import { baseApiUrl } from "./constants";
import Login from "./login";
import header from "../images/mind.png";
import priorityDashboardVideo from "../images/demo/prioritydashboardnodoctor.mp4";

const axios = require('axios');


class InvestorsPage extends Component {

	constructor(props) {
		super(props);
		this.state = { 
			isLoading: true,
			isLoggedIn: localStorage.getItem('user_id') ? true : false,
			password: localStorage.getItem('IPPassword') || '',
		}
	}

	componentDidMount = async () => {
		if (this.state.isLoggedIn) {
            // await this.getData();
            this.setState({ isLoading: false });
        } else if (!this.state.isLoggedIn && localStorage.getItem('IPPassword')) {
            await this.handleLogin();
        } else {
            this.setState({ isLoading: false });
        }

        const listener = event => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                event.preventDefault();
                this.handleLogin();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
	}

	handleChange = (event) => {
        this.setState({password: event.target.value});
    }
	
	handleLogin = async (event) => {
		const isCorrectPassword = await this.checkPassword();
        if (isCorrectPassword) {
			localStorage.setItem('IPPassword', this.state.password);
			this.setState({ isLoading: false, isLoggedIn: true });
        } else {
			localStorage.removeItem('IPPassword');
		}
	}

	checkPassword = async () => {
        const url = baseApiUrl() + 'serenusai/password-check';
        try {
            let res = await axios({
				method: 'post',
				url: url,
				data: {
					page: 'information-portal',
					password: this.state.password
				}
			});
            console.log(res);
            if (res.data.status == "ok") {
                return true;
            } else if (res.data.status == "error") {
                toast.error('Wrong password', {
                    position: "bottom-right"
                });
                return false;
            }      
        } catch (error) {
            toast.error('Error', {
                position: "bottom-right"
            });
            return false;
        }
    }

	toastMessage = (text, type = null) => {
		if (type == "error")
			toast.error(text, {
				position: "bottom-right"
			});
		else if (type == "success")
			toast.success(text, {
				position: "bottom-right"
			});
		else
			toast.info(text, {
				position: "bottom-right"
			});
	}

	render() {
		return ( 
			<React.Fragment>
				<ToastContainer />
				<Helmet>	
                    <title>Information Portal | Serenus.AI&reg;</title>
                </Helmet>
				<div className="row">
					{/* <div className="col col-12" id="image-picker">
						<img src={header} alt="" width="100%" height="400px" />
						<div style={{position:"absolute", width:"100%", height:"100%", background:"#000000", opacity:"0.4", top:0}}></div>
						<div className="centered main-title" style={{textAlign:"center", width:"100%"}}>
							{!this.state.isLoggedIn
								? (<h1 className="header-for-pages">Log In</h1>)
								: (<React.Fragment>
										<h1 className="header-for-pages">Serenus.AI Information Portal</h1>
									</React.Fragment>)
							}
						</div>
					</div> */}
					<div className="col col-12 video-ES" id="video-picker" style={{overflow: 'hidden'}}>
                        <video playsInline autoPlay muted loop src={video} type="video/mp4" id="ESvideo"/>
                        <div className="centered main-title main-title-ES">
                            <h1 className="header-for-pages header-for-pages-ES">Serenus.AI Information Portal</h1>
                            {/* <h4 className="sub-title">Providing the right treatment, to the right patient, at the right time</h4> */}
                        </div>
                    </div>
				</div>

				{!this.state.isLoggedIn
					? (
					<div className="row">
						<div className="col-12 col-lg-4 offset-lg-4 col-md-6 offset-md-3 login-card mt-5 mb-5 px-4 hv-center">
							<input type="text" name="password" value={this.state.password} onChange={this.handleChange} placeholder="Please enter the password" />
							<br />
							<button className="read-more-press" onClick={this.handleLogin}>Log In</button>
						</div>
					</div>
					)
					: (
						<div className="row main-page-ES">
							<div className="col col-12" id="accordion">
								<div className="row">
									<div className="col-12">
										<div className="demo center" style={{margin: '10px', paddingTop: '50px'}}>
											<h4 data-toggle="collapse" data-target="#ES">Explore Our Executive Summary</h4>
											<div id="ES" className="collapse mt-4" data-parent="#accordion">
												<p>Unveiling the Solution to a Pressing Challenge. Dive into our concise overview to learn about the challenge we're tackling, our innovative solution, our current progress, the products driving our success, insights into our market strategy, our funding needs, and the dynamic team propelling our mission forward.</p>
												{/* <button className="btn btn-info my-4" onClick={() => window.open('/executive-summary', '_blank')}>Executive Summary</button> */}
												<button className="btn btn-info my-4 mx-2" onClick={() => window.open('/payers-brochure', '_blank')}>Payers Brochure</button>
												<button className="btn btn-info my-4 mx-2" onClick={() => window.open('/payers-investors', '_blank')}>Payers Investors</button>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12">
										<div className="demo center" style={{margin: '10px', paddingTop: '50px'}}>
											<h4 data-toggle="collapse" data-target="#simulator">Try The Simulator</h4>
											<div id="simulator" className="collapse mt-4" data-parent="#accordion">
												<p>At the core of our technology lies our meticulously crafted simulator, designed in adherence to medical guidelines. This AI-powered tool conducts comprehensive case analyses by responding to a series of medical inquiries, providing detailed insights for every scenario.</p>
												<p>Pick a procedure, observe a demonstration of the simulator being filled out, Review examples of completed reports or take control and try the simulator yourself.</p>
												<button className="btn btn-info my-4" onClick={() => window.open('/demo', '_blank')}>Simulator Demo</button>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12">
										<div className="demo center" style={{margin: '10px', paddingTop: '50px'}}>
											<h4 data-toggle="collapse" data-target="#dashboards">Discover Our Dashboards</h4>
											<div id="dashboards" className="collapse mt-4" data-parent="#accordion">
												<p>Explore Our Specialized Dashboards, access critical information for medical professionals, manage case labeling to generate data to train our AI, and track and manage patients waiting list.</p>
												<button className="btn btn-info my-4 mx-1" onClick={() => window.open('/dashboards', '_blank')}>Dashboards</button>
												<button className="btn btn-info my-4 mx-1" data-toggle="collapse" data-target="#priorityDashboardVid">Watch Video</button>
												<div id="priorityDashboardVid" class="collapse">
													<video controls src={priorityDashboardVideo} type="video/mp4" style={{width: '80%'}} />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						// <div className="row" style={{background: 'linear-gradient(to bottom, black, transparent)'}}>
						// 	<div className="col col-12 text-center">
						// 		<div className="card m-3" style={{backgroundColor: "rgba(248, 249, 250, 0.9)", top: "0"}}>
						// 			<div className="card-body">
						// 				<h2 className="">Explore our executive summary</h2>
						// 				<p className="">Unveiling the Solution to a Pressing Challenge. Dive into our concise overview to learn about the challenge we're tackling, our innovative solution, our current progress, the products driving our success, insights into our market strategy, our funding needs, and the dynamic team propelling our mission forward.</p>
						// 				<button className="btn btn-outline-success" onClick={() => window.open('/executive-summary', '_blank')}>Executive Summary</button>
						// 			</div>
						// 		</div>

						// 		<div className="card m-3" style={{backgroundColor: "rgba(248, 249, 250, 0.9)", top: "0"}}>
						// 			<div className="card-body">
						// 				<h2 className="">Try the simulator</h2>
						// 				<p className="">At the core of our technology lies our meticulously crafted simulator, designed in adherence to medical guidelines. This AI-powered tool conducts comprehensive case analyses by responding to a series of medical inquiries, providing detailed insights for every scenario.</p>
						// 				<p className="">Pick a procedure, observe a demonstration of the simulator being filled out, Review examples of completed reports or take control and try the simulator yourself.</p>
						// 				<button className="btn btn-outline-success" onClick={() => window.open('/demo', '_blank')}>Simulator Demo</button>
						// 			</div>
						// 		</div>
						// 	</div>
						// </div>
					)
				}
			</React.Fragment>
			
		 );
	}
}
 
export default InvestorsPage;