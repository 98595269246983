import terms from '../images/privacy.jpg'
import React, { Component } from "react";


const Terms = () => {

	return ( 
		<React.Fragment>
			<div className="col col-12" id="image-picker">
				<img src={terms} alt="" height="350px" width="100%" />
				<div style={{position:"absolute", width:"100%", height:"100%", background:"#000000", opacity:"0.6", top:0}}></div>
				<div className="centered main-title" style={{textAlign:"center", width:"100%"}}>
					<h1 class="header-for-pages">Terms of Use</h1>
				</div>
			</div>

			<div class="container" style={{width:"80%"}}>
				<br/><br/>
				<p style={{fontSize:'1.5em'}}><b> LAST MODIFIED: April 13TH, 2021</b></p>
				<br/><br/>
				<p className="font-one-em">
					These Terms of Use ("Terms") shall govern your ("You" or "Your") access and use of Serenus AI Ltd.’s ("We" or " Serenus") website located at https://serenusai.com/ and/or https://mymedicalowl.com ("Website") which is an online resource for information related to Serenus activities, products and services as well as MySerenus™ which is an informative platform that helps patients become better acquainted with the best medical practice for their condition and procedure ("Platform"). 
				</p>

				<p className="font-one-em">
					By accessing or using the Website and/or Platform, or by clicking a button or checking a box marked “I Agree” (or something similar), you signify that you have read, understood, and agree to be bound by these Terms, and to the collection and use of your information as set forth in our Privacy Policy, whether or not you are a registered user. 
				</p>

				<p className="font-one-em">
					You declare that by acceptance of these Terms and/or by using the Website, you are of legal age to form a binding contract with Serenus. Any use or access to the Website and/or Platform by anyone under the age of 18 is strictly prohibited and in violation of these Terms. You may not use the Website and may not accept these Terms if you are a person barred from using the Website under the laws of the country in which you are a resident or from which you use the Website.
				</p>
				<p className="font-one-em">
					You agree that we may change any part of the Website and/or Platform, including its content, at any time or discontinue the Website and/or Platform or any part thereof, for any reason, without notice to you and without liability. You agree to review these Terms periodically and to be aware of such modifications, and you’re accessing or using the Website constitutes your acceptance of these Terms as they appear at the time of your access or use. From time to time, Serenus may offer Website visitors the opportunity to participate in additional features or services through the Website. You may be required to enter into additional agreements or authorizations before you can access such features or services.
				</p>

				<h6 style={{fontWeight:"bold"}}>1.	ABOUT SERENUS</h6>
				<p className="font-one-em">
					1.1.	Serenus is the first AI-based platform that empowers medical decisions at critical post-diagnostic crossroads on patient pathways, saving lives and valuable resources. Our flagship product is the first clinically objective, AI-based, automated case-by-case review system to improve decision-making before medical procedures.
				</p>

				<h6  style={{fontWeight:"bold"}}>2.	GRANT OF LICENSE</h6>
				<p className="font-one-em">
					2.1.	Subject to your agreement and compliance with these Terms, Serenus grants you a personal, non-exclusive, non-transferable, non-sublicensable, revocable, limited scope license to use the information on the Website and the Platform for your personal, noncommercial use only and as permitted by the features of the Platform. You hereby acknowledge that your license to use the Website is limited by these Terms, and, if you violate or if, at any point, you do not agree to any of these Terms, your license to use the Website shall immediately terminate, and you shall immediately refrain from using the Website. If the Website or any part thereof is determined to be illegal under the laws of the country in which you are situated, you shall not be granted any license to use the Website, and must refrain from using the Website.
				</p>
				<p className="font-one-em">
					2.2.	Representatives from life sciences and insurance companies are prohibited from creating accounts or registering for the Platform on behalf of their employers, but may use the Platform in their personal capacity. All content shared within the Website is not for external use. Serenus reserves all rights not expressly granted herein in the Platform and in the Website Materials (as defined below). Serenus may terminate this license at any time for any reason or no reason. 
				</p>
				<p className="font-one-em">
					2.3.	Our Website is made available for personal and non-commercial use only. Therefore, you are not allowed to resell, deep-link, use, copy, monitor (e.g., spider, scrape), display, download, reproduce, or use crawlers, robots, data mining or extraction tools of any type in connection with any content or information, software, products or services available through our Website for any commercial or competitive activity or purpose.
				</p>

				<h6  style={{fontWeight:"bold"}}>3.	ACCESS TO THE WEBSITE</h6>
				<p className="font-one-em">
					3.1.	It is your responsibility to ensure your computer or mobile device meets all the necessary technical specifications to enable you to access and use the Website. Serenus does not provide you with the equipment to access and/or use our Website. Serenus cannot guarantee that the Website will always function without disruptions, delay or errors. Several factors may impact the quality of your communications and use of the Website and may cause failure of your communications, including, without limitation, your local network, firewall, your internet service provider, the public internet, and your power supply. Serenus takes no responsibility for any disruption, interruption or delay caused by any failure of or inadequacy in any of these items or any other items over which we have no control. You are responsible for all fees charged by third parties related to your access and use of the Website (e.g., charges by internet service providers or air time charges).
				</p>

				<h6 style={{fontWeight:"bold"}}>4.	ACCOUNT INFORMATION </h6>
				<p className="font-one-em">
					4.1.	Your use of certain features on the Website may require the creation of an account ("Account"). During the process of creating an Account, you may be required to insert your E-mail address and choose a password to enable your future login to the Website ("Login Information"). The following rules govern the security of your Account and Login information. For the purposes of these Terms, references to Account and Login information shall include any account and account information, including user names, passwords or security questions created for the purpose of using the Website.
				</p>

				<p className="font-one-em">
					a)	You shall not share your Account or Login Information, nor let anyone else access your Account or do anything else that might jeopardize the security of your Account. 
				</p>

				<p className="font-one-em">
					b)	In the event you become aware of or reasonably suspect any breach of security, including, without limitation any loss, theft, or unauthorized disclosure of your Login Information or unauthorized access to your Account, you must immediately notify Serenus and modify your Login Information;
				</p>

				<p className="font-one-em">
					c)	You are solely responsible for maintaining the confidentiality of the Login Information, and you will be responsible for all uses of your Login Information, whether or not authorized by you; 
				</p>

				<p className="font-one-em">
					d)	You are responsible for anything that happens through your Account, whether or not such actions were taken by you, including, for the avoidance of doubt, actions taken by third parties. You therefore acknowledge that your Account may be terminated if someone else uses it to engage in any activity that violates these Terms or is otherwise improper or illegal;  
				</p>

				<p className="font-one-em">
					e)	You undertake to monitor your Account and restrict use by any individual barred from accepting these Terms and/or accessing or using the Website, under the provisions listed herein or any applicable law. You shall accept full responsibility for any unauthorized use of the Website by any of the above mentioned; and
				</p>

				<p className="font-one-em">
					f)	Serenus reserves the right to remove or reclaim any usernames at any time and for any reason, including but not limited to claims by a third party that a username violates such third party's rights. 
				</p>

				<h6 style={{fontWeight:"bold"}}>5.	ACCOUNT TERMINATION</h6>
				<p className="font-one-em">
					5.1.	Serenus may refuse access to the Website or may terminate your Account without notice for any reason, including, but not limited to, a suspected violation of these Terms, illegal or improper use of your Account, or illegal or improper use of the Website, or Serenus' intellectual property as determined by Serenus in its sole discretion. You may lose your user name as a result of Account termination, without responsibility on the part of Serenus for any damage that may result from the foregoing. If you have more than one Account, Serenus may terminate all of your Accounts. Upon termination of your access to the Website and/or Platform for any reason, you shall continue to be bound by these Terms as applicable.
				</p>
				<p className="font-one-em">
					5.2.	We may, without prior notice, change the Website and/or the Platform; stop providing the Website and/or the Platform or certain features of the Platform, to you or to users generally; or create usage limits for the Platform
				</p>

				<h6 style={{fontWeight:"bold"}}>6.	USER CONTENT </h6>
				<p className="font-one-em">
					6.1.	Some areas of the Website may allow users to submit, post, display, provide, or otherwise make available content such as videos, graphics, photographs, comments, questions, and other content or information. Any such materials a user submits, posts, displays, provides, or otherwise makes available on the Website is referred to as <b> ("User Content")</b>.
				</p>
				<p className="font-one-em">
					6.2.	You agree that any User Content published by you through the Website is done so through the use of technology and tools provided by Serenus. You agree that you are publishing such User Content willingly and you represent that you own such User Content or have received the necessary authorizations from third parties, that you have all rights to publish said User Content and that publishing of the content by you complies with all applicable laws and that Serenus’ use thereof as contemplated by these Terms and the Website will not violate infringe any rights of any third party, including but not limited to any intellectual property rights and privacy rights. You grant Serenus the right to act as an agent on your behalf as the Website's operator. 
				</p>
				<p className="font-one-em">
					6.3.	Serenus does not claim ownership of any User Content. However, by sending and/or creating User Content and/or using the Website you automatically grant Serenus a worldwide, non-exclusive, royalty-free, sublicensable, transferable perpetual license of all rights to share, use, edit, modify, translate include, incorporate, adapt, record, publish, make derivative works and reproduce such User Content, including, without limitation, all trademarks associated therewith, in any manner whatsoever, in or out of context, in all languages, in all media now known or hereafter created for the purposes set forth in the Website and/or these Terms, including for the avoidance of doubt commercial, non-commercial and/or promotional use by Serenus associating such User Content with your user information, user name and/or profile picture. Serenus may retain any raw material that you submit, and make internal use of such material including for testing purposes. You hereby agree that any of Serenus’s users may view and use the User Content. You may request that Serenus delete and make no further use of such material by contacting us at: info@serenusai.com. 
				</p>
				<p className="font-one-em">
					6.4.	Serenus may or may not regulate User Content and provides no representations or guarantees regarding the accuracy, quality, or integrity of any User Content posted via the Website. It is hereby clarified with respect to User Content that: (a) Serenus has no obligation to review User Content. Opinions expressed in the User Content are not necessarily the opinion of Serenus; (b) Serenus is not obligated to backup any User Content and User Content may be deleted at any time. You are solely responsible for creating backup copies of your User Content if you desire. 
				</p>
				<p className="font-one-em">
					6.5.	By using the Website, you acknowledge and accept that you may be exposed to material that is inaccurate, you find offensive or objectionable. You agree that Serenus will not under any circumstances be responsible or liable for any User Content, including, but not limited to, errors in any User Content or any loss or damage incurred by use of the User Content or for any failure to or delay in removing User Content. Serenus reserves the right (but shall at no time be obligated) to, in its sole discretion, remove, block, edit, move, disable or permanently delete User Content from the Website with or without notice for any reason whatsoever. You hereby agree that, to the maximum extent permitted by applicable law, Serenus shall at no time be responsible or held liable for the removal, modification or blocking of material or User Content that may be considered offensive and shall at no time be obligated to effect such removal other than under applicable law. 
				</p>

				<h6 style={{fontWeight:"bold"}}>7.	RULES OF CONDUCT AND USAGE</h6>
				<p  className="font-one-em">
					7.1.	You represent and warrant that you have full right and authority to use the Website and to be bound by these Terms. You agree that you will comply fully with these Terms and all applicable domestic and international laws, regulations, statutes, ordinances that govern your use of the Website and/or the transmission of data exported from the country in which you reside. Without limiting the foregoing and in recognition of the global nature of the Internet, you agree to comply with all local and international rules regarding online conduct. You also agree to comply with all applicable laws affecting the transmission of content or the privacy of persons. 
				</p>
				<p  className="font-one-em">
					7.2.	You undertake that you shall not defraud, or attempt to defraud, Serenus or other users, and that you shall not act in bad faith in your use of the Website. If Serenus determines that you have acted in bad faith and/or in violation of these Terms, or if Serenus determines that your actions fall outside of reasonable community standards, Serenus may, at its sole discretion, prohibit you from using the Website. You agree that your use of the Website shall be lawful and that you will comply with the usage rules. In furtherance of the foregoing, and as an example and not as a limitation, you agree that you shall not:
				</p>
				<p  className="font-one-em">
					a)	Access the Website if you are barred under the provisions of these Terms or any applicable law;
				</p > 
				<p className="font-one-em">
					b)	Promote, host or display the Website on any websites that: (i) contain indecent, obscene or pornographic material, hate speech, highly explosive subject matter (as determined by Serenus at its sole discretion), defamatory, libelous, obscene, gambling related, discriminatory, deceptive, abusive spyware, adware, or other malicious programs or code, unsolicited mass distribution of email, or any illegal subject matter or activities or otherwise offensive content, or content addressed to individuals under the age of 18; (ii) incorporate any materials that infringe or assists others to infringe on any copyright, trademark or other intellectual property rights; (iii) are an incentive-based website, such as a lottery or sweepstakes site which rewards users for clicking on links; (iv) engage in activities that violate Serenus' Privacy Policy; (v) openly encourage users to click on banner ads or other sponsored links to support the Website; or (vi) infringe any right of any third party or violate any applicable law or regulation;
				</p>
				<p className="font-one-em">
					c)	Upload, post, transmit or otherwise disseminate any material that is vulgar, indecent, obscene, pornographic, sexual or that is, in a reasonable person's view, otherwise offensive or objectionable;
				</p>
				<p className="font-one-em">
					d)	Libel, ridicule, defame, mock, stalk, intimidate, threaten, harass, or abuse anyone, hatefully, racially, ethnically or in any other manner;
				</p>
				<p className="font-one-em">
					e)	Copy, rent, lease, sell, transfer, assign, sublicense, disassemble, reverse engineer or decompile (except if expressly authorized by Serenus or by applicable statutory law), modify or alter any part of the Website;
				</p>
				<p className="font-one-em">
					f)	Upload or transmit (or attempt to upload or transmit) files that contain viruses, Trojan horses, worms, time bombs, cancelbots, corrupted files or data, or any other similar software or programs that may damage the operation of the Website or the computers of other users of the Website;
				</p>
				<p className="font-one-em">
					g)	Advertise, solicit or transmit any commercial advertisements, including chain letters, junk e-mail or repetitive messages (spim or spam) to anyone;
				</p>
				<p className="font-one-em">
					h)	Violate the contractual, personal, intellectual property or other rights of any party including by using, uploading, transmitting, distributing, or otherwise making available any information or material made available through the Website in any manner that infringes any copyright, trademark, patent, trade secret, or other right of any party (including rights of privacy or publicity);
				</p>
				<p className="font-one-em">
					i)	Create false personas, multiple identities, use bots or other automated software programs to defraud or which otherwise violate these Terms and/or the terms of service of any third-party applications or social networks through which the Website is accessed;
				</p>
				<p className="font-one-em">
					j)	Attempt to obtain passwords or other private information from other members including personally identifiable information (whether in text, image or video form), identification documents, or financial information;
				</p>
				<p className="font-one-em">
					k)	Upload or transmit (or attempt to upload or to transmit), without Serenus' express consent, any material that acts as a passive or active information collection or transmission mechanism, including, without limitation, clear graphics interchange formats ("gifs"), 1x1 pixels, web bugs, cookies or other similar devices (sometimes referred to as "spyware", "passive collection mechanisms" or "pcms");
				</p>
				<p className="font-one-em">
					l)	Improperly use support channels or complaint buttons to make false reports to Serenus;
				</p>
				<p className="font-one-em">
					m)	Develop and distribute "auto" software programs, "macro" software programs or other "cheat utility" software programs or applications or bypass the measures Serenus may use to prevent or restrict access to the Website, including without limitation features that prevent or restrict use or copying of any content or enforce limitations on use of the Website or the content therein;
				</p>
				<p className="font-one-em">
					n)	Copying, distributing, or disclosing any part of the Website in any medium, including without limitation by any automated or non-automated “scraping”, using any automated system, including without limitation “robots,” “spiders,” “offline readers,” etc., to access the Website in a manner that sends more request messages to the Serenus servers than a human can reasonably produce in the same period of time by using a conventional on-line web browser (except that Serenus grants the operators of public search engines revocable permission to use spiders to copy publically available materials from the Website for the sole purpose of and solely to the extent necessary for creating publicly available searchable indices of the materials, but not caches or archives of such materials); 
				</p>
				<p className="font-one-em">
					o)	Encourage any third party to: (i) directly or indirectly generate usage, queries, impressions, or clicks through any automated, deceptive, fraudulent or other invalid means; (ii) edit or modify any tag, or remove, obscure or minimize any tag in any way; or (iii) engage in any action or practice that reflects poorly on Serenus or otherwise disparages or devalues Serenus’ reputation or goodwill; 
				</p>
				<p className="font-one-em">
					p)	Make representations with respect to Serenus not approved in advance and in writing by Serenus. You shall obtain Serenus’ prior written approval to the content of any marketing message, and with respect to any use of Serenus’ trade name and/or trademarks and/or designs in connection with the Website; 
				</p>
				<p className="font-one-em">
					q)	Engage in any fraudulent activity with respect to payment methods or advertiser tracking mechanisms;
				</p>
				<p className="font-one-em">
					r)	Violate any applicable laws or regulations, or encourage or promote any illegal activity including, but not limited to, copyright infringement, trademark infringement, defamation, invasion of privacy, identity theft, hacking, cracking or distribution of counterfeit software, or cheats or hacks for the Website;
				</p>
				<p className="font-one-em">
					s)	Attempt to use the Website on or through any service that is not authorized by Serenus. Any such use is at your own risk and may subject you to additional or different terms. Serenus takes no responsibility for your use of the Website through any service that is not authorized by it;
				</p>
				<p className="font-one-em">
					t)	Attempt to interfere with, hack into or decipher any transmissions to or from the servers for the Website; and/or
				</p>
				<p className="font-one-em">
					u)	Interfere with the ability of others to enjoy using the Website, including disruption, overburden or aid the disruption or overburdening of the Website’s servers, or take actions that interfere with or materially increase the cost to provide the Website for the enjoyment of all its users. 
				</p>

				<h6 style={{fontWeight:"bold"}}>8.	INTELLECTUAL PROPERTY OWNERSHIP</h6>
				<p className="font-one-em">
					8.1.	Serenus and/or its affiliates retain all rights in the Website, Platform and its materials (including, but not limited to, applications, software, designs, graphics, texts, information, pictures, video, sound, music, and other files, and their selection and arrangement) (collectively, "Website Materials"). The entire contents of the Website are protected by applicable copyright, trade dress, patent, and trademark laws, international conventions, and other laws protecting intellectual property and related proprietary rights. You shall not, nor shall you cause any other party to modify, decompile, disassemble, reverse engineer, copy, transfer, create derivative works from, rent, sub-license, distribute, reproduce framed, republish, scrape, download, display, transmit, post, lease or sell in any form or by any means, in whole or in part, use for any purpose other than for using the Website pursuant to these Terms or otherwise exploit any of the Website Materials without Serenus' explicit, prior written consent. The foregoing shall not apply to your own User Content that you post through the Website in accordance with these Terms. All other uses of copyrighted or trade mark material, including any derivative use, require explicit, prior written consent from Serenus. Any reproduction or redistribution of materials not in accordance with these Terms is explicitly prohibited and may result in severe civil and criminal penalties. 
				</p>
				<p className="font-one-em">
					8.2.	Serenus and/or its licensors and affiliates own all right, title, and interest, including copyrights and other intellectual property rights, in and to all the Website Materials. You hereby acknowledge that you do not acquire any ownership rights by using the Website or by accessing any of the Website Materials, or rights to any derivative works thereof.
				</p>

				<h6 style={{fontWeight:"bold"}}>9.	THIRD PARTY MATERIAL </h6>
				<p className="font-one-em">
					9.1.	You may be able to access, review, display or use third party resources, content, information or links to other websites or resources (the "Third Party Materials") via the Website. You acknowledge sole responsibility for and assume any and all risks arising from your access to, use of or reliance upon any such Third Party Materials, and Serenus disclaims any liability that you may incur arising from your access to, use of or reliance upon such Third Party Materials through the Website. You acknowledge and agree that Serenus: (i) is not responsible for the availability, accuracy integrity, quality or lawfulness of such Third Party Materials or the products or website on or available from such Third Party Materials; (ii) has no liability to you or any third party for any harm, injuries or losses suffered as a result of your access to or use of such Third Party Materials; and (iii) does not make any promises to remove Third Party Materials from being accessed through the Website. Your ability to access or link to Third Party Materials or a third party website does not imply any endorsement by Serenus of Third Party Materials or any such third party website. 
				</p>
				<p className="font-one-em">
					9.2.	These Terms do not authorize you to, and you may not use any Third Party Materials except as expressly permitted by the owners of such Third Party Materials and such owners may have the right to seek damages against you for any unauthorized use of their Third Party Materials. 
				</p>
				<p className="font-one-em">
					9.3.	Without derogating from any of Serenus' rights and remedies under these Terms and/or under law, Serenus will be entitled, at its sole discretion, to immediately discontinue access and/or use of the Website or any part thereof, in the event of any alleged infringement, misappropriation or violation of any rights of any third parties in connection with the Third Party Materials.
				</p>
				<p className="font-one-em">
					9.4.	You may not use any Third Party Materials for which you have not obtained appropriate approval to use. Serenus cannot grant permission to use third party content. 
				</p>

				<h6 style={{fontWeight:"bold"}}>10.	LINKS, SEARCH ENGINES </h6>
				<p className="font-one-em">
					10.1.	The Website may contain links to other websites, applications or resources ("Linked Sites"). The Linked Sites are not under the control of Serenus and Serenus is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. Serenus is not responsible for webcasting or any other form of transmission received from any Linked Site. The inclusion of any link does not imply endorsement by Serenus of the site or any association with its operators. You acknowledge and agree that Serenus will not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or Linked Sites available on or through any such site or resource.
				</p>

				<h6 style={{fontWeight:"bold"}}>11.	COMMUNICATION CHANNELS</h6>
				<p className="font-one-em">11.1.	The Website may provide communication channels such as blogs, forums, communities, or chat areas (collectively, "Communication Channels") designed to enable the communication of users with each other and Serenus. Serenus is under no obligation to monitor these Communication Channels but may do so, and reserves the right to review materials posted to the Communication Channels and to remove any materials, at any time, with or without notice for any reason, at its sole discretion. Serenus may also terminate or suspend your access to any Communication Channels at any time, without notice, for any reason. You acknowledge that chats, postings, or materials posted by users on the Communication Channels are neither endorsed nor controlled by Serenus, and these communications should not be considered reviewed or approved by Serenus. You will be solely responsible for your activities within the Communication Channels and under no circumstances will Serenus be liable for any activity within the Communication Channels. You agree that some of your communications within the Communication Channels may be public, and unless provided otherwise under Serenus’ Privacy Policy, you have no expectation of privacy regarding such use of the Communication Channels. Serenus is not responsible for information that you choose to share on the Communication Channels, or for the actions of other users.</p>

				<h6 style={{fontWeight:"bold"}}>12.	FEEDBACK</h6>
				<p className="font-one-em">12.1.	If you send or transmit any communications, comments, questions, suggestions, or related materials regarding the Website, whether by email or otherwise (collectively, "Feedback"), such Feedback is, and will be treated as, non-confidential and non-proprietary. You hereby assign all right, title, and interest in, and Serenus is free to use, without any attribution or compensation to you, any and all Feedback for any purpose whatsoever. You understand and agree that Serenus is not obligated to use, display, reproduce, or distribute any such ideas, know-how, concepts, or techniques contained in the Feedback, and you have no right to compel such use, display, reproduction, or distribution.</p>

				<h6 style={{fontWeight:"bold"}}>13.	COOKIES</h6>
				<p className="font-one-em">13.1.	Like many websites, we may use "cookies" to collect information. A cookie is a small data file that is transferred to your computer's hard disk or your mobile device for record-keeping purposes. Cookies may be sent to a user’s computer or a mobile device when such user accesses or views the Website. Information contained in a cookie may be linked to personal information for purposes such as improving the quality of our Website, tailoring recommendations to interests, and making the Website easier to use. Third party advertisers on the Website may also place or read cookies on your computer or mobile device. Cookies can be disabled at any time by changing your web browser's options to stop accepting cookies or to prompt you before accepting a cookie from the websites you visit. Note however, that if you do not accept cookies, however, you may not be able to use all portions or functionalities of the Website.</p>

				<h6 style={{fontWeight:"bold"}}>14.	PRIVACY </h6>
				<p className="font-one-em">
					14.1.	You understand that by accessing and/or using the Website and/or Platform you consent to the collection, use and disclosure of your information, including (i) personally identifiable information and aggregate and/or anonymized data, and (ii) personal health information which you choose to share with us through the Platform, according to the features of the Platform, all as set forth in our Privacy Policy.
				</p> 
				<p className="font-one-em">
					14.2.	Matters related to privacy are also governed by Serenus’ Privacy Policy, available at https://serenusai.com/privacy-policy <b>("Privacy Policy")</b>, which constitutes an integral part of these Terms by way of reference, and by using or accessing the Website you agree to be bound by its terms and conditions. Unless the context otherwise requires, all reference herein to these Terms, shall also include the Privacy Policy. <b> We advise you to carefully read the Privacy Policy.</b>
				</p>

				<h6 style={{fontWeight:"bold"}}>15.	DISCLAIMERS; NO WARRANTY </h6>
				<p className="font-one-em">
					15.1.	You acknowledge and agree that your use of or access to the Website and/or any materials and/or services, if any, offered by Serenus shall be at your sole risk. The Website and/or Platform is provided on an “AS IS” and “AS AVAILABLE” basis. You are solely responsible for any and all acts or omissions made by you in reliance on the Website or any part thereof. To the fullest extent permitted by law, Serenus, its officers, directors, employees, and agents disclaim all warranties, explicit or implied, in connection with the Website and/or the Platform and your use thereof including implied warranties of merchantability, title, fitness for a particular purpose or non-infringement, usefulness, authority, accuracy, completeness, and timeliness. No advice or information, whether oral or written, obtained by you from the Website or through the Platform will create any warranty not expressly stated herein.  
				</p>
				<p className="font-one-em">
					15.2.	Healthcare Disclaimer. The content, products and services offered herein are here to educate consumers on health care and medical issues that may affect their daily lives. Nothing in the content, products or services should be considered, or used as a substitute for, medical advice, diagnosis or treatment. The Website and its services do not constitute the practice of any medical, nursing or other professional health care advice, diagnosis or treatment. You should always talk to your health care provider for diagnosis and treatment, including your specific medical needs. None of the products or services offered through the Website represents or warrants that any particular service or product is safe, appropriate or effective for you. We advise users to always seek the advice of a physician or other qualified health care provider with any questions regarding personal health or medical conditions. If you have or suspect that you have a medical problem or condition, please contact a qualified health care professional immediately. If you are in the United States and are experiencing a medical emergency, please call 911 or call for emergency medical help on the nearest telephone.
				</p>
				<p className="font-one-em">	
					15.3. <span style={{textDecoration:"underline"}}>	No Medical Advice through the Platform.</span> All of the material provided through the Platform, such as text, graphics, photographs, images, messages, data and any other materials, are for informational purposes only and are not a substitute for professional medical advice or treatment. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding your health. Never disregard professional medical advice or delay in seeking it because of any information provided through the Platform. Additionally, Serenus does not promise any particular results with regards to your health in connection with your use of the Platform. If you think you have a medical emergency, call your doctor or your local emergency phone number immediately. Serenus does not recommend or endorse any specific tests, physicians, products, procedures, opinions, or other information that may be mentioned through the Website and/or Platform. Reliance on any information provided on the Website and/or Platform, or by other members on the Website is solely at your own risk.
				</p>
				<p className="font-one-em">
					15.4.	When using the Platform you may be issued with a report. The report does not provide any medical advice, but only gathers the information and data. Any information provided in the report is not a substitute for professional medical advice or treatment. Never disregard professional medical advice or delay in seeking it because of any information provided through the report. Serenus does not provide healthcare services and therefore cannot guarantee any results or outcomes. Serenus does not recommend self-management of health problems, nor does it promote any particular form of medical treatment.
					It is hereby clarified that the report has not been evaluated by the FDA, and is not intended to diagnose, treat, cure, or prevent any disease or condition, tell you anything about your current state of health, or be used to make medical decisions, including whether or not you should take a medication or how much of a medication you should take
				</p>
				<p className="font-one-em">
					15.5.	If a healthcare professional consults you in connection with or based on the information gathered in the report, you and the healthcare professional shall have a direct relationship thereupon. Serenus is not in any way liable on such actions and is not acting anyhow thereupon as a healthcare professional, but is only providing the report containing the information you desire to receive.
				</p>

				<h6 style={{fontWeight:"bold"}}>16.	LIMITATION OF LIABILITY</h6>
				<p className="font-one-em">16.1.	WITHOUT DEROGATING FROM THE ABOVEMENTIONED, IN NO EVENT WILL SERENUS, ITS DIRECTORS, OFFICERS, AGENTS, CONTRACTORS, PARTNERS, CONSULTANTS AND/OR EMPLOYEES, BE LIABLE TO YOU OR ANY THIRD PERSON FOR ANY, INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER INCLUDING ANY LOST PROFITS OR LOST DATA ARISING FROM YOUR USE OF THE WEBSITE OR OTHER MATERIALS ON, ACCESSED THROUGH OR DOWNLOADED FROM THE WEBSITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT SERENUS HAS BEEN ADVISED OF THE POSSIBILITY OF THESE DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.</p>
				<p className="font-one-em">16.2.	IN NO EVENT SHALL SERENUS, ITS DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, LICENSORS OR AFFILIATES BE LIABLE TO YOU FOR ANY CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR COSTS IN AN AMOUNT EXCEEDING THE AMOUNT YOU PAID TO SERENUS HEREUNDER OR $100.00, WHICHEVER IS GREATER.</p>
				<p className="font-one-em">16.3.	In addition and without derogating from the above, to the maximum extent permitted by applicable law, Serenus assumes no liability or responsibility for any (a) errors, mistakes, or inaccuracies of content or information or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Website and/or the Platform; (b) personal injury or property damage, of any nature whatsoever, resulting from your access to or use of the Website and/or Platform; (c) any unauthorized access to or use of Serenus’ servers and/or any and all information stored therein; (d) any interruption or cessation of transmission to or from the Website; (e) any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Website by any third party; and/or (f) User Content or the defamatory, offensive, or illegal conduct of any third party. </p>
				<p className="font-one-em">16.4.	You specifically acknowledge that Serenus shall not be liable for any user submissions and/or defamatory, offensive and/or illegal conduct by any third party, and that the risk of harm or damage from and/or associated with the foregoing rests entirely with you. Under no circumstances will Serenus be responsible for any damage, loss or injury resulting from hacking, tampering or other unauthorized access or use of your Account or the information contained therein and/or the Platform.</p>

				<h6 style={{fontWeight:"bold"}}>17.	INDEMNIFICATION</h6>
				<p className="font-one-em">17.1.	You agree to defend, indemnify and hold harmless Serenus, its directors, officers, and employees, its contractors, agents, licensors and subsidiaries from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney’s fees) arising from: (a) your use of and access to the Website and/or Platform, including any data or content transmitted or received by you; (b) your violation of any term of these Terms, including without limitation your breach of any of the representations and warranties contained herein; (c) your violation of any third-party right, including without limitation any intellectual property rights or rights of privacy; (d) your violation of any applicable law or regulation; (e) User Content or any content that is submitted via your Account including without limitation misleading, false, or inaccurate information; or (f) any other party’s access and use of the Website and/or the Platform with your Account and/or Login Information.</p>

				<h6 style={{fontWeight:"bold"}}>18.	NOTICES</h6>
				<p className="font-one-em">18.1.	Notices to you may be made via the Website and/or e-mail. Serenus may also provide notices of changes to these Terms or other matters by displaying notices or links to notices to you generally on the Website. You agree that all agreements, notices, disclosures and any other communications that Serenus provides as aforementioned satisfy any legal requirement that such communications be in writing.</p>
				<p className="font-one-em">18.2.	By providing Serenus your email address and other contact information, you consent to our using such contact information to send you Website and/or Platform-related notices, including any notices required by law, in lieu of communication by postal mail. Such notices may be provided to you by email, or any other contact form you may provide us with (including your phone number for calls or text messages) and you agree to receive such notices. We may also use your email address or phone number to send you other messages, such as changes to features of the Platform and special offers. If you do not want to receive such notifications, you may opt-out or change your preferences in your settings page. Opting out may prevent you from receiving notification including notices regarding updates, improvements, or offers. You will not be able to opt-out from receiving service and payment connected notifications from Serenus. </p>

				<h6 style={{fontWeight:"bold"}}>19.	GENERAL </h6>
				<p className="font-one-em">19.1.	By using or visiting the Website, you agree that the laws of the State of Israel, without regard to principles of conflict of laws and regardless of your location, will govern these Terms and any dispute of any sort that might arise between you and Serenus. Any claim or dispute between you and Serenus that arises in whole or in part from your access and/or use of the Website shall be decided exclusively by a court of competent jurisdiction located in Tel-Aviv, Israel, to the exclusion of any other courts, and you hereby consent to, and waive all defenses of lack of personal jurisdiction and forum non-convenience with respect to venue and jurisdiction in the courts of Tel-Aviv, Israel. Serenus reserves the right to amend these Terms at any time and without notice, and it is your responsibility to review these Terms regularly for any changes. Your use of the Website following any amendment of these Terms will signify your assent to and acceptance of its revised terms. </p>

				<p className="font-one-em">For any questions about these Terms of Use or any other issue regarding Serenus or the Website please contact us at: info@serenusai.com</p>
				<p className="font-one-em">Last update: April 2021</p>
				<p className="font-one-em">All rights reserved, Serenus AI Ltd.</p>
			</div>
		</React.Fragment>
	);
}
 
export default Terms;