import Media from '../images/media.jpg';
import React, { Component } from "react";
import Media1 from '../images/media1.png'
import Media2 from '../images/media2.png'
import Media3 from '../images/media3.png'
import Media4 from '../images/media4.png'
import Media5 from '../images/media5.png'
import Media6 from '../images/media6.png'
import Media7 from '../images/media7.png'
import Media8 from '../images/media8.png'
import Serenus1 from '../images/serenus1.png'
import Serenus2 from '../images/serenus2.png'
import Serenus3 from '../images/serenus3.png'
import Serenus4 from '../images/serenus4.png'
import Serenus5 from '../images/serenus5.png'
import Serenus6 from '../images/serenus6.png'
import Serenus7 from '../images/serenus7.png'
import Serenus8 from '../images/serenus8.png'
import Serenus9 from '../images/serenus9.png'
import Serenus10 from '../images/serenus10.png'
import Serenus11 from '../images/serenus11.png'
import Serenus13 from '../images/serenus13.png'
import Serenus14 from '../images/serenus14.png'
import Serenus15 from '../images/serenus15.png'
import Serenus16 from '../images/serenus16.png'
import Serenus17 from '../images/serenus17.png'
import patent from '../images/patent.jpg'
import patent2 from '../images/patent-art2.jpg'
import patent3 from '../images/patent-art3.jpg'
import PDF from '../images/award/deathbymedicine.pdf'
import { Helmet } from 'react-helmet'
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	NavLink,
	useHistory
  } from 'react-router-dom';

  
const Mdia = () => {

	return ( 
		<React.Fragment>
			<Helmet>	
				<title>Media | Serenus.AI&reg;</title>
			</Helmet>
			<div className="col col-12" id="image-picker">
				<img src={Media} alt="" width="100%" height="500px" />
				<div style={{position:"absolute", width:"100%", height:"100%", background:"#000000", opacity:"0.5", top:0}}></div>
				<div className="centered main-title" style={{textAlign:"center", width:"100%"}}>
					<h1 class="header-for-pages">Media</h1>
					<p>Serenus.AI empowering medical decisions, saving lives and valuable resources. <br/> Learn more about our AI-driven technology and about the importance of preventing unnecessary medical procedures through a series of articles.</p>
				</div>
			</div>

			<div className="col col-12">
				<div className="row margin-top-40">
					<br/><br/>
					<div className="col-md-8 ">
						<h3 style={{textAlign:"left", textShadow:"none"}}>Articles about Serenus.AI</h3>
						<br/>
						
						<div className="row">
							<div className="col-md-3 col-sm-5 col-xs-3">
								<img className="media" src={Serenus17} style={{width:"100%"}} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>Serenus.AI Unveils Patented Generative AI System, Transforming Patients’ Referrals and Prioritization of Waiting lists</h5>
								<p>Serenus.AI, a trailblazer in healthcare technology, announced the launch of Serenus.AI PPW™, a revolutionary Generative AI system designed to streamline patients’ referrals before medical procedures and treatments, with a primary focus on prioritizing patients' waiting lists.
								</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://www.healthindustrywatch.com/article/691593590-serenus-ai-unveils-patented-generative-ai-system-transforming-patients-referrals-and-prioritization-of-waiting-lists"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr>

						<div className="row">
							<div className="col-md-3 col-sm-5 col-xs-3">
								<img className="media" src={Serenus16} style={{width:"100%"}} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>Serenus.AI Launches the First Fully Autonomous Generative AI Personalized Medical Authorizations System</h5>
								<p>Serenus.AI launches the first patented autonomous clinically objective, Generative AI, case-by-case review system to improve the utilization and prior-authorization processes before medical procedures.
								</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://finance.yahoo.com/news/serenus-ai-launches-first-fully-130000073.html"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr>

						<div className="row">
							<div className="col-md-3 col-sm-5 col-xs-3">
								<img className="media" src={Serenus13} style={{width:"100%"}} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>Serenus.AI will present a development of an AI-based personalized CDSS for the treatment of Chronic Lymphocytic Leukemia, at the 2022 annual ASH meeting</h5>
								<p>The work is the result of a collaboration with J-C Health Care Ltd Israel with the aim to improve outcomes for patients living with CLL.
									The American Cancer Society estimates that in 2022 about 20,160 new cases of CLL were diagnosed, with estimated deaths of 4,410 persons1.
									With advanced machine learning technologies, the system shall allow medical professionals in the future to constantly be updated with the latest research in the field and improve patients' outcomes.
								</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://finance.yahoo.com/news/serenus-ai-present-development-ai-130000405.html"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr>


						<div className="row">
							<div className="col-md-3 col-sm-5 col-xs-3">
								<img className="media" src={Serenus14} style={{width:"100%"}} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>Serenus.AI and HealthTrio Launch an AI-based Module to Streamline Medical Authorizations for Knee Replacements</h5>
								<p>Serenus.AI and HealthTrio are continuing an established partnership to launch an AI-based module to streamline and automate medical authorizations for knee replacements. As part of their joint "AI Prior-Auth Connect" project, their first module to streamline medical authorizations for knee replacements will be followed by modules for additional common elective surgeries.
								</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://finance.yahoo.com/news/serenus-ai-healthtrio-launch-ai-130000396.html"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr>


						<div className="row">
							<div className="col-md-3 col-sm-5 col-xs-3">
								<img className="media" src={Serenus15} style={{width:"100%"}} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>Serenus.AI announced winner - first place at the prestigious international 2022 World Internet Conference Wuzhen Summit innovation competition</h5>
								<p>Chosen out of hundreds of startups from across the globe, Serenus.AI  wins first place at the at the 2022 World Internet Conference Wuzhen Summit innovation competition on Nov 9.
									The 2022 World Internet Conference Wuzhen Summit that concluded Friday, had a record of 2,100 guests from over 120 countries and regions.
									Serenus.AI specializes in the development of a breakthrough, patented, lifesaving AI-based system, providing personalized medical treatment pathways, providing the right treatment, to the right patient and the right time.
								</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://finance.yahoo.com/news/serenus-ai-announced-winner-first-140000197.html"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr>


						<div className="row">
							<div className="col-md-3 col-sm-5 col-xs-3">
								<img className="media" src={patent} style={{width:"100%"}} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>Serenus.AI Granted New US Patent for 'AUTOMATED METHOD AND SYSTEM FOR SCREENING AND PREVENTION OF UNNECESSARY MEDICAL PROCEDURES'</h5>
								<p>Serenus.AI, an innovative startup developing AI-based solutions to improve medical treatment decisions, obtained a Notice of Allowance from the US Patent and Trademark Office that the application 'AUTOMATED METHOD AND SYSTEM FOR SCREENING AND PREVENTION OF UNNECESSARY MEDICAL PROCEDURES' will be registered as a US patent.
								</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://www.prnewswire.com/il/news-releases/serenusai-granted-new-us-patent-for-automated-method-and-system-for-screening-and-prevention-of-unnecessary-medical-procedures-301293865.html"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr>


						{/* <div className="row">
							<div className="col-md-3 col-sm-5 col-xs-3">
								<img className="media" src={patent3} style={{width:"100%"}} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>Serenus.AI Granted New US Patent for 'AUTOMATED METHOD AND SYSTEM FOR SCREENING AND PREVENTION OF UNNECESSARY MEDICAL PROCEDURES'</h5>
								<p>Serenus.AI, an innovative startup developing AI-based solutions to improve medical treatment decisions, obtained a Notice of Allowance from the US Patent and Trademark Office that the application 'AUTOMATED METHOD AND SYSTEM FOR SCREENING AND PREVENTION OF UNNECESSARY MEDICAL PROCEDURES' will be registered as a US patent.
								</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://www.wicz.com/story/43910426/serenusai-granted-new-us-patent-for-automated-method-and-system-for-screening-and-prevention-of-unnecessary-medical-procedures"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr> */}


						{/* <div className="row">
							<div className="col-md-3 col-sm-5">
								<img className="media" src={patent2} style={{width:"100%"}} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>Serenus.AI Granted New US Patent for 'AUTOMATED METHOD AND SYSTEM FOR SCREENING AND PREVENTION OF UNNECESSARY MEDICAL PROCEDURES'</h5>
								<p>Serenus.AI, an innovative startup developing AI-based solutions to improve medical treatment decisions, obtained a Notice of Allowance from the US Patent and Trademark Office that the application 'AUTOMATED METHOD AND SYSTEM FOR SCREENING AND PREVENTION OF UNNECESSARY MEDICAL PROCEDURES' will be registered as a US patent.
								</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://www.galvnews.com/news_ap/business/article_f4162de4-8981-5970-a662-35618d6f9763.html"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr> */}


						{/* <div className="row">
							<div className="col-md-3 col-sm-5">
								<img className="media" src={patent} style={{width:"100%"}} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>Serenus.AI Granted New US Patent for 'AUTOMATED METHOD AND SYSTEM FOR SCREENING AND PREVENTION OF UNNECESSARY MEDICAL PROCEDURES'</h5>
								<p>Serenus.AI, an innovative startup developing AI-based solutions to improve medical treatment decisions, obtained a Notice of Allowance from the US Patent and Trademark Office that the application 'AUTOMATED METHOD AND SYSTEM FOR SCREENING AND PREVENTION OF UNNECESSARY MEDICAL PROCEDURES' will be registered as a US patent.
								</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://finance.yahoo.com/news/serenus-ai-granted-us-patent-133800940.html"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr> */}


						<div className="row">
							<div className="col-md-3 col-sm-5">
								<img className="media" src={Serenus9} style={{width:"100%"}} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>Israeli Insurtech Serenus.AI won first place winner of MTL Tech Awards 2020</h5>
								<p>Tel-Aviv based startup, Serenus.AI won 1st place of MTL Tech Awards 2020, a leading innovation event in Canada by Printemps numérique.
								Chosen out of hundreds of startups from across the globe, Serenus.AI won first place, by the public and the jury among them representatives from international companies such as Deloitte, and OVHcloud.
								</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://insurtechisrael.news/israeli-insurtech-serenus-ai-won-first-place-winner-of-mtl-tech-awards-2020/"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr>


						<div className="row">
							<div className="col-md-3 col-sm-5">
								<img className="media" style={{width:"100%"}} src={Serenus8} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>Hillary Harel, CEO & Founder of Serenus.AI won in UK tech awards for being inspirational in her field</h5>
								<p>The UK’s Department for International Trade (DIT) Europe Network will host the first-ever DIT European Tech Women event on September 2nd, which will focus on, you guessed it, prominent female leaders in Europe’s tech sphere. The event was originally planned for March, until COVID came and ruined everyone's fun. Now set to be part of London Tech Week, the event will include a multitude of panels, keynote speeches, and conclude with an awards ceremony to recognize the excellence and achievements of 24 women and their work in the technology arena.</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://www.geektime.com/4-israeli-female-founders-win-uk-awards/?fbclid=IwAR1FTEWR-8BlM8Dy0SFprSHMTLGACCd-c0ruJ93RV3AUOUn5X65KCQ-AZtk"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr>


						<div className="row">
							<div className="col-md-3 col-sm-5">
								<img className="media" style={{width:"100%"}} src={Serenus10} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>Serenus.AI is listed as one of the Top InsurTech Companies in Israel (2021)</h5>
								<p>This article showcases our top picks for the best Israel based InsurTech companies. These startups and companies are taking a variety of approaches to innovate the InsurTech industry, but are all exceptional companies well worth a follow.
									We tried to pick companies across the size spectrum from cutting edge startups to established brands.
								</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://df.media/these-are-the-top-insurtech-companies-in-israel-2021/"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr>


						<div className="row">
							<div className="col-md-3 col-sm-5">
								<img className="media" style={{width:"100%"}} src={Serenus11} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>BIRD to invest $8 Million in 10 New Projects , one of them is Serenus.AI</h5>
								<p>July 13, 2020 – During its meeting on June 23, 2020, held via video conference, the Board of Governors of the Israel-U.S. Binational Industrial Research and Development (BIRD) Foundation approved $8 million in funding for ten new projects between U.S. and Israeli companies. In addition to the grants from BIRD, the projects will access private sector funding, boosting the total value of all projects to approximately $20 million.
								</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://www.birdf.com/pr/"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr>


						<div className="row">
							<div className="col-md-3 col-sm-5">
								<img className="media" style={{width:"100%"}} src={Serenus7} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>JVP and AstraZeneca chose Serenus.AI as one of 9 Israeli digital health startups to watch </h5>
								<p>As health systems across the world face unprecedented post-corona challenges, nine Israeli digital health startups were chosen by Jerusalem Venture Partners (JVP), pharmaceutical giant AstraZeneca and the ii2020 National Initiative for their new PlayBeyondBio mentoring and development program.</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://www.israel21c.org/9-israeli-digital-health-startups-to-watch/"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr>


						<div className="row">
							<div className="col-md-3 col-sm-5">
								<img className="media" style={{width:"100%"}} src={Serenus1} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>Serenus.AI is proud for Prof. Elidan, Serenus.AI's Chief Medical Officer to be featured in the Haaretz Newspaper. Prof. Elidan states: "Serenus.AI allows patients and medical professionals to make informed decisions before medical treatments" </h5>
								<p>Recent research has revealed that an alarmingly high percentage of medical procedures are unnecessary, risking patients’ lives and wasting valuable resources. This problem rapidly attains extreme proportions during extraordinary morbidities such as the novel corona virus (Sars-CoV-2) pandemic, which is putting a huge burden on overstretched medical professionals, impacting patients unnecessarily hospitalized and wasting valuable resources that can be directed where they are most needed.</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://www.haaretz.com/haaretz-labels/1.9507648"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr>


						<div className="row margin-top-20">
							<div className="col-md-3 col-sm-5">
								<img className="media" style={{width:"100%"}} src={Serenus2} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}> Serenus.AI is listed as one of the Top InsurTech Companies in Israel (2021) and a far growing startup apart from hundreds of startup companies</h5>
								<p>The Insurtech ecosystem in Israel is growing very fast, with about 100 companies currently in this field. Israel is home to the world’s leading Insurtech companies and has seen mega-investment rounds.</p>
							<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://insurtechisrael.news/mapping-the-israeli-insurtech-ecosystem-and-investments-by-dikla-wagner/"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>		
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr>


						<div className="row margin-top-20">
							<div className="col-md-3 col-sm-5">
								<img className="media" style={{width:"100%"}} src={Serenus3} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>Serenus.AI's NHS partner and University of Leeds' doctor, Prof. Pandit uses our AI platform as a case study for knee replacements.</h5>
								<p>The study is led by a Leeds surgeon could ultimately lead to AI being trialled in hospital departments around the country to help doctors make better decisions.
								The computer-based application developed by Israeli firm Serenus.AI guides doctors and other healthcare professionals through a review of a patient’s case before presenting a recommendation tailored for that individual patient.
								</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://www.yorkshireeveningpost.co.uk/health/can-smart-robot-match-surgeons-skills-leeds-researchers-will-find-out-2005357"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr>
			

						{/* <div className="row margin-top-20">
							<div className="col-md-3 col-sm-5">
								<img className="media" style={{width:"100%"}} src={Serenus4} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>TheMarker published Serenus.AI to be an award-winning company with a vision </h5>
								<p>With two patents pending, Serenus.AI Ltd. has met this challenge head on with a novel AI-based solution that assists medical professionals, health systems and insurers in making better medical decisions, saving lives and valuable resources.</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://www.extra-mag.co.il/making-an-informed-decision/"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr> */}

						<div className="row margin-top-20">
							<div className="col-md-3 col-sm-5">
								<img className="media" style={{width:"100%"}} src={Serenus5} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>MedGadget releases Serenus.AI as one of the progressive startups in digital health to be the next Israeli innovation</h5>
								<p>Serenus.AI is developing the first clinically objective, AI-based, automated case-by-case review system to improve the utilization and prior authorization processes before medical procedures. The company has developed algorithms to review the most common elective procedures while providing a comprehensive, real-time analytics report highlighting the related medical factors and their dynamic impact influencing the recommendation.</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://www.medgadget.com/2019/03/digital-health-the-next-big-exit-for-the-startup-nation.html"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr>


						<div className="row margin-top-20">
							<div className="col-md-3 col-sm-5">
								<img className="media" style={{width:"100%"}} src={Serenus6} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>GovTech Incentive Program mentions Serenus.AI as an innovative technology for the public sector and that we are one out of 72% of initiatives that are successful</h5>
								<p>The Israel Innovation Authority, the Israel National Digital Ministry, and the national “Digital Israel” program are renewing its GovTech Incentive Program for the fifth consecutive year. The program was designed to promote innovative technologies for the public sector.
									Companies that have received grants via the program in the past include Serenus.AI, the first AI-based platform assisting with medical decisions ahead of procedures.
								</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://www.calcalistech.com/ctech/articles/0,7340,L-3845679,00.html"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr>


						<div className="row margin-top-20 margin-bottom-40">
							<div className="col-md-3 col-sm-5">
								<img className="media" style={{width:"100%"}} src={Media8} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>AI in Healthcare: How AI Helps Save and Improve Lives</h5>
								<p>AI + Healthcare is eating the world. Over the past few years, the AI innovation in healthcare has been overwhelming, more so than in other AI-disrupted industries we have analyzed. According to Stratistics MRC, the Global Healthcare AI Market accounted for $0.95 billion in 2017 and is expected to reach $19.25 billion by 2026 growing at a CAGR of 39.7%. What drives this vibrant growth?</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://datarootlabs.com/blog/ai-in-healthcare-how-ai-startups-disrupt-the-healthcare-industry"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>


						<h3 style={{textAlign:"left", textShadow:"none"}}>Articles about unnecessary medical procedures</h3>
						<br/>
						<div className="row">
							<div className="col-md-3 col-sm-5">
								<img className="media" style={{width:"100%"}} src={Media1} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>Death By Medicine </h5>
								<p>Death By Medicine: A definitive Review and Close Reading of Medical Peer-Review Journals, and Government Health Statistics Shows That American Medicine Frequently Causes More Harm Than Good.</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} href={PDF}  target = "_blank"><i class="fas fa-book-open mr-1"></i>Read More</a> 
							</div>
						</div>
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr>


						<div className="row margin-top-20">
							<div className="col-md-3 col-sm-5">
								<img className="media" style={{width:"100%"}} src={Media2} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>Cincinnati hospital to pay $4.1 million to settle claims for unnecessary surgeries </h5>
								<p>West Chester Hospital in Cincinnati and its parent company UC Health will pay $4.1 million to settle allegations that the hospital billed Medicare and Medicaid for medically unnecessary spine surgeries.</p>
							<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://www.modernhealthcare.com/article/20151009/NEWS/151009913/cincinnati-hospital-to-pay-4-1-million-to-settle-claims-for-unnecessary-surgeries"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>		
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr>


						<div className="row margin-top-20">
							<div className="col-md-3 col-sm-5">
								<img className="media" style={{width:"100%"}} src={Media3} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>Unnecessary medical tests, treatments cost $200 billion annually, cause harm</h5>
								<p>It's common knowledge in medicine: Doctors routinely order tests on hospital patients that are unnecessary and wasteful.|
									Sutter Health, a giant hospital chain in Northern California, thought it had found a simple solution.</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://www.healthcarefinancenews.com/news/unnecessary-medical-tests-treatments-cost-200-billion-annually-cause-harm"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr>
			

						<div className="row margin-top-20">
							<div className="col-md-3 col-sm-5">
								<img className="media" style={{width:"100%"}} src={Media4} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>Editorial: ‘Do no harm’ with wasteful medical procedures </h5>
								<p>The Hippocratic Oath that doctors take includes the mandate that in trying to do good for a patient, they do no harm.
								All medical professionals will say they take that ethic to heart, but the realities of our health care system — where simple complaints from patients can easily lead to tests and procedures — mean that, in truth, there’s a lot of harm being done.</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://www.heraldnet.com/opinion/editorial-do-no-harm-with-wasteful-medical-procedures/"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr>


						<div className="row margin-top-20">
							<div className="col-md-3 col-sm-5">
								<img className="media" style={{width:"100%"}} src={Media5} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>Paediatric cardiologist reprimanded over unnecessary surgery on three-day-old baby </h5>
								<p>A doctor at the Sydney Children's Hospital ordered unnecessary open-heart surgery on a three-day-old baby and put another at risk of permanent heart damage, the health regulator has found.</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://www.smh.com.au/healthcare/paediatric-cardiologist-reprimanded-over-unnecessary-surgery-on-threedayold-baby-20151015-gka0vs.html"><i class="fas fa-book-open  mr-1"></i>Read More</a>
							</div>
						</div>
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr>


						<div className="row margin-top-20">
							<div className="col-md-3 col-sm-5">
								<img className="media" style={{width:"100%"}} src={Media6} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>A Small Indiana Town Scarred by a Trusted Doctor </h5>
								<p>MUNSTER, Ind. — On a spring evening last year, Debra Davidson flipped on the television to watch the local news. When an item came on about her longtime physician, she perked up and leaned forward. Then she screamed. Her husband rushed into the living room to see if everything was O.K.</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://www.nytimes.com/2015/10/18/business/a-small-indiana-town-scarred-by-a-trusted-doctor.html?_r=0"><i class="fas fa-book-open  mr-1"></i>Read More</a>
							</div>
						</div>
						<hr style={{borderTop:"1px solid #0076bb"}} ></hr>


						<div className="row margin-top-20 margin-bottom-40">
							<div className="col-md-3 col-sm-5">
								<img className="media" style={{width:"100%"}} src={Media7} alt=""/>
							</div>
							<div className="col-md-9 col-sm-7">
								<h5 style={{color:"#0076bb"}}>Multimillionaire doctor who plunged hundreds of patients into bankruptcy by falsely diagnosing cancer and giving them unnecessary chemo is jailed for 45 YEARS </h5>
								<p>A doctor who netted millions of dollars by putting more than 500 patients through unnecessary and grueling cancer treatments and then billing insurers has been sentenced to 45 years in prison.</p>
								<a style={{color:"#0076bb", fontSize:"0.9em"}} target = "_blank" href="https://www.dailymail.co.uk/news/article-3156633/Doctor-prescribed-stunning-doses-unnecessary-treatment-hundreds-cancer-patients-scam-millions-insurance-firms-jailed-45-YEARS.html"><i class="fas fa-book-open mr-1"></i>Read More</a>
							</div>
						</div>
					</div>
					<br/>

					<div className="col-md-4">
						<div className="info" style={{padding:"20px"}}>
							<h4 className="additional">ADDITIONAL INFORMATION</h4>
							<Link to='serenus-ai-for-payers-employers'> <button className="read-more">Read about Serenus.AI for Payers/Employers</button></Link>
							<Link to='serenus-ai-for-providers-and-physicians'><button className="read-more">Read about Serenus.AI for Providers and Physician</button></Link> 
							<Link to='serenus-ai-for-patient'> <button className="read-more">Read about Serenus.AI for Patients</button></Link>
							<Link to='emr-api'> <button className="read-more">Read about Serenus.AI EMR API</button></Link>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
 
export default Mdia;