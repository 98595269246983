import React, { Component } from "react";
import { ToastContainer, toast } from 'react-toastify';
import Login from "./login";
import header from "../images/mind.png";
import ExecutiveSummaryEditor from "./executiveSummaryEditor";
import PayersBrochureEditor from "./payersBrochureEditor";
import CVSEditor from "./cvsEditor";
import jwt_decode from "jwt-decode";

const axios = require('axios');


class Admin extends Component {

	constructor(props) {
		super(props);
		this.state = { 
			isLoggedIn: localStorage.getItem('user_id') ? true : false,
			user: {
				id: localStorage.getItem('user_id') || '',
				name: localStorage.getItem('username') || '',
				token: localStorage.getItem('token') || ''
			},
			editing: null
		}
	}

	componentDidUpdate() {
		// Log out if token expired
		if (this.state.user.token) {
			let exp = jwt_decode(this.state.user.token).exp.toString();
			let now = Date.now()
				.toString()
				.slice(0, 10);
			if (now > exp) {
				this.handleLogout('Session expired');
			}
		}
	}
	
	handleLogin = (id, name, token) => {
		localStorage.setItem('user_id', id);
		localStorage.setItem('username', name);
		localStorage.setItem('token', token);
		this.setState({
			isLoggedIn: true,
			user: {
				id: id,
				name: name,
				token: token
			}
		});
		toast.success('Logged in', {
			position: "bottom-right"
		});
	}

	handleLogout = (text) => {
		localStorage.clear();
		this.setState({
			isLoggedIn: false,
			user: {
				id: '',
				name: '',
				token: ''
			},
			editing: null
		});
		toast.info(text, {
			position: "bottom-right"
		});
	}

	handleEdit = (num) => {
		this.setState({editing: num});
	}

	toastMessage = (text, type = null) => {
		if (type == "error")
			toast.error(text, {
				position: "bottom-right"
			});
		else if (type == "success")
			toast.success(text, {
				position: "bottom-right"
			});
		else
			toast.info(text, {
				position: "bottom-right"
			});
	}

	render() {
		return ( 
			<React.Fragment>
				<ToastContainer />
				<div className="col col-12" id="image-picker">
					<img src={header} alt="" width="100%" height="400px" />
					<div style={{position:"absolute", width:"100%", height:"100%", background:"#000000", opacity:"0.4", top:0}}></div>
					<div className="centered main-title" style={{textAlign:"center", width:"100%"}}>
						{!this.state.isLoggedIn
							? (<h1 className="header-for-pages">Admin Login</h1>)
							: (<React.Fragment>
									<h1 className="header-for-pages">Admin Panel</h1>
									<p style={{alignContent: 'left'}}>Welcome {this.state.user.name}</p>
									<div>
										<button onClick={() => this.handleEdit(1)} className="btn btn-outline-light mx-2">Edit Executive Summary</button>
										<button onClick={() => this.handleEdit(2)} className="btn btn-outline-light mx-2">Edit Payers Brochure</button>
										<button onClick={() => this.handleEdit(3)} className="btn btn-outline-light mx-2">Edit CVS</button>
										<button onClick={() => this.handleLogout('Logged out')} className="btn btn-outline-light mx-2">Log out</button>
									</div>
								</React.Fragment>)
						}
					</div>
					{this.state.editing == 1 && (
						<div style={{position: 'absolute', bottom: '0px', width: '100%', display: 'flex', justifyContent: 'center'}}>
							<h3 style={{ color: 'white'}}>Executive Summary Editor</h3>
							<a href="/executive-summary" target="_blank" className="btn btn-outline-light ml-5 mb-2">View Page</a>
						</div>
					)}
					{this.state.editing == 2 && (
						<div style={{position: 'absolute', bottom: '0px', width: '100%', display: 'flex', justifyContent: 'center'}}>
							<h3 style={{ color: 'white'}}>Payers Brochure Editor</h3>
							<a href="/payers-brochure" target="_blank" className="btn btn-outline-light ml-5 mb-2">View Page</a>
						</div>
					)}
					{this.state.editing == 3 && (
						<div style={{position: 'absolute', bottom: '0px', width: '100%', display: 'flex', justifyContent: 'center'}}>
							<h3 style={{ color: 'white'}}>CVS Editor</h3>
							<a href="/cvs" target="_blank" className="btn btn-outline-light ml-5 mb-2">View Page</a>
						</div>
					)}
				</div>
				{!this.state.isLoggedIn && (
					<Login handleLogin={this.handleLogin} toast={this.toastMessage} />
				)}
				{this.state.editing == 1 && (
					<ExecutiveSummaryEditor toast={this.toastMessage} user={this.state.user} />
				)}
				{this.state.editing == 2 && (
					<PayersBrochureEditor toast={this.toastMessage} user={this.state.user} />
				)}
				{this.state.editing == 3 && (
					<CVSEditor toast={this.toastMessage} user={this.state.user} />
				)}
			</React.Fragment>
			
		 );
	}
}
 
export default Admin;