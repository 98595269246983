import header from '../images/work.jpg'
import React, { Component } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	NavLink
  } from 'react-router-dom';
import { Helmet } from 'react-helmet'


const FullStack = () => {

	return ( 
		<React.Fragment>
			<Helmet>	
				<title>Full Stack Developer | Serenus.AI&reg;</title>
			</Helmet>
			<div className="col col-12" id="image-picker">
				<img src={header} alt="" width="100%" height="400px" />
				<div style={{position:"absolute", width:"100%", height:"100%", background:"#000000", opacity:"0.4", top:0}}></div>
				<div className="centered main-title" style={{textAlign:"center", width:"100%"}}>
					<h1 class="header-for-pages">Full Stack Developer</h1>
					<div  className="mr-3"><span className="mr-3"><i class="fas fa-map-marker-alt mr-1"></i> Tel Aviv, Israel</span> <span><i class="fas fa-business-time mr-1"></i>Full-Time</span></div>
				</div>
			</div>

			<div className="container">
				<div className="row">
					<div className="col-md-8">
						<p>We're an exciting start-up developing an innovative AI-based system for the medical field. <br />
						We're looking for a talented web developer experienced in both sides of the stack to join our team. <br />
						A proactive self-motivated person to spearhead the development process for our various products.</p>
						<h5 style={{fontWeight:"bold"}}>On a daily basis you will :</h5>
						<ul className="ul-big">
							<li>Design and implement technical solutions with an emphasis on quality and performance.</li>
							<li>Write smart and precise automated unit tests to speed up the deployment process.</li>
							<li>Maintain code troubleshoot and solve issues.</li>
						</ul>
						<br/>
						<h5 style={{fontWeight:"bold"}}>Requirements:</h5>
						<ul className="ul-big">
							<li>Working experience as a web developer.</li>
							<li>Working experience with OOP PHP and MVC frameworks like Laravel.</li>
							<li>A solid understanding of relational databases and experience with an ORM.</li>
							<li>A basic familiarity with the Linux OS and server management.</li>
							<li>Experience with modern JavaScript and any of the popular frameworks such as Angular React or Vue.</li>
						</ul>
						<br/>

						<h5 style={{fontWeight:"bold"}}>Advantages:</h5>
						<ul className="ul-big">
							<li>A good understanding of modern CSS and the web platform.</li>
							<li>Experience with JavaScript SPAs.</li>
							<li>Working experience with the Laravel framework.</li>
						</ul>
						<br/>

						<p style={{fontWeight:"bold"}}>The position will be located within the central area of Israel.</p>
						<p style={{fontWeight:"bold"}}>Position Capacity: Full-Time</p>
						<p>**We are committed to creating a diverse environment and proud to be an equal opportunity employer.</p>
					</div>
					<div className="col-md-4">
						<div className="row">
							<Link className="btn btn-position" to="full-stack/apply" style={{width:"100%"}}>
								<i class="fas fa-pencil-alt mr-2"></i>Apply To Position
							</Link>
						</div>	
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
 
export default FullStack;