import logo from './logo.svg';
import './App.css';
import React, { Component } from "react";
import { BrowserRouter, Switch ,withRouter} from 'react-router-dom';
import { Router, Route, browserHistory } from 'react-router';
import Navbar from "./components/navbar";
import Footer from "./components/footer";
// import Login from "./components/login";
import Admin from './components/admin';
import Home from "./components/home"
import Payers from "./components/payers"
import Providers from "./components/providers"
import Specialties from "./components/specialties"
import AdminPanel from './components/adminPanel';
import Patient from './components/patient';
import Partners from './components/partners';
import Careers from './components/careers';
import Emr from './components/emr';
import Cso from './components/cso';
import Evp from './components/evp';
import Digital from './components/digital';
import Benefits from './components/benefits';
import Apply from './components/apply';
import VP from './components/vp';
import "./App.css";
import SpecialtiesPage from './components/specialtiesPage';
import ContactUs from './components/contact-us';
import Media from './components/media';
import Terms from './components/terms';
import Privacy from './components/privacy';
import RouteChangeTracker from './components/RouteChangeTracker';
import AboutUs from './components/about';
import Team from './components/team';
import Vision from './components/vision';
import Investors from './components/investors';
import About from './components/about-us';
import Releases from './components/releases';
import FourZeroFour from './components/FourZeroFour';
import {TinyButton as ScrollUpButton} from "react-scroll-up-button"; 
import FullStack from './components/full-stack';
import ExecutiveSummary from './components/executiveSummary';
import ScrollToTop from './components/scroll-to-top';
import PayersBrochure from './components/payersBrochure';
import CVS from './components/cvs';
import PriorAuth360 from './components/priorAuth360';
import CTEAS from './components/cteas';
import SimulatorDemo from './components/simulatorDemo';
import InvestorsPage from './components/investorsPage';
import Dashboards from './components/dashboards';
import MergeQuestionnaires from './components/mergeQuestionnaires';
import FinishedSimulator from './components/finishedSimulator';


class App extends Component {
	
	state = {
	
	};

	async componentDidMount() {
		window.onscroll = function() {myFunction()};
		var header = document.getElementById("navbar");

		var sticky = header.offsetTop;

		function myFunction() {
			if(window.innerWidth >= 992){
				if (window.pageYOffset > sticky) {
					header.classList.remove("un-sticky");
					header.classList.add("sticky");
				}
				else {
					header.classList.remove("sticky");
					header.classList.add("un-sticky");
				}
			}
		}
	}

	
	render() {
		return (
			<BrowserRouter>
				<header>
					<Navbar/>
				</header>
				<ScrollToTop />
				<ScrollUpButton style={{height:"30px",width:"30px",zIndex: "200",borderRadius:"6px", boxShadow:"rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset"}} />
				
				<main style={{ minHeight: 800 }}>
					<Switch>
						<Route path="/terms-of-use" component={withRouter(Terms)} />
						<Route path="/privacy-policy" component={withRouter(Privacy)} />
						<Route path="/jobs/serenus-ai/:title/apply" component={withRouter(Apply)} />
						<Route path="/jobs/serenus-ai/cso" component={withRouter(Cso)} />
						<Route path="/jobs/serenus-ai/vp" component={withRouter(VP)} />
						<Route path="/jobs/serenus-ai/evp-us" component={withRouter(Evp)} />
						<Route path="/jobs/serenus-ai/digital" component={withRouter(Digital)} />
						<Route path="/jobs/serenus-ai/full-stack" component={withRouter(FullStack)} />
						<Route path="/benefits" component={withRouter(Benefits)} />
						<Route path="/about" component={withRouter(About)} />
						<Route path="/specialties" component={withRouter(SpecialtiesPage)} />
						<Route path="/careers" component={withRouter(Careers)} />
						<Route path="/partners" component={Partners} />
						<Route path="/emr-api" component={withRouter(Emr)} />
						{/* <Route path="/specialties" component={withRouter(Specialties)} /> */}
						<Route path="/media" component={withRouter(Media)} />
						<Route path="/serenus-ai-for-patient" component={withRouter(Patient)} />
						<Route path="/serenus-ai-for-providers-and-physicians" component={withRouter(Providers)} />
						{/* <Route path="/serenus-ai-for-payers-employers" component={withRouter(Payers)} /> */}
						<Route path="/serenus-ai-for-payers-employers" component={withRouter(PriorAuth360)} />
						<Route path="/admin-panel" component={withRouter(AdminPanel)} />
						<Route path="/contact-us" component={withRouter(ContactUs)} />
						<Route path="/admin"  component={withRouter(Admin)} />
						<Route path="/team"  component={withRouter(Team)} />
						<Route path="/mission"  component={withRouter(Vision)} />
						<Route path="/investors"  component={withRouter(Investors)} />
						<Route path="/releases"  component={withRouter(Releases)} />
						<Route path="/executive-summary"  component={withRouter(ExecutiveSummary)} />
						<Route path="/payers-brochure"  component={withRouter(PayersBrochure)} />
						<Route path="/payers-investors"  component={withRouter(CVS)} />
						<Route path="/products-payers" component={withRouter(PriorAuth360)} />
						<Route path="/cteas" component={withRouter(CTEAS)} />
						<Route path="/demo" component={withRouter(SimulatorDemo)} />
						<Route path="/info" component={withRouter(InvestorsPage)} />
						<Route path="/dashboards" component={withRouter(Dashboards)} />
						<Route path="/merge-questionnaires" component={withRouter(MergeQuestionnaires)} />
						<Route path="/finished-simulator" component={withRouter(FinishedSimulator)} />
						<Route exact path="/"  component={Home} />
						<Route path='*'><FourZeroFour /></Route>
					</Switch>
				</main>
				
				<footer>
			  		<Footer />
				</footer>
			</BrowserRouter>
		);
	}
}
	
export default App;
