import React, { Component } from "react";
import Orthopedics from "../images/osteoporosis.png"
import nose from "../images/nose-icon-6.png"
import uro from "../images/urology.png"
import '../css/specialties.css';	


const Specialties = () => {

	return ( 
		<ul className="hexagon-grid-container" >
			<li className="hexagon hexagon-green">
				<div className="hexagon-inner">
					<img className="hexagon-avatar-img img-height-5	0" src="https://img.icons8.com/carbon-copy/50/000000/brain.png"/>
					<span className="hexagon-name">Neurosurgery</span>
				</div>	
			</li>
			<li className="hexagon hexagon-green">
				<div className="hexagon-inner">
					<img className="hexagon-avatar-img img-height-60" src="https://img.icons8.com/dotty/80/000000/visible.png"/>
					<span className="hexagon-name">Ophthalmology</span>
				</div>
			</li>
			<li className="hexagon hexagon-green">
				<div className="hexagon-inner">
					<img className="hexagon-avatar-img img-height-60" src="https://img.icons8.com/carbon-copy/100/000000/tooth.png"/>
					<span className="hexagon-name">Oral Surgery</span>
				</div>
			</li>
			<li className="hexagon hexagon-green">
				<div className="hexagon-inner">
					<img className="hexagon-avatar-img img-height-50" src="https://img.icons8.com/dotty/80/000000/spine.png"/>
					<span className="hexagon-name">Orthopedics</span>
				</div>
			</li>
			<li className="hexagon hexagon-green">
				<div className="hexagon-inner">
					<img className="hexagon-avatar-img img-height-50"  src="https://img.icons8.com/ios/50/000000/head-profile.png"/>
					<span className="hexagon-name">Plastics</span>
				</div>
			</li>
			<li className="hexagon hexagon-green">
				<div className="hexagon-inner">
					<img className=" img-height-50"  src="https://img.icons8.com/ios/50/000000/breath.png"/>
					<span className="hexagon-name">Otolaryngology</span>
				</div>
			</li>
			<li className="hexagon hexagon-green">
				<div className="hexagon-inner">
					<img className="hexagon-avatar-img img-height-50" src="https://img.icons8.com/ios/50/000000/bladder-flush.png"/>
					<span className="hexagon-name">Urology</span>
				</div>
			</li>
			{/* <li className="hexagon hexagon-green">
				<div className="hexagon-inner">
					<img className=" img-height-50 chest" src="https://img.icons8.com/ios/50/000000/chest.png"/>
					<span className="hexagon-name">Chest Surgery</span>
				</div>
			</li> */}
			<li className="hexagon hexagon-green">
				<div className="hexagon-inner">
					<img className="hexagon-avatar-img" src="https://img.icons8.com/ios/50/000000/eukaryotic-cells.png"/>
					<span className="hexagon-name">Oncology</span>
				</div>
				<br/><br/>
			</li>
			<li className="hexagon hexagon-green">
				<div className="hexagon-inner">
					<img className="hexagon-avatar-img" src="https://img.icons8.com/ios/50/000000/surgery.png"/>
					<span className="hexagon-name">General</span>
				</div>
			</li>
			<li className="hexagon hexagon-green">
				<div className="hexagon-inner">
					<img className="hexagon-avatar-img" src="https://img.icons8.com/windows/32/000000/uterus.png"/>
					<span className="hexagon-name">Gynecology </span>
				</div>
			</li>
			<li className="hexagon hexagon-green">
				<div className="hexagon-inner">
					<img className="hexagon-avatar-img img-height-50"  src="https://img.icons8.com/wired/64/000000/baby.png"/>
					<span className="hexagon-name">Obstetrics</span>
				</div>
			</li>
			<li className="hexagon hexagon-green">
				<div className="hexagon-inner">
					<img className="hexagon-avatar-img" src="https://img.icons8.com/ios/50/000000/bowel-flush.png"/>
					<span className="hexagon-name">Gastroenterology</span>
				</div>
			</li>
			<li className="hexagon hexagon-green">
				<div className="hexagon-inner">
					<img className="hexagon-avatar-img" src="https://img.icons8.com/ios/50/000000/medical-heart.png"/>
					<span className="hexagon-name">Cardiology</span>
				</div>
				<br/><br/>
			</li>
		</ul>

	);
}
 
export default Specialties;