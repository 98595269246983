import React, { Component, useEffect, useState } from "react";
import '../css/contact.css';
import { useForm } from 'react-hook-form/dist/index.ie11'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "toastr/build/toastr.css";
import qs from 'qs';
import { b2cUrl } from "./constants";

var FormData = require('form-data');
const axios = require('axios');


function Demo() {

	const [isSubmit, setIsSubmit] = useState(false);
	const { register, handleSubmit, errors } = useForm(); 


	async function onSubmit(data) {
		setIsSubmit(true);
		try {
			let firstNameErr='';
			let lastNameErr='';
			let emailErr='';
			let phoneErr='';
			let companyErr='';
			let titleErr='';
			let messageErr ='';

			// const url = 'http://localhost/serenus-ai/b2c/serenus-demo';
			const url = b2cUrl() + 'serenus-demo';

			let res = await axios({
				method: 'post',
				url: url,
				data: {
					firstName: data.firstname,
					lastName: data.lastname,
					email: data.email,
					phone: data.phone,
					company: data.company,
					title: data.title,
					message: data.message
				},
				headers: {
					'Content-Type': 'application/json;charset=UTF-8'
					//  "Access-Control-Allow-Origin": "*",
					// 'Accept': 'application/json' 	
				}
			});

			if (res.status == 200) {
				if (Array.isArray(res.data)) {
					for (let i = 0; i < res.data.length; i++) {
						if (res.data[i]) {
							if (i == 0) {
								firstNameErr = res.data[i];
							} else if (i == 1) {
								lastNameErr = res.data[i];
							} else if (i == 2) {
								emailErr = res.data[i];
							} else if (i == 3) {
								phoneErr = res.data[i];
							} else if (i == 4) {
								companyErr = res.data[i];
							} else if (i == 5) {
								titleErr = res.data[i];
							} else if (i == 6) {
								messageErr = res.data[i];
							}
						}
					}
				
					if (firstNameErr || lastNameErr || emailErr || phoneErr || companyErr || titleErr || messageErr) {
						if (firstNameErr) {
							document.getElementById("err-first-name").innerHTML = firstNameErr;
						}
						if (lastNameErr) {
							document.getElementById("err-last-name").innerHTML = lastNameErr;
						}
						if (emailErr) {
							document.getElementById("err-email").innerHTML = emailErr;
						}
						if (phoneErr) {
							document.getElementById("err-phone").innerHTML = phoneErr;
						}
						if (companyErr) {
							document.getElementById("err-company").innerHTML = companyErr;
						}
						if (titleErr) {
							document.getElementById("err-title").innerHTML = titleErr;
						}
						if (messageErr) {
							document.getElementById("err-message").innerHTML = messageErr;
						}

					}
				} else {
					if (res.data.includes("Message has been sent")) {
						toast.success("Your message sent successfully!");
						let frm = document.getElementById('demo-form');
						frm.reset();
						setIsSubmit(false);
						return false;
					} else {
						toast.error("The site is experiencing technical difficulties, please try again later");
					}
				}
			}
			setIsSubmit(false);
		} catch {
			toast.error("The site is experiencing technical difficulties, please try again later");
			setIsSubmit(false);
		}
	};

	useEffect(() => {

	});

  
	return ( 
		<React.Fragment>
 			<ToastContainer />
			<div className="container">
				<div className="row">
					<div className="col-md-12 ">
  						<form onSubmit={handleSubmit(onSubmit)} id="demo-form">
							<div className="col-12">
								<div className="row">
									<div className="col-md-6 col-xs-12 ">
										<label htmlFor="fname" style={{display:"none"}}>First Name</label>
										<input type="text" id="fname" name="firstname" ref={register({ required: true })}  placeholder="First Name"/>
										<span className="err" id="err-first-name">{errors.firstname && '*First name is required'}</span>
									</div>
									<div className="col-md-6 col-xs-12 " style={{justifyContent:"right"}}>
										<label htmlFor="lname" style={{display:"none"}}>Last Name</label>
										<input type="text" id="lname" name="lastname" ref={register({ required: true })} placeholder="Last Name"/>
										<span className="err" id="err-last-name">{errors.lastname && '*Last name is required'}</span>
									</div>
								</div>

								<div className="row">
									<div className="col-md-6 col-xs-12 " style={{justifyContent:"right"}}>
										<label htmlFor="email" style={{display:"none"}}>Email</label>
										<input type="email" id="email" name="email" ref={register({ required: true })} placeholder="Email"/>
										<span className="err" id="err-email">{errors.email && '*Email is required'} </span>
									</div>
									<div className="col-md-6 col-xs-12 " style={{justifyContent:"right"}}>
										<label htmlFor="phone" style={{display:"none"}}>Phone</label>
										<input type="phone" id="phone" name="phone" ref={register({ required: true, maxLength: 14, minLength: 9 })} placeholder="Phone Number"/>
										<span className="err" id="err-phone">
											{errors.phone && errors.phone.type === "required" && <span>*Phone is required</span>}
											{errors.phone && errors.phone.type === "maxLength" && <span>*Max length exceeded</span> }
											{errors.phone && errors.phone.type === "minLength" && <span>*Min length exceeded</span> }
										</span>
									</div>
								</div>

								<div className="row">
									<div className="col-md-6 col-xs-12 " style={{justifyContent:"right"}}>
										<label htmlFor="text" style={{display:"none"}}>Company Name</label>
										<input type="text" id="company" name="company" ref={register({ required: true, maxLength: 100 })} placeholder="Company Name"/>
										<span className="err" id="err-company">
											{errors.company && errors.company.type === "required" && <span>*Company name is required</span>}
											{errors.company && errors.company.type === "maxLength" && <span>*Max length exceeded</span> }
										</span>
									</div>
									<div className="col-md-6 col-xs-12 " style={{justifyContent:"right"}}>
										<label htmlFor="text" style={{display:"none"}}>TITLE</label>
										<input type="text" id="title" name="title" ref={register({ required: true, maxLength:100 })} placeholder="Title"/>
										<span className="err" id="err-title">
											{errors.title && errors.title.type === "required" && <span>*Title is required</span>}
											{errors.title && errors.title.type === "maxLength" && <span>*Max length exceeded</span> }
										</span>
									</div>
								</div>

								<div className="row">
									<div className="col-12">
										<label htmlFor="message" style={{display:"none"}}>Message Request</label>
										<textarea name="message" id="message" cols="30" ref={register({ required: true, maxLength:800 })} rows="5" placeholder="Message request"></textarea>
										<span className="err" id="err-message">
											{errors.message && errors.message.type === "required" && <span>*Message is required</span>}
											{errors.message && errors.message.type === "maxLength" && <span>*Max length exceeded</span> }
										</span>
									</div>
								</div>
							</div>

							<div className="col-12" style={{textAlign: "center"}}>
								<button className="submit" type="submit" value="Submit" disabled={isSubmit}>
									{isSubmit
										? (<React.Fragment><span className="spinner-border spinner-border-sm"></span> Sending...</React.Fragment>)
										: 'Submit'
									}
								</button>
							</div>
  						</form>
					</div>
				</div>
			</div>
		</React.Fragment>
	 );
}

 
export default Demo;
